import React, {
  forwardRef,
  useImperativeHandle,
  useEffect,
  useState,
} from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { isEmpty } from 'lodash'

import classes from './questions.module.css'
import { addQuestion } from 'reducers/questions/question.actions'
import { QUESTION_TYPES } from 'shared/CONSTANTS'
import { MultipelQuestion } from './MultipelQuestion'
import { updateQuestion } from 'reducers/questions/question.actions'
import { showToast } from 'shared/functions'
import { TOAST_TYPE } from 'shared/functions'

const questionTypeSelection = [
  {
    title: 'Text',
    type: 'text',
  },
  {
    title: 'Checkboxes',
    type: 'checkbox',
  },
  {
    title: 'Multiple Choice',
    type: 'radio',
  },
]

export const AddQuestionForm = forwardRef((props, ref) => {
  const [show, setModal] = useState(false)
  const [formData, setFormData] = useState({
    qTitle: '',
    type: '',
    description: '',
    questions: [],
  })

  const {
    quiz_id,
    course_id,
    lesson_id,
    questionData = {},
    isUpdating = false,
  } = props || {}

  const dispatch = useDispatch()

  useImperativeHandle(ref, () => ({
    showModal: () => {
      setModal(true)
    },
  }))

  useEffect(() => {
    if (!show) return

    if (isUpdating) {
      const questions =
        typeof questionData?.questions !== 'string'
          ? questionData.questions
          : JSON.parse(questionData?.questions)

      setFormData({
        ...questionData,
        qTitle: questionData.title,
        questions: questions,
      })
    }
  }, [show])

  const onClose = () => {
    setFormData({
      qTitle: '',
      type: '',
      description: '',
      questions: [],
    })
    setModal(false)
  }

  const onChangeText = (key, value) => {
    const _formData = { ...formData }
    _formData[key] = value
    setFormData(_formData)
  }

  const setQuestions = (questions) => {
    const _formData = { ...formData }
    _formData.questions = [...questions]
    setFormData(_formData)
  }

  const onSubmitForm = async (e) => {
    e.preventDefault()
    if(!isUpdating)
    {
      if (formData?.qTitle === '') {
        return showToast('Please enter the question title.', TOAST_TYPE.error)
      }
      if (formData.description === '') {
        return showToast('Please enter the title description.', TOAST_TYPE.error)
      }
      if (formData.type === '') {
        return showToast('Please select the type of the question.', TOAST_TYPE.error)
      }
      if (formData.questions.length < 1 && formData.type !== QUESTION_TYPES.TEXT_INPUT) {
        return showToast('Please select options.', TOAST_TYPE.error)
      }
    }
    const _formData = { ...formData }
    if (_formData.type === QUESTION_TYPES.TEXT_INPUT) {
      delete _formData.questions
      const q = []
      q.push({ type: _formData.type })
      _formData.questions = q
    }

    const payload = {
      lesson_id,
      quiz_id,
      course_id,
      ..._formData,
    }

    if (!isUpdating) await dispatch(addQuestion(payload))
    else if (isUpdating) {
      //call the update function here....
      await dispatch(updateQuestion(payload))
    }

    if (show) {
      setModal(false)
      setFormData({
        qTitle: '',
        type: '',
        description: '',
        questions: [],
      })
    }
  }

  return (
    <Modal
      show={show}
      onHide={onClose}
      size="lg"
      animation={false}
      backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>
          {isUpdating ? 'Edit Question' : 'Add Question'}
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={onSubmitForm}>
        <Modal.Body>
          <Row>
            <Col sm="12" lg="12" md="12">
              <Form.Group>
                <Form.Label>Question</Form.Label>
                <Form.Control
                  placeholder="Question"
                  type="text"
                  name="qTitle"
                  value={formData.qTitle}
                  onChange={(e) => onChangeText('qTitle', e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col sm="12" lg="12" md="12">
              <Form.Group>
                <Form.Label>Description</Form.Label>
                <Form.Control
                  placeholder="Quiz Description"
                  type="text"
                  as="textarea"
                  rows="4"
                  name="description"
                  value={formData.description}
                  onChange={(e) => onChangeText('description', e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col sm="12" lg="12" md="12">
              <Form.Group>
                <select
                  className={classes.select}
                  onChange={(e) => {
                    onChangeText('type', e.target.value)
                  }}
                  value={formData.type}>
                  <option value="null">Question Type</option>
                  {questionTypeSelection.map((question, key) => (
                    <option value={question.type}>{question.title}</option>
                  ))}
                </select>
              </Form.Group>
            </Col>
          </Row>
          {formData.type && formData.type !== QUESTION_TYPES.TEXT_INPUT && (
            <MultipelQuestion
              type={formData.type}
              setQuestions={setQuestions}
              options={formData?.questions}
            />
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button type="button" variant="default" onClick={onClose}>
            Cancel
          </Button>
          <Button
            className="btn-fill pull-right"
            type="submit"
            variant={isEmpty(questionData) ? 'primary' : 'info'}
            // disabled={
            //   !formData?.qTitle ||
            //   formData.type === "null" ||
            //   !formData.type ||
            //   (formData.type !== QUESTION_TYPES.TEXT_INPUT &&
            //     !formData.questions.length)
            // }
          >
            {isEmpty(questionData) ? 'Submit' : 'Update'}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
})
