import { isEmpty } from 'lodash'
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { Col, Form, Modal, Row, Button } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { addLessonToCourse } from 'reducers/videos/videos.action'

import classes from './addVideoForm.module.css'
import { FormFileViewer } from './FormFileViewer'
import { FileSelectionInput } from './FileSelectionInput.js.js'
import { TextEditor } from 'components/TextEditor/TextEditor'
import { showToast } from 'shared/functions'
import { TOAST_TYPE } from 'shared/functions'
import { getCourse } from 'reducers/course/courses.action'
import { getLocalStorage } from 'shared/functions'

export const AddLessonsForm = forwardRef((props, ref) => {
  const [formData, setFormData] = useState([])
  const [selectedVideo, setSelectedVideo] = useState(null)
  const [show, setModalState] = useState(false)

  const editorRef = useRef(null)

  const dispatch = useDispatch()

  useImperativeHandle(ref, () => ({
    showModal: () => {
      setModalState(true)
    },
  }))

  const onClose = () => {
    setFormData([])
    setSelectedVideo(null)
    setModalState(false)
    dispatch(getCourse({ course_id: getLocalStorage('course_id') }))
  }

  const isSelected = (file) => file === selectedVideo

  const onChangeInput = (key, value) => {
    const _formData = [...formData]
    const index = _formData?.findIndex((f) => f === selectedVideo)
    if (index === -1) return

    selectedVideo[key] = value
    _formData[index] = selectedVideo
    setFormData(_formData)
  }

  const onSelectVideo = (file) => {
    setSelectedVideo(file)
  }

  const onRemoveVideo = (file) => {
    const _formData = [...formData]

    const index = _formData?.findIndex((f) => f === file)

    if (index === -1) return

    const newFormData = _formData.filter((item) => item !== file)

    if (!newFormData?.length) {
      setSelectedVideo(null)
    } else {
      setSelectedVideo(newFormData[0])
    }
    setFormData(newFormData)
  }

  const onFilesSelected = (files) => {
    const _formData = [...formData]
    const _files = []
    if (!files?.length) return

    files?.map((file) => {
      const obj = {
        topic: '',
        description: '',
        file: file,
      }

      _files.push(obj)
    })

    const updatedFiles = _formData.concat(_files)
    setSelectedVideo(updatedFiles[0])
    isSelected(updatedFiles[0])
    setFormData(updatedFiles)
  }

  const onSubmitForm = async (e) => {
    e.preventDefault()
    if (formData[0].topic === '') {
      return showToast('Ilma Topic is required', TOAST_TYPE.error)
    }
    if (formData[0].description === '') {
      return showToast('Ilma description is required', TOAST_TYPE.error)
    }

    await dispatch(
      addLessonToCourse({
        videos: formData,
        course_id: props?.course?.course_id,
      })
    )
    onClose()
  }

  return (
    <Modal
      show={show}
      size="lg"
      animation={false}
      onHide={onClose}
      backdrop="static">
      <Modal.Header closeButton={true} closeVariant="white" closeLabel="Close">
        <Modal.Title>Add New Lesson</Modal.Title>
      </Modal.Header>
      <Form onSubmit={onSubmitForm} encType="multipart/form-data">
        <Modal.Body>
          <Row>
            <Col lg="12" md="12" sm="12">
              <Form.Group>
                <Form.Label>Course Lessons</Form.Label>
                <div className={classes.form_video_wrapper}>
                  {formData?.length ? (
                    formData?.map((file) => (
                      <FormFileViewer
                        file={file}
                        isPDF={false}
                        isVideo={true}
                        onSelect={onSelectVideo}
                        onRemove={onRemoveVideo}
                        isSelected={isSelected(file)}
                      />
                    ))
                  ) : (
                    <></>
                  )}
                  <FileSelectionInput onFilesSelected={onFilesSelected} />
                </div>
              </Form.Group>
            </Col>
          </Row>
          <div className={classes.inputWrapper}>
            {!selectedVideo ? <div className={classes.blockOverlay} /> : <></>}
            <Row>
              <Col sm="12">
                <Form.Group>
                  <Form.Label>Lesson / Topic</Form.Label>
                  <Form.Control
                    placeholder="Video Lessons/topic"
                    value={selectedVideo && selectedVideo.topic}
                    onChange={(e) => onChangeInput('topic', e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm="12">
                <Form.Group>
                  <Form.Label>Description</Form.Label>
                  {/* <Form.Control
                    placeholder="Video Description"
                    as="textarea"
                    rows={4}
                    value={selectedVideo && selectedVideo.description}
                    onChange={(e) =>
                      onChangeInput("description", e.target.value)
                    }
                  /> */}
                  <TextEditor
                    // value={editCourse.description}
                    placeholder="Lesson Details"
                    ref={editorRef}
                    onChange={(value) => onChangeInput('description', value)}
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" variant="default" onClick={onClose}>
            Cancel
          </Button>
          <Button
            className="btn-fill pull-right"
            type="submit"
            variant="primary"
            disabled={!formData?.length}>
            Add
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
})
