import { LESSON_TYPES } from "./videos.action";

const INITIAL_STATE = {
  comments: [],
};

const lessonReducer = (state = INITIAL_STATE, actions) => {
  switch (actions.type) {
    case LESSON_TYPES.SET_COMMENTS:
      return {
        ...state,
        comments: actions.payload,
      };
    default:
      return state;
  }
};

export default lessonReducer;
