import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'

export const OnReplaceItem = ({onApplySuccessFunction , title , date }) => {
 
    Swal.fire({
        title: `Ilma already exist on \n${date.split('T')[0]}`,
        text: `Are you sure you want to replace this ${title}? \nYou won't be able to revert this!`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, replaced it!'
      }).then((result) => {
        if (result.isConfirmed) {
          onApplySuccessFunction()        
        }
        else if(result.dismiss){
            Swal.fire(
                        'Cancelled',
                        'Replaced function cancelled.',
                        'error'
                      )
        }
      })
 }