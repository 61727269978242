export const AUTH_ENDPOINTS = {
  LOGIN: "/auth/login",
  SIGNUP: "/auth/register",
  LOGOUT: "/auth/logout",
  SESSION_USER: "/auth/session-user"
};

export const USER = {
  PROFILE: "/user/user-profile",
  FETCH_USERS: "/user/list-users",
  EDIT_USER: "/user/adminupdate",
  DELETE_USER: "/user/delete-user",
  FETCH_USER_WITH_PAGINATION: "/user/list-users-with-pagination"
};

export const CATEGORY_ENDPOINTS = {
  FETCH_ALL: "/cat/categories",
  CREATE: "/cat/create",
  DELETE: "/cat/delete",
  UPDATE: "/cat/update",
  UPDATE_DEFAULT_IMAGE: '/cat/update-default-image',
  GET_DEFAULT_IMAGE: '/cat/get-default-image'
};

export const USER_ENDPOINTS = {
  UPDATE_PROFILE: "/user/update",
  UPDATE_PASSWORD: "/user/change-password"
};

export const COURSES_ENDPOINTS = {
  ALL_COURSES: "/courses/all",
  GET_COURSE: "/courses/get-course",
  CREATE_COURSE: "/courses/new",
  UPLOAD_COURSE_THUMBNAIL: "/courses/upload",
  DELETE_COURSE: "/courses/delete",
  UPDATE_COURSE: "/courses/update",
  PUBLISH_COURSE: "/courses/publish",
  SUBMIT_COURSE: "/courses/submit-course",
  TAGS_COURSE: "tags/create"
};

export const LESSONS_ENDPOINTS = {
  UPDATE_LESSONS: "/lessons/update-lessons",
  ADD_LESSONS: "/lessons/add-lessons",
  UPLOAD_FILE: "/lessons/upload",
  DELETE_LESSON: "/lessons/delete",
  RE_ORDER_LESSON: "/lessons/re-order",
  GET_COMMENTS: "/lessons/comments",
  REPLY_COMMENT: "/lessons/add-comment-reply"
};

export const QUIZ_ENDPOINTS = {
  GET_QUIZ: "/quizes/quiz",
  ADD_QUIZ: "/quizes/create",
  DELETE_QUIZ: "/quizes/delete"
};

export const QUESTIONS_ENDPOINTS = {
  ADD_QUESTION: "/questions/add",
  UPDATE_QUESTION: "/questions/update",
  DELETE_QUESTION: "/questions/delete"
};

export const ILMA_ENDPOINTS = {
  GET_ALL_ILMAS: "/ilma/all",
  CREATE_ILMA: "/ilma/create",
  DELETE_ILMA: "/ilma/delete",
  UPLOAD_ILMA_FILE: "/ilma/upload",
  UPDATE_ILMA: "/ilma/update",
  CHECK_ILMA_SCHEDULE: "/ilma/check-schedule-date",
  UPDATE_ILMA_DEFAUT_VIDEO: "/ilma/update-default-ilma",
  GET_ILMA_DEFAUT_VIDEO: "/ilma/get-default-video"
};

export const ASK_A_SHEIKH_ENDPOINTS = {
  GET_QUESTIONS: "/ask-a-sheikh/all",
  REPLY_QUESTION: "/ask-a-sheikh/reply-to-question",
  GET_UNANSEWRD: "/ask-a-sheikh/un-answered",
  ASK_SHIEKH_QUESTION: "/ask-a-sheikh/ask-question",
  DELETE_QUESTION: "/ask-a-sheikh/delete-question"
  
};

export const TAGS_ENDPOINTS = {
  GET_TAGS: "/tags",
  SEARCH_TAGS: "/tags/all",
  CREATE_TAGS: "/tags/create"
};

export const KNOWLEDGE_JOURNEY_ENDPOINTS = {
  GET_QUESTIONS: "/my-journey/list-questions",
  GET_ASSESSMENTS: "/my-journey/list-assessment",
  DELETE_ASSESSMENT: "/my-journey/delete-assessment",
  CREATE_QUESTIONS: "/my-journey/create-question",
  UPDATE_QUESTIONS: "/my-journey/update-question",
  DELETE_QUESTION: "/my-journey/delete-question",
  // DELETE_QUESTION: "/questions/delete",
  CREATE_ASSESSMENTS: "/my-journey/create-assessment",
  CREATE_MULTI_ASSESSMENTS: "/my-journey/create-multi-topics-assessment",
  UPDATE_MULTI_ASSESSMENTS: "/my-journey/update-assessment",
  CHECK_QUESTION_MULTI_ASSESSMENTS: "/my-journey/check-multi-topics-assessment"
  
};
