import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

import classes from "./style.module.css";
import { ButtonLink } from "components/ButtonLink/ButtonLink";
import { QUESTION_TYPES } from "shared/CONSTANTS";

export const EditQuestionType = ({
  options,
  type = QUESTION_TYPES.CHECK_BOX,
  setQuestions = (questions) => {}
}) => {
  const [multipleOptions, setMultipleOptions] = useState([
    {
      name: "",
      label: "",
      type: type,
      isCorrect: false
    }
  ]);

  useEffect(() => {
    const questions = [...options];
    for (let i = 0; i < questions.length; i++) {
      const question = { ...questions[i] };
      question.type = type;
      questions[i] = question;
    }

    setMultipleOptions(questions);
    setQuestions(questions);
  }, [type]);

  const onAdd = () => {
    const newOption = {
      name: "",
      label: "",
      type: type,
      isCorrect: false
    };
    const questions = [...multipleOptions];
    questions.push(newOption);

    setMultipleOptions(questions);
    setQuestions(questions);
  };

  const onRemove = (index) => {
    const questions = [...multipleOptions];

    questions.splice(index, 1);
    setMultipleOptions(questions);
    setQuestions(questions);
  };

  const onChangeInput = (q, index, value) => {
    const question = { ...q };
    question.label = value;

    const questionsList = [...multipleOptions];
    questionsList[index] = question;
    setMultipleOptions(questionsList);
    setQuestions(questionsList);
  };

  const onMarkAsCorrect = (q, index, value) => {
    const question = { ...q };

    const questionsList = [...multipleOptions];
    //mark all other options to false
    if (question.type === "radio") {
      for (let i = 0; i < questionsList.length; i++)
        questionsList[i].isCorrect = false;
    }

    question.isCorrect = value;
    questionsList[index] = question;
    setMultipleOptions(questionsList);
    setQuestions(questionsList);
  };

  return (
    <>
      <Row>
        {multipleOptions.map((q, key) => (
          <Col sm="12" md="12" lg="12" key={key.toString()}>
            <div className={classes.inline}>
              <Form.Group style={{ display: "flex", alignItems: "center" }}>
                <input type={q.type} className={classes.checkbox} disabled />
                <Form.Control
                  placeholder="Label"
                  name={q.name}
                  value={q.label}
                  required
                  onChange={(e) => onChangeInput(q, key, e.target.value)}
                  className={classes.input}
                />
              </Form.Group>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Form.Control
                  type={q.type}
                  name="correntAnswer"
                  style={{ width: "20px", height: "20px", marginRight: 5 }}
                  onChange={(e) => onMarkAsCorrect(q, key, e.target.checked)}
                />
                Correct Answer
              </div>
              <ButtonLink
                iconClasses="fas fa-trash"
                tooltip="Delete"
                iconStyle={classes.icon_style_delete}
                onClick={() => onRemove(key)}
              />
            </div>
          </Col>
        ))}
      </Row>

      <div className={classes.add_option} onClick={onAdd}>
        <input
          type={multipleOptions?.length && multipleOptions[0].type}
          className={classes.checkbox}
          disabled
        />
        Add Answer Option
      </div>
    </>
  );
};
