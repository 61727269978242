import React from "react";

import classes from "./CourseDetailTable.module.css";

export const QuestionCard = ({
  question,
  onQuestionSelect = (question) => {},
}) => (
  <div
    className={classes.question_card}
    onClick={() => onQuestionSelect(question)}
  >
    {question.message}
    <p>Asked By: {question?.user?.fname + " " + question?.user?.lname}</p>
  </div>
);
