import { ILMA_ENDPOINTS } from 'config/apis'
import http from 'config/http'
import { isEmpty } from 'lodash'
import { setAppCloseableLoadingState } from 'reducers/appLoading/appLoading.action'
import { deleteFileFromCloudinary } from 'shared/cloudinary'
import { ILMA_TYPE } from 'shared/CONSTANTS'
import { uploadFileToCloudinaryViaAPI } from 'shared/functions'
import { TOAST_TYPE } from 'shared/functions'
import { showToast } from 'shared/functions'

export const ILMA_ACTION_TYPES = {
  SET_ILMAS: '[ILMA] SET_ILMAS',
  SET_SELECTED_ILMA: '[ILMA] SET_SELECTED_ILMA',
}

export const setIlmasList = (payload) => {
  return (dispatch) => {
    return dispatch({
      type: ILMA_ACTION_TYPES.SET_ILMAS,
      payload,
    })
  }
}

export const getAllIlmas = () => {
  return async (dispatch) => {
    try {
      dispatch(
        setAppCloseableLoadingState({
          status: true,
          closeable: false,
          text: 'Please wait getting Ilmas',
        })
      )
      const ilmas = await http.get(ILMA_ENDPOINTS.GET_ALL_ILMAS)
      dispatch(
        setAppCloseableLoadingState({
          status: false,
          closeable: false,
          text: '',
        })
      )
      if (!ilmas.data) return

      return dispatch(setIlmasList([...ilmas.data.ilmas]))
    } catch (err) {}
  }
}

export const createIlma = (payload) => {
  return async (dispatch, getState) => {
    try {
      if (!payload || !payload.title) {
        return showToast('All fields are required!', TOAST_TYPE.error)
      } else if (payload?.type === ILMA_TYPE.VIDEO && !payload.file) {
        return showToast('Please select video', TOAST_TYPE.error)
      } else if (payload?.type === ILMA_TYPE.TEXT && !payload.description) {
        return showToast('Please enter ilma description', TOAST_TYPE.error)
      }

      const ilmaList = [...getState().ilmas.list]
      //upload file
      if (
        payload?.type === ILMA_TYPE.VIDEO ||
        payload?.type === ILMA_TYPE.PDF
      ) {
        const formData = new FormData()
        formData.append('file', payload.file)

        dispatch(
          setAppCloseableLoadingState({
            status: true,
            closeable: true,
            text: 'Please wait creating Ilma',
          })
        )
        // const fileName = `${payload.file.name}`.replace(' ', '')

        const data = {
          file: payload?.file,
          path: 'ilmas',
        }
        const _file = await uploadFileToCloudinaryViaAPI(data, dispatch)

        const reqBody = {
          ...payload,
          file: _file.url,
          filePath: _file.public_id,
        }
        try {
          const newIlma = await http.post(ILMA_ENDPOINTS.CREATE_ILMA, reqBody)
          if (!newIlma.data) {
            return
          }
          const newIlmaDate = new Date(newIlma.data.schedule_date)
          let insertIndex = ilmaList.findIndex(
            (ilma) => new Date(ilma.schedule_date) < newIlmaDate
          )

          if (insertIndex === -1) {
            ilmaList.push(newIlma.data) // If no index found, add the element at the end
          } else {
            ilmaList.splice(insertIndex, 0, newIlma.data) // Insert the element at the found index
          }
          // ilmaList.unshift(newIlma.data)


          showToast('Ilma successfully created', TOAST_TYPE.success)
          return dispatch(setIlmasList(ilmaList))
        } catch (error) {
          showToast(error.response.data.message, TOAST_TYPE.error)
        }
      } else {
        //create ilma

        const reqBody = {
          ...payload,
        }
        try {
          const newIlma = await http.post(ILMA_ENDPOINTS.CREATE_ILMA, reqBody)
          if (!newIlma.data) {
            return
          }
          const newIlmaDate = new Date(newIlma.data.schedule_date)
          let insertIndex = ilmaList.findIndex(
            (ilma) => new Date(ilma.schedule_date) < newIlmaDate
          )
            
          if (insertIndex === -1) {
            ilmaList.push(newIlma.data) // If no index found, add the element at the end
          } else {
            ilmaList.splice(insertIndex, 0, newIlma.data) // Insert the element at the found index
          }
          // ilmaList.unshift(newIlma.data)
          showToast('Ilma successfully created', TOAST_TYPE.success)
          return dispatch(setIlmasList(ilmaList))
        } catch (error) {
          showToast(error.response.data.message, TOAST_TYPE.error)
        }
      }
    } catch (err) {}
  }
}

export const deleteIlma = (ilma_id) => {
  return async (dispatch, getState) => {
    try {
      if (!ilma_id) return showToast('Invalid id', TOAST_TYPE.error)

      dispatch(
        setAppCloseableLoadingState({
          status: true,
          closeable: false,
          text: 'Please wait deleting Ilma',
        })
      )
      const deleted = await http.delete(ILMA_ENDPOINTS.DELETE_ILMA, {
        data: {
          ilma_id: ilma_id,
        },
      })

      const ilmaList = [...getState().ilmas.list]
      const [ilma] = ilmaList?.filter((ilma) => ilma.ilma_id === ilma_id)
      if (ilma && ilma.type === ILMA_TYPE.VIDEO) {
        //delete the ilma file if exists
        deleteFileFromCloudinary([{ public_id: ilma.filePath, url: ilma.file }])
      }
      const updatedIlmaList = ilmaList.filter(
        (ilma) => ilma.ilma_id !== ilma_id
      )
      return dispatch(setIlmasList(updatedIlmaList))
    } catch (err) {}
  }
}

export const onIlmaExistOnDate = (payload) => {
  return async (dispatch, getState) => {
    try {
      const selectedIlma = getState().ilmas.selectedIlma
      if (isEmpty(selectedIlma))
        return showToast('Invalid id', TOAST_TYPE.error)
      const value = await http.post(ILMA_ENDPOINTS.CHECK_ILMA_SCHEDULE, {
        ...payload,
        Ilma: selectedIlma.ilma_id,
      })
      return
    } catch (error) {
      return showToast(error?.response?.data?.message, TOAST_TYPE.error)
    }
  }
}

export const replaceIlma = (payload) => {
  return async (dispatch, getState) => {
    try {
      const selectedIlma = payload.ilma_id
      // if (isEmpty(selectedIlma))
      //   return showToast('Invalid id', TOAST_TYPE.error)
      if (
        payload?.type === ILMA_TYPE.VIDEO ||
        payload?.type === ILMA_TYPE.PDF
      ) {
        if (typeof payload.file == 'string' || payload.file === '') {
          var reqBody = {
            ...payload,
          }
        } else {
          dispatch(
            setAppCloseableLoadingState({
              status: true,
              closeable: false,
              text: 'Please wait replacing Ilma',
            })
          )
          const data = {
            file: payload?.file,
            path: 'ilmas',
          }
          const _file = await uploadFileToCloudinaryViaAPI(data, dispatch)

          var reqBody = {
            ...payload,
            file: _file.url,
            filePath: _file.public_id,
          }
        }

        try {
          const updateIlmaResponse = await http.put(
            `${ILMA_ENDPOINTS.UPDATE_ILMA}/${selectedIlma}`,
            reqBody
          )

          dispatch(
            setAppCloseableLoadingState({
              status: false,
              closeable: false,
              text: '',
            })
          )

          const ilmaList = [...getState().ilmas.list]

          const [ilmaItem] = ilmaList.filter(
            (ilma) => ilma.ilma_id === selectedIlma
          )
          const indexOfIlma = ilmaList.findIndex(
            (ilma) => ilma.ilma_id === selectedIlma
          )
          if (!ilmaItem || indexOfIlma === -1) {
            return
          }
          const updatedIlmaItem = {
            ...ilmaItem,
            ...updateIlmaResponse.data.ilmas,
          }
          showToast('Ilma successfully updated', TOAST_TYPE.success)
          ilmaList[indexOfIlma] = updatedIlmaItem
          return dispatch(setIlmasList([...ilmaList]))
        } catch (error) {
          showToast(error.response.data.message, TOAST_TYPE.error)
        }
      } else {
        try {
          dispatch(
            setAppCloseableLoadingState({
              status: true,
              closeable: false,
              text: 'Please wait replacing Ilma',
            })
          )
          const updateIlmaResponse = await http.put(
            `${ILMA_ENDPOINTS.UPDATE_ILMA}/${selectedIlma}`,
            payload
          )
          dispatch(
            setAppCloseableLoadingState({
              status: false,
              closeable: false,
              text: '',
            })
          )

          const ilmaList = [...getState().ilmas.list]

          const [ilmaItem] = ilmaList.filter(
            (ilma) => ilma.ilma_id === selectedIlma
          )
          const indexOfIlma = ilmaList.findIndex(
            (ilma) => ilma.ilma_id === selectedIlma
          )
          if (!ilmaItem || indexOfIlma === -1) {
            return
          }
          const updatedIlmaItem = {
            ...ilmaItem,
            ...updateIlmaResponse.data.ilmas,
          }
          showToast('Ilma successfully updated', TOAST_TYPE.success)
          ilmaList[indexOfIlma] = updatedIlmaItem
          return dispatch(setIlmasList([...ilmaList]))
        } catch (error) {
          showToast(error.response.data.message, TOAST_TYPE.error)
        }
      }
    } catch (err) {}
  }
}

export const updateIlma = (payload) => {
  return async (dispatch, getState) => {
    try {
      const selectedIlma = getState().ilmas.selectedIlma
      if (isEmpty(selectedIlma))
        return showToast('Invalid id', TOAST_TYPE.error)
      if (
        payload?.type === ILMA_TYPE.VIDEO ||
        payload?.type === ILMA_TYPE.PDF
      ) {
        if (typeof payload.file == 'string' || payload.file === '') {
          var reqBody = {
            ...payload,
          }
        } else {
          dispatch(
            setAppCloseableLoadingState({
              status: true,
              closeable: true,
              text: 'Please wait updating Ilma',
            })
          )
          const data = {
            file: payload?.file,
            path: 'ilmas',
          }
          const _file = await uploadFileToCloudinaryViaAPI(data, dispatch)

          var reqBody = {
            ...payload,
            file: _file.url,
            filePath: _file.public_id,
          }
        }

        try {
          const updateIlmaResponse = await http.put(
            `${ILMA_ENDPOINTS.UPDATE_ILMA}/${selectedIlma.ilma_id}`,
            reqBody
          )

          dispatch(
            setAppCloseableLoadingState({
              status: false,
              closeable: false,
              text: '',
            })
          )

          const ilmaList = [...getState().ilmas.list]

          const [ilmaItem] = ilmaList.filter(
            (ilma) => ilma.ilma_id === selectedIlma.ilma_id
          )
          const indexOfIlma = ilmaList.findIndex(
            (ilma) => ilma.ilma_id === selectedIlma.ilma_id
          )
          if (!ilmaItem || indexOfIlma === -1) {
            return
          }
          const updatedIlmaItem = {
            ...ilmaItem,
            ...updateIlmaResponse.data.ilmas,
          }
          showToast('Ilma successfully updated', TOAST_TYPE.success)
          ilmaList[indexOfIlma] = updatedIlmaItem
          return dispatch(setIlmasList([...ilmaList]))
        } catch (error) {
          showToast(error.response.data.message, TOAST_TYPE.error)
        }
      } else {
        try {
          dispatch(
            setAppCloseableLoadingState({
              status: true,
              closeable: false,
              text: 'Please wait updating Ilma',
            })
          )
          const updateIlmaResponse = await http.put(
            `${ILMA_ENDPOINTS.UPDATE_ILMA}/${selectedIlma.ilma_id}`,
            payload
          )
          dispatch(
            setAppCloseableLoadingState({
              status: false,
              closeable: false,
              text: '',
            })
          )

          const ilmaList = [...getState().ilmas.list]

          const [ilmaItem] = ilmaList.filter(
            (ilma) => ilma.ilma_id === selectedIlma.ilma_id
          )
          const indexOfIlma = ilmaList.findIndex(
            (ilma) => ilma.ilma_id === selectedIlma.ilma_id
          )
          if (!ilmaItem || indexOfIlma === -1) {
            return
          }
          const updatedIlmaItem = {
            ...ilmaItem,
            ...updateIlmaResponse.data.ilmas,
          }
          showToast('Ilma successfully updated', TOAST_TYPE.success)
          ilmaList[indexOfIlma] = updatedIlmaItem
          return dispatch(setIlmasList([...ilmaList]))
        } catch (error) {
          showToast(error.response.data.message, TOAST_TYPE.error)
        }
      }
    } catch (err) {}
  }
}

export const setSelectedIlma = (ilma) => {
  return (dispatch) => {
    return dispatch({
      type: ILMA_ACTION_TYPES.SET_SELECTED_ILMA,
      payload: ilma,
    })
  }
}
