import React, { useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";

import classes from "./style.module.css";
import { Link } from "react-router-dom";
import { RegisterForm } from "components/RegisterForm/RegisterForm";
import { useDispatch, useSelector } from "react-redux";
import { onRegister } from "reducers/auth/auth.actions";
import { redirectTo } from "shared/functions";
import { GLOBAL_STATE } from "reducers/appLoading/getAppState";
import { AUTH_STATE } from "reducers/auth/getState";

export const Register = (props) => {
  const dispatch = useDispatch();
  const appLoading = useSelector(GLOBAL_STATE.loading);
  const authSuccess = useSelector(AUTH_STATE.authSuccess);

  useEffect(() => {
    if (!appLoading && authSuccess) {
      return redirectTo(props, { path: "/admin/login" });
    }
  }, [appLoading, authSuccess]);

  const onSubmitForm = (data) => {
    dispatch(onRegister(data));
  };

  return (
    <div className={`${classes.wrapper}`}>
      <Container className={classes.login_container}>
        <Col lg="6" md="6" sm="6">
          <Card className={classes.card}>
            <Card.Header className={classes.card_header}>
              <Card.Title as="h4">Register</Card.Title>
            </Card.Header>
            <Card.Body>
              <RegisterForm onSubmit={onSubmitForm} />
            </Card.Body>
            <Card.Footer>
              <Row>
                <Col>
                  Already have account? <Link to="/admin/login">Login</Link>
                </Col>
              </Row>
            </Card.Footer>
          </Card>
        </Col>
      </Container>
    </div>
  );
};
