import { ASK_A_SHEIKH_ENDPOINTS } from 'config/apis'
import { setAppCloseableLoadingState } from 'reducers/appLoading/appLoading.action'
import { TOAST_TYPE } from 'shared/functions'
import { showToast } from 'shared/functions'
import http from '../../config/http'

export const ASK_A_SHEIKH_ACTION_TYPES = {
  SET_QUESTIONS: '[ASK_A_SHEIKH] SET_QUESTIONS',
  SET_UNANSWERED_QUESTIONS: '[ASK_A_SHEIKH] SET_UNANSWERED_QUESTIONS',
  ADD_UNANSWERED_QUESTIONS: '[ASK_A_SHEIKH] ADD_UNANSWERED_QUESTIONS',
}

const setQuestions = (payload) => {
  return (dispatch) => {
    return dispatch({
      type: ASK_A_SHEIKH_ACTION_TYPES.SET_QUESTIONS,
      payload,
    })
  }
}

const setUnAnsweredQuestions = (payload) => {
  return (dispatch) =>
    dispatch({
      type: ASK_A_SHEIKH_ACTION_TYPES.SET_UNANSWERED_QUESTIONS,
      payload,
    })
}

export const getQuestions = (category_id) => {
  return async (dispatch, getState) => {
    try {
      const user = getState().auth.user
      if (!category_id)
        return showToast('Please select topic', TOAST_TYPE.error)

      dispatch(setAppCloseableLoadingState({ status: true, closeable: false , text:'Please wait questions are loading'}))
      const questions = await http.get(
        `${ASK_A_SHEIKH_ENDPOINTS.GET_QUESTIONS}/${category_id}`,
        {
          category_id: category_id,
          sheikh_id: user?.user_id,
        }
      )
      dispatch(setAppCloseableLoadingState({ status: false, closeable: false , text:''}))

      if (!questions.data) return

      return dispatch(setQuestions(questions.data.questions))
    } catch (err) {}
  }
}

export const deleteQuestion = (payload) => {
  return async (dispatch, getState) => {
    try {
      if ( !payload.category_id || !payload.question_id) {
        return showToast('Invalid!', TOAST_TYPE.error)
      }
      dispatch(setAppCloseableLoadingState({ status: true, closeable: false , text:''}))
      const reqBody = {
        ...payload,
        user_id: getState().auth.user?.user_id,
      }

      await http.post(ASK_A_SHEIKH_ENDPOINTS.DELETE_QUESTION, reqBody)
      dispatch(setAppCloseableLoadingState({ status: false, closeable: false ,text:''}))

        const unAnswered = [...getState().askASheikh.unAnswered]

        const [currentTopic] = unAnswered.filter(
          (t) => t?.category_id === payload.category_id
        )
        const indexOfCurrentTopic = unAnswered.findIndex(
          (t) => t?.category_id === payload.category_id
        )

        const _questions = [...currentTopic.questions]
        const filtered = currentTopic.questions.filter((q, index) => 
          q?.question_id !== payload.question_id
        )
        currentTopic.questions = filtered

        if (indexOfCurrentTopic === -1) return
        unAnswered[indexOfCurrentTopic] = currentTopic
        showToast('Question deleted', TOAST_TYPE.success)
        return dispatch(setUnAnsweredQuestions([...unAnswered]))

    } catch (err) {}
  }
}

export const replyToQuestion = (payload) => {
  return async (dispatch, getState) => {
    console.log(payload)
    try {
      if (!payload.message || !payload.category_id || !payload.question_id) {
        return showToast('Please update the answer!', TOAST_TYPE.error)
      }
      dispatch(setAppCloseableLoadingState({ status: true, closeable: false , text:''}))
      const reqBody = {
        ...payload,
        user_id: getState().auth.user?.user_id,
      }
      await http.post(ASK_A_SHEIKH_ENDPOINTS.REPLY_QUESTION, reqBody)
      dispatch(setAppCloseableLoadingState({ status: false, closeable: false ,text:''}))

      if (payload?.draft === 0) {
        const unAnswered = [...getState().askASheikh.unAnswered]
        const [currentTopic] = unAnswered.filter(
          (t) => t?.category_id === payload.category_id
        )
        const indexOfCurrentTopic = unAnswered.findIndex(
          (t) => t?.category_id === payload.category_id
        )

        const questions = currentTopic.questions.filter(
          (q) => q?.question_id !== payload.question_id
        )

        currentTopic.questions = [...questions]

        if (indexOfCurrentTopic === -1) return
        unAnswered[indexOfCurrentTopic] = currentTopic
        showToast('Answer is successfully submited', TOAST_TYPE.success)
        return dispatch(setUnAnsweredQuestions([...unAnswered]))
      } else {
        const unAnswered = [...getState().askASheikh.unAnswered]

        const [currentTopic] = unAnswered.filter(
          (t) => t?.category_id === payload.category_id
        )
        const indexOfCurrentTopic = unAnswered.findIndex(
          (t) => t?.category_id === payload.category_id
        )

        const _questions = [...currentTopic.questions]
        currentTopic.questions.filter((q, index) => {
          if (q?.question_id === payload.question_id) {
            _questions[index].answer = payload.message
            _questions[index].description = payload.description
          }
        })
        currentTopic.questions = [..._questions]

        if (indexOfCurrentTopic === -1) return
        unAnswered[indexOfCurrentTopic] = currentTopic
        showToast('Answer is successfully saved', TOAST_TYPE.success)
        return dispatch(setUnAnsweredQuestions([...unAnswered]))
      }
    } catch (err) {}
  }
}

export const getUnAnswerdQuestions = () => {
  return async (dispatch, getState) => {
    try {
      const sheikh_id = getState().auth.user.user_id
      dispatch(setAppCloseableLoadingState({ status: true, closeable: false , text:'Please wait loading data.'}))

      const questions = await http.get(
        `${ASK_A_SHEIKH_ENDPOINTS.GET_UNANSEWRD}/${sheikh_id}`
      )

      return dispatch(setUnAnsweredQuestions(questions.data.questions))
    } catch (err) {
      console.log(err)
    }
  }
}

const addQuestions = (payload) => {
  return (dispatch) => {
    return dispatch({
      type: ASK_A_SHEIKH_ACTION_TYPES.ADD_UNANSWERED_QUESTIONS,
      payload,
    })
  }
}

export const askSheikhQuestions = (payload) => {
  return async (dispatch) => {
    try {
      const question = await http.post(
        `${ASK_A_SHEIKH_ENDPOINTS.ASK_SHIEKH_QUESTION}`,
        payload
      )
      showToast('Question is successfully added', TOAST_TYPE.success)
      return dispatch(addQuestions(question.data))
    } catch (err) {
      console.log(err)
    }
  }
}
