import React, { useRef, useState } from 'react'
import {
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  Modal,
  Row,
} from 'react-bootstrap'

import classes from './style.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { CATEGORY_STATE } from 'reducers/categories/getState'
import { createMultiAssessment } from 'reducers/knowledgeJourney/knowledgeJourney.action'
import { useHistory } from 'react-router'
import { MultiSelect } from 'react-multi-select-component'
import { slice, values } from 'lodash'
import { TOAST_TYPE } from 'shared/functions'
import { showToast } from 'shared/functions'
import { KNOWLEDGE_JOURNEY_ENDPOINTS } from 'config/apis'
import { OnReplaceItem } from 'components/AssessmentConfirmation'
import Swal from 'sweetalert2'
import http from 'config/http'
import { AddCategoryForm } from 'components/AddCategoryForm/AddCategoryForm'
import { onReOrderList } from 'shared/functions'

export const CreateMultiAssessment = ({ show = false, onClose = () => {} }) => {
  const history = useHistory()

  const form = {
    title: '',
    scoref: 0,
    scoret: 0,
    assessments: [
      {
        dificultyLevel: -1,
        categories: [],
      },
    ],
  }

  const [formData, setFormData] = useState(form)
  const [selected, setSelected] = useState([])
  const topics = useSelector(CATEGORY_STATE.categories)
  const dispatch = useDispatch()
  const catModalRef = useRef(null)

  const showCatModal = () => {
    if (!catModalRef.current) return

    catModalRef.current?.showCatModal()
  }
  const resetForm = () => {
    setFormData(form)
  }

  const onGetTopicName = (topicID) => {
    const selected = topics.filter(
      (item) => item.category_id === Number(topicID)
    )
    return selected && selected[0]?.title
  }

  const onChangeInput = (key, value) => {
    const _formData = { ...formData }
    _formData[key] = value
    setFormData(_formData)
  }

  const onAddTopics = async (index, value) => {
    setSelected(value)

    let selectedArr = [...value]

    value.filter(
      (item, index) =>
        selectedArr[index].category_id !== item.value &&
        (selectedArr[index] = {
          category_id: item.value,
          TotalQuestions: 0,
        })
    )

    const clone = { ...formData }

    clone.assessments[index].categories = selectedArr

    setFormData(clone)
  }

  const onAddDifficultyLevel = (index, dificultyLevel) => {
    const clone = { ...formData }
    clone.assessments[index].dificultyLevel = dificultyLevel
    setFormData(clone)
  }

  const onRemove = (Index) => {
    const clone = { ...formData }
    clone.assessments = formData.assessments.filter(
      (item, index) => index !== Index
    )
    if (clone.assessments.length < 1) return
    setFormData(clone)
  }

  const onAddTotalQuestion = (assessmentIndex, index, totalquestions) => {
    const clone = { ...formData }
    clone.assessments[assessmentIndex].categories[index].numOfQuestions =
      totalquestions
    setFormData(clone)
  }

  const onFormClose = () => {
    resetForm()
    onClose()
  }

  const onGetSelectCategoryName = (id) => {
    const arr = topics.filter((_item, index) => _item.category_id === id)
    return arr.length > 0 ? arr[0].title : 'N/A'
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    if (formData?.title === '') {
      return showToast('Title is required', TOAST_TYPE.error)
    }
    if (formData?.scoret === 0) {
      return showToast('Score To is required', TOAST_TYPE.error)
    }
    if (formData?.scoref >= formData?.scoret) {
      return showToast(
        'Score From should be less than Score To',
        TOAST_TYPE.error
      )
    }

    for (var i = 0; i <= formData?.assessments?.length; i++) {
      if (formData?.assessments[i]?.dificultyLevel === -1) {
        return showToast('difficulty Level is required', TOAST_TYPE.error)
      }
      if (formData?.assessments[i]?.categories.length === 0) {
        return showToast('Categories are required', TOAST_TYPE.error)
      }
    }

    try {
      const value = await http.post(
        KNOWLEDGE_JOURNEY_ENDPOINTS.CHECK_QUESTION_MULTI_ASSESSMENTS,
        formData
      )
      if (value?.data?.data?.status) {
        return await OnReplaceItem({
          onApplySuccessFunction: async () => {
            await dispatch(createMultiAssessment(formData))
            onFormClose()
            Swal.fire('Created!', 'Your file has been Created.', 'success')
          },
          title: onGetSelectCategoryName(
            formData?.assessments[0]?.categories[0].category_id
          ),
          question: value?.data?.data?.questionsMax,
        })
      } else {
        onFormClose()
        Swal.fire('Cancelled!', 'You cancelled the request.', 'error')
      }
    } catch (error) {
      console.log(error)
      return showToast(error?.response?.data?.message, TOAST_TYPE.error)
    }
  }

  const onAddMoreAssessments = () => {
    const clone = { ...formData }
    clone.assessments[
      clone?.assessments?.length < 1 ? 0 : clone?.assessments?.length
    ] = {
      dificultyLevel: 0,
      categories: [],
    }
    setFormData(clone)
  }

  const onRemoveQuestion = (assessmentIndex, index, category_id) => {
    const cloneArr = [...selected]
    const Filtered = cloneArr.filter((item) => item.value !== category_id)
    setSelected(Filtered)
    const clone = { ...formData }
    const data = formData.assessments[assessmentIndex].categories.filter(
      (item) => parseInt(item.category_id) !== parseInt(category_id)
    )
    clone.assessments[assessmentIndex].categories = data
    setFormData(clone)
  }

  const onCreateTopicsData = () => {
    let topicsArr = []
    onReOrderList(topics).map((item, index) => {
      item.title.toLowerCase() !== 'uncategorize' &&
        topicsArr.push({ label: item.title, value: item.category_id })
    })

    return topicsArr
  }

  return (
    <>
      <Modal
        show={show}
        onHide={onFormClose}
        size="lg"
        animation={false}
        backdrop="static">
        <Form onSubmit={onSubmit}>
          <Modal.Header
            style={{ display: 'flex', alignItems: 'center' }}
            closeButton>
            <Modal.Title>
              Create Auto-generated assessment from question bank
            </Modal.Title>
            {/* <Row style={{ marginTop: '10px' }}>
            <Col
              sm="12"
              md="6"
              lg="8"
              style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                size="sm"
                type="button"
                className="btn-fill"
                onClick={onAddMoreAssessments}
                style={{ width: '250px' }}>
                Add More
              </Button>
            </Col>
          </Row> */}
          </Modal.Header>
          <Modal.Body className={classes.modal_body}>
            <div className={classes.question}>
              <Row>
                <Col sm="12" md="12" lg="12">
                  <FormGroup>
                    <Form.Label>Assessment Title *</Form.Label>
                    <FormControl
                      type="text"
                      placeholder="Assessment Title *"
                      value={formData.title}
                      onChange={(e) => onChangeInput('title', e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row style={{ marginTop: '10px' }}>
                <Col sm="12" md="6" lg="6">
                  <FormGroup>
                    <Form.Label>Scores From *</Form.Label>
                    <FormControl
                      type="number"
                      min="0"
                      max="100"
                      placeholder="Scores From *"
                      value={formData.scoref}
                      onChange={(e) =>
                        onChangeInput('scoref', parseInt(e.target.value))
                      }
                    />
                  </FormGroup>
                </Col>
                <Col sm="12" md="6" lg="6">
                  <FormGroup>
                    <Form.Label>Scores To *</Form.Label>
                    <FormControl
                      type="number"
                      min="0"
                      max="100"
                      placeholder="Scores To *"
                      value={formData.scoret}
                      onChange={(e) =>
                        onChangeInput('scoret', parseInt(e.target.value))
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              {formData?.assessments?.map((item, assessmentIndex) => (
                <div className={classes.question} key={assessmentIndex}>
                  <Row>
                    <Col
                      sm="12"
                      md="12"
                      style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button
                        variant="danger"
                        size="sm"
                        className="btn btn-fill"
                        onClick={() => onRemove(assessmentIndex)}>
                        &times;
                      </Button>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '10px' }}>
                    <Col sm="12" md="6" lg="6">
                      <FormGroup>
                        <Form.Label>Difficulty Level *</Form.Label>
                        <select
                          className={classes.select}
                          value={item.dificultyLevel}
                          onChange={(e) =>
                            onAddDifficultyLevel(
                              assessmentIndex,
                              parseInt(e.target.value)
                            )
                          }>
                          <option value="">Select Difficulty Level</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                          <option value="13">13</option>
                          <option value="14">14</option>
                          <option value="15">15</option>
                          <option value="16">16</option>
                        </select>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row style={{ marginBottom: '10px' }}>
                    <Col
                      sm="12"
                      md="12"
                      style={{ display: 'flex', flexDirection: 'column' }}
                      className={classes.separate}>
                      <Form.Label>Topic *</Form.Label>
                      <div
                        style={{
                          display: 'flex',
                          width: '100%',
                        }}>
                        <div style={{ width: '70%' }}>
                          <MultiSelect
                            options={onCreateTopicsData()}
                            value={selected}
                            closeOnChangedValue={true}
                            // onChange={setSelected}
                            hasSelectAll={false}
                            onChange={(e) => onAddTopics(assessmentIndex, e)}
                            labelledBy="Select"
                          />
                        </div>
                        <Button
                          style={{ width: '29%', marginLeft: '10px' }}
                          className="btn-fill"
                          variant="primary"
                          size="sm"
                          onClick={showCatModal}>
                          Add New Topic
                        </Button>
                      </div>
                      {/* <select
                      className={classes.select}
                      value={item.category_id}
                      onChange={(e) =>
                        e.target.value !== "NextPage"
                          ? onAddTopics(assessmentIndex, e.target.value)
                          : history.push({ pathname: "/admin/categories" })
                      }
                      
                    >
                      <option value="">Select Topic</option>
                      {topics.map((topic, index) => {
                        if (topic.title.toLowerCase() !== "uncategorize")
                          return (
                            <option
                              value={topic.category_id}
                              key={index.toString()}
                            >
                              {topic?.title}
                            </option>
                          );
                      })}
                      <option value="NextPage">Add New Categories</option>
                    </select> */}
                    </Col>
                  </Row>
                  {item?.categories &&
                    item?.categories.map((item, index) => (
                      <Row key={index} style={{ margin: '20px 0' }}>
                        <Col sm="12" md="5" lg="5">
                          <FormGroup>
                            <div> {onGetTopicName(item?.category_id)} </div>
                          </FormGroup>
                        </Col>

                        <Col sm="12" md="5" lg="5">
                          <FormGroup>
                            <FormControl
                              type="number"
                              min="1"
                              max="16"
                              placeholder="No of Questions *"
                              value={item.category_id?.numOfQuestions}
                              onChange={(e) =>
                                onAddTotalQuestion(
                                  assessmentIndex,
                                  index,
                                  e.target.value
                                )
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col
                          sm="2"
                          md="2"
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}>
                          <Button
                            variant="danger"
                            size="sm"
                            className="btn btn-fill"
                            onClick={() =>
                              onRemoveQuestion(
                                assessmentIndex,
                                index,
                                item.category_id
                              )
                            }>
                            &times;
                          </Button>
                        </Col>
                      </Row>
                    ))}
                </div>
              ))}
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button
              size="sm"
              type="button"
              variant="default"
              onClick={onFormClose}>
              Cancel
            </Button>
            <Button
              className="btn-fill pull-right"
              type="submit"
              variant="primary"
              size="sm">
              Submit Questions
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <AddCategoryForm ref={catModalRef} />
    </>
  )
}
