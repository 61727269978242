const { QUIZ_ACTION_TYPES } = require("./quiz.actions");

const INITIAL_STATE = {
  quiz: {},
};

const QuizReducer = (state = INITIAL_STATE, actions) => {
  switch (actions.type) {
    case QUIZ_ACTION_TYPES.SET_QUIZ:
      return {
        ...state,
        quiz: { ...actions.payload },
      };

    default:
      return state;
  }
};

export default QuizReducer;
