import React, { useState, useEffect } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

import classes from "./style.module.css";
import { useSelector } from "react-redux";

import { showToast } from "shared/functions";
import { TOAST_TYPE } from "shared/functions";
import { isEmailValid } from "shared/functions";
import { GLOBAL_STATE } from "reducers/appLoading/getAppState";
import { REG_ROLES } from "shared/CONSTANTS";
import PhoneInput from "react-phone-number-input";

export const EditUser = ({
  show = false,
  userData,
  onClose = () => {},
  onSubmit = (formData) => {}
}) => {
  const [formData, setFormData] = useState({
    fname: userData?.fname,
    lname: userData?.lname,
    email: userData?.email,
    city: userData?.city,
    country: userData?.country,
    zipCode: userData?.zipCode,
    user_id: userData?.user_id
  });

  useEffect(() => {
    setFormData({
      fname: userData?.fname,
      lname: userData?.lname,
      email: userData?.email,
      city: userData?.city,
      country: userData?.country,
      zipCode: userData?.zipCode,
      user_id: userData?.user_id
    });
  }, [userData]);

  const appLoading = useSelector(GLOBAL_STATE.loading);

  const onChangeInput = (key, value) => {
    const _formData = { ...formData };

    _formData[key] = value;

    setFormData(_formData);
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    const { email } = formData;
    if (!isEmailValid(email)) {
      return showToast("Please enter valid email", TOAST_TYPE.error);
    }


    onSubmit(formData);
  };

  const resetForm = () => {
    setFormData({
      fname: "",
      lname: "",
      email: "",
      city: "",
      country: "",
      zipCode: ""
    });
  };

  const onFormClose = () => {
    resetForm();
    onClose();
  };

  return (
    <Modal
      show={show}
      onHide={onFormClose}
      size="lg"
      animation={false}
      backdrop="static"
    >
      <Form onSubmit={onSubmitForm}>
        <Modal.Header style={{ display: "flex", alignItems: "center" }}>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body className={classes.modal_body}>
          <div>
            <Row>
              <Col className="pl-1" md="6" lg="6" sm="12">
                <Form.Group>
                  <label>First Name</label>
                  <Form.Control
                    name="name"
                    placeholder="First Name"
                    type="text"
                    // defaultValue={userData?.fname}
                    onChange={(e) => onChangeInput("fname", e.target.value)}
                    value={formData.fname}
                    required
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col className="pl-1" md="6" lg="6" sm="12">
                <Form.Group>
                  <label>Last Name</label>
                  <Form.Control
                    name="name"
                    placeholder="Last Name"
                    type="text"
                    defaultValue={userData?.lname}
                    onChange={(e) => onChangeInput("lname", e.target.value)}
                    // value={formData.lname}
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className="pl-1" md="6">
                <Form.Group>
                  <label>Email address</label>
                  <Form.Control
                    placeholder="Email"
                    type="email"
                    defaultValue={userData?.email}
                    // value={formData?.email}
                    onChange={(e) => onChangeInput("email", e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>

              {/* <Col className="pl-1" md="6">
                <Form.Group>
                  <label>Contact</label>
                  <PhoneInput
                    international
                    countryCallingCodeEditable={false}
                    // value={formData.contact}
                    defaultValue={userData?.contact}
                    defaultCountry={userData?.contactCountry}
                    onCountryChange={(country) =>
                      onChangeInput("contactCountry", country)
                    }
                    onChange={(contact) => onChangeInput("contact", contact)}
                  />
                </Form.Group>
              </Col> */}
            </Row>
            <Row>
              <Col className="pr-1" md="4">
                <Form.Group>
                  <label>City</label>
                  <Form.Control
                    placeholder="City"
                    type="text"
                    // defaultValue={userData?.city}
                    value={formData.city}
                    onChange={(e) => onChangeInput("city", e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col className="px-1" md="4">
                <Form.Group>
                  <label>Country</label>
                  <Form.Control
                    placeholder="Country"
                    type="text"
                    defaultValue={userData?.country}
                    // value={formData.country}
                    onChange={(e) => onChangeInput("country", e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col className="pl-1" md="4">
                <Form.Group>
                  <label>Postal Code</label>
                  <Form.Control
                    placeholder="ZIP Code"
                    type="number"
                    defaultValue={userData?.zipCode}
                    // value={formData.zipCode}
                    onChange={(e) => onChangeInput("zipCode", e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <div className="clearfix"></div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            size="sm"
            type="button"
            variant="default"
            onClick={onFormClose}
          >
            Cancel
          </Button>
          <Button
            className="btn-fill pull-right"
            type="submit"
            variant="primary"
            size="sm"
            block
            disabled={
              appLoading ||
              !formData.fname ||
              !formData.email ||
              // !formData.contact ||
              !formData.city ||
              !formData.country
            }
          >
            Submit
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
