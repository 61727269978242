import React from 'react'
import { Col, Row, Table } from 'react-bootstrap'
import { TableRow } from './TableRow'

export const TableComponent = ({
  data = [],
  onDelete = (item) => {},
  onEdit = (item) => {},
  deleteEnable = '',
  editEnable = '',
}) => {
  return (
    <Row>
      <Col sm="12" md="12" lg="12">
        {!data || !data?.length ? (
          <p>No Content</p>
        ) : (
          <Table className="table-hover table-striped">
            <thead>
              <tr>
                <th className="border-0">#</th>

                <th className="border-0">Image</th>
                <th className="border-0">First Name</th>
                <th className="border-0">Last Name</th>
                <th className="border-0">Email</th>
                <th className="border-0">Role</th>
                <th className="border-0">Country</th>

                <th className="border-0">Created At</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => (
                <TableRow
                  key={index?.toString()}
                  item={item}
                  index={index}
                  // onClick={onCourseSelect}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  deleteEnable={deleteEnable}
                  editEnable={editEnable}
                />
              ))}
            </tbody>
          </Table>
        )}
      </Col>
    </Row>
  )
}
