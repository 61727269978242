import React, { useRef, useState } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'

import classes from './QuizHeader.module.css'
import { AUTH_STATE } from 'reducers/auth/getState'
import { COURSES_STATE } from 'reducers/course/getState'
import { AddQuizForm } from 'components/AddQuizForm/AddQuizForm'
import { QUIZ_STATE } from 'reducers/quiz/getState'
import { isEmpty } from 'lodash'
import { AddQuestionForm } from 'components/AddQuestionForm/AddQuestionForm'
import { deleteQuiz } from 'reducers/quiz/quiz.actions'
import { OnDeleteItem } from 'components/DeleteConfirmation'
import { SUPER_ADMIN } from 'shared/CONSTANTS'

export const QuizHeader = (props) => {
  const currentUser = useSelector(AUTH_STATE.user)
  const selectedCourse = useSelector(COURSES_STATE.course)
  const quiz = useSelector(QUIZ_STATE.quiz)
  const dispatch = useDispatch()
  const modalRef = useRef(null)
  const questionRef = useRef(null)

  const { location: { state: { lesson_id } = {} } = {} } = props || {}

  const onClickAdd = () => {
    if (!modalRef.current) return

    modalRef.current.showModal()
  }

  const onClickAddQuestion = () => {
    if (!questionRef.current) return

    questionRef.current.showModal()
  }

  const onDeleteQuiz = async () => {
    await dispatch(
      deleteQuiz({
        course_id: quiz?.course_id,
        quiz_id: quiz?.quiz_id,
        lesson_id: quiz?.lesson_id,
      })
    )
  }

  const RenderButton = () => {
    if (isEmpty(quiz)) {
      return (
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <Button
            className={`btn-fill ${classes.create_btn}`}
            variant={'primary'}
            onClick={onClickAdd}
            size="sm">
            Add Quiz
            <i className={`nc-icon nc-simple-add ${classes.create_btn_icon}`} />
          </Button>
        </div>
      )
    }

    return (
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        {!props.togglePages && (
          <>
            <Button
              className={`btn-fill ${classes.create_btn}`}
              variant={'primary'}
              onClick={onClickAddQuestion}
              style={{ whiteSpace: 'nowrap' }}
              size="sm">
              Add Question
              <i
                className={`nc-icon nc-simple-add   ${classes.create_btn_icon}`}
              />
            </Button>

            <Button
              style={{ whiteSpace: 'nowrap' }}
              className={`btn-fill ml-1 ${classes.create_btn}`}
              variant={'danger'}
              onClick={() =>
                OnDeleteItem({
                  onApplySuccessFunction: onDeleteQuiz,
                  title: 'Quiz',
                })
              }
              // onClick={onDeleteQuiz}
              size="sm">
              Delete Quiz
              <i className={`fas fa-trash ${classes.create_btn_icon}`} />
            </Button>
          </>
        )}
        {currentUser.role === SUPER_ADMIN.role && (
          <Button
            style={{ whiteSpace: 'nowrap' }}
            onClick={props.onTogglePage}
            size="sm"
            className="btn-fill ml-1"
            variant={'primary'}>
            {props.togglePages ? 'Back' : 'Restore Question'}
          </Button>
        )}
      </div>
    )
  }

  return (
    <>
      <Row>
        <Col sm="12" md="12" lg="12">
          {selectedCourse &&
            selectedCourse.user?.user_id === currentUser?.user_id && (
              <RenderButton />
            )}
        </Col>
      </Row>
      <AddQuizForm ref={modalRef} {...props} />
      <AddQuestionForm
        ref={questionRef}
        quiz_id={quiz.quiz_id}
        course_id={selectedCourse.course_id}
        lesson_id={lesson_id}
      />
    </>
  )
}
