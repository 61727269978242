import React from 'react'
import { Col, Row, Table } from 'react-bootstrap'
import { TableRow } from './TableRow'

export const TableComponent = ({
  data = [],
  onDelete = (item) => {},
  onEdit = (item) => {},
  deleteEnable = '',
  editEnable = '',
  searchedItem
}) => {

  return (
    <Row>
      <Col sm="12" md="12" lg="12">
        {!data || !data?.length ? (
          <p>No Content</p>
        ) : (
          <Table className="table-hover table-striped">
            <thead>
              <tr style={{ textAlign: 'center' }}>
                <th className="border-0">#</th>
                <th className="border-0">Title</th>
                <th className="border-0">Topic</th>
                {data[0]?.level && (
                  <th className="border-0">Difficulty Level</th>
                )}
                {/* <th className="border-0">View Details</th> */}
                <th className="border-0">Dated</th>
                {/* <th className="border-0">Edit | Delete</th> */}
              </tr>
            </thead>
            <tbody>
              {data?.map(
                (question, index) =>
                question?.title?.toLowerCase().includes(searchedItem.toLowerCase()) && !question.is_deleted && (
                    <TableRow
                      key={index?.toString()}
                      item={question}
                      index={index}
                      // onClick={onCourseSelect}
                      onEdit={onEdit}
                      onDelete={onDelete}
                      deleteEnable={deleteEnable}
                      editEnable={editEnable}
                    />
                  )
              )}
            </tbody>
          </Table>
        )}
      </Col>
    </Row>
  )
}
