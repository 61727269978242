import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { createQuiz } from 'reducers/quiz/quiz.actions'
import { TextEditor } from 'components/TextEditor/TextEditor'
import { updateCourse } from 'reducers/course/courses.action'
import { redirectTo } from 'shared/functions'
import { getCourses } from 'reducers/course/courses.action'

export const AddQuizForm = forwardRef((props, ref) => {
  const [show, setModal] = useState(false)
  const [formData, setFormData] = useState({
    title: '',
    desc: '',
  })
  const editorRef = useRef(null)

  const { location: { state: { course_id, lesson_id } = {} } = {} } =
    props || {}

  const dispatch = useDispatch()

  useImperativeHandle(ref, () => ({
    showModal: () => {
      setModal(true)
    },
  }))

  const onClose = () => {
    setModal(false)
  }

  const onChangeText = (key, value) => {
    const _formData = { ...formData }
    _formData[key] = value
    setFormData(_formData)
  }

  const onSubmitForm = async (e) => {
    e.preventDefault()

    await dispatch(
      createQuiz({
        ...formData,
        course_id: props.Quizcourse ? props.Quizcourse.course_id : course_id,
        lesson_id,
      })
    )
    if (show) {
      setModal(false)
      setFormData({
        title: '',
        desc: '',
        icon: '',
      })
    }

    if (props.Quizcourse) {
      await dispatch(getCourses())
    }
  }
  return (
    <Modal
      show={show}
      onHide={onClose}
      size="lg"
      animation={false}
      backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Add Quiz</Modal.Title>
      </Modal.Header>
      <Form onSubmit={onSubmitForm}>
        <Modal.Body>
          <Row>
            <Col sm="12" lg="12" md="12">
              <Form.Group>
                <Form.Label>Title</Form.Label>
                <Form.Control
                  placeholder="Title"
                  type="text"
                  name="title"
                  value={formData.title}
                  onChange={(e) => onChangeText('title', e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col sm="12" lg="12" md="12">
              <Form.Group>
                <Form.Label>Description</Form.Label>
                {/* <Form.Control
                  placeholder="Quiz Description"
                  type="text"
                  as="textarea"
                  rows="4"
                  name="desc"
                  value={formData.desc}
                  onChange={(e) => onChangeText("desc", e.target.value)}
                /> */}
                <TextEditor
                  // value={editCourse.description}
                  placeholder="Quiz Details"
                  ref={editorRef}
                  onChange={(value) => onChangeText('desc', value)}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button type="button" variant="default" onClick={onClose}>
            Cancel
          </Button>
          <Button
            className="btn-fill pull-right"
            type="submit"
            variant={'primary'}
            disabled={!formData?.title}>
            {'Add'}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
})
