import React from 'react'
import { Button, Card, Col, Container, Row } from 'react-bootstrap'
import { withRouter } from 'react-router'

import classes from './quizes.module.css'
import { QuizHeader } from 'components/QuizHeader/QuizHeader'
import { QuizTable } from 'components/QuizTable/QuizTable'
import { useSelector } from 'react-redux'
import { QUIZ_STATE } from 'reducers/quiz/getState'
import { isEmpty } from 'lodash'
import { useState } from 'react'

const Quizes = (props) => {
  const quiz = useSelector(QUIZ_STATE.quiz)
  const [togglePages, settogglePages] = useState(false)

  return (
    <Container fluid>
      <Row>
        <Col sm="12" lg="12" md="12">
          <Card>
            <Card.Header>
              <Row>
                <Col sm="12" lg="7" md="7" className={classes.header}>
                  <Button
                    size="sm"
                    className={`btn-fill ${classes.backBtn}`}
                    onClick={() => props?.history.goBack()}>
                    <i
                      className="nc-icon nc-stre-left"
                      style={{ fontSize: 14, fontWeight: 'bolder' }}
                    />
                  </Button>
                  <Card.Title>
                    {isEmpty(quiz) ? 'Quiz not added' : quiz?.title}
                  </Card.Title>
                </Col>
                <Col sm="12" lg="5" md="5">
                  <QuizHeader
                    togglePages={togglePages}
                    onTogglePage={() => settogglePages(!togglePages)}
                    {...props}
                  />
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <QuizTable togglePages={togglePages} {...props} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default withRouter(Quizes)
