import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Button, Col, Form, Modal, Row, FormGroup, Alert } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'

import { FileSelectionInput } from 'components/AddLessonsForm/FileSelectionInput.js'
import { FormFileViewer } from 'components/AddLessonsForm/FormFileViewer'
import { CustomDropdown } from 'components/CustomDropdown/CustomDropdown'
import { isEmpty } from 'lodash'
import { ILMA_TYPE } from 'shared/CONSTANTS'
import { ILMA_STATE } from 'reducers/ilma/getState'
import { createIlma } from 'reducers/ilma/ilma.actions'
import { updateIlma } from 'reducers/ilma/ilma.actions'
import { TextEditor } from 'components/TextEditor/TextEditor'
import { TimeZonesList } from 'shared/CONSTANTS'
import classes from './style.module.css'
import { showToast } from 'shared/functions'
import { TOAST_TYPE } from 'shared/functions'
import http from 'config/http'
import { ILMA_ENDPOINTS } from 'config/apis'
import { OnReplaceItem } from 'components/ReplaceConfirmation'
import { replaceIlma } from 'reducers/ilma/ilma.actions'
import { deleteIlma } from 'reducers/ilma/ilma.actions'
import Swal from 'sweetalert2'
import { CATEGORY_STATE } from 'reducers/categories/getState'
import { onReOrderList } from 'shared/functions'

const ILMA_TYPES = [
  {
    title: 'Text',
    value: 'text',
  },
  {
    title: 'Video',
    value: 'video',
  },
  {
    title: 'pdf',
    value: 'pdf',
  },
]

export const CreateIlmaForm = ({
  show = false,
  onClose = () => {},
  editIlma = false,
}) => {
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    file: '',
    type: '',
    pubtype: '',
    category_ids: '',
    schedule_time: '',
    schedule_date: '',
  })

  const dispatch = useDispatch()
  const categories = useSelector(CATEGORY_STATE.categories)
  const selectedIlma = useSelector(ILMA_STATE.selectedIlma)
  const editorRef = useRef(null)

  useEffect(() => {
    if (!editIlma || isEmpty(selectedIlma)) return

    setFormData(selectedIlma)
  }, [editIlma, selectedIlma])

  const onChangeInput = (key, value) => {
    if (key.toLowerCase() === 'type') {
      const _formData = { ...formData }
      _formData.file = ''
      _formData[key] = value
      setFormData(_formData)
    } else {
      const _formData = { ...formData }
      _formData[key] = value
      setFormData(_formData)
    }
  }

  const convertToKualaLumpurTime = (dateString, timeString, timeZone) => {
    const dateTimeString = `${dateString}T${timeString}`
    const dateTime = new Date(dateTimeString)

    const klTime = dateTime.toLocaleString('en-US', {
      timeZone: 'Asia/Kuala_Lumpur',
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
    })

    return klTime
  }

  const onSubmitForm = async () => {
    console.log(formData)
    if (formData?.title === '') {
      return showToast('Ilma Title is required', TOAST_TYPE.error)
    }
    if (!formData?.category_ids) {
      return showToast('Category Ids is required', TOAST_TYPE.error)
    }
    if (formData?.pubtype === '') {
      return showToast('Publication Type is required', TOAST_TYPE.error)
    }
    if (
      formData?.pubtype === 'schedule_date' &&
      formData?.schedule_date === ''
    ) {
      return showToast(
        'Publication Schedule date is required',
        TOAST_TYPE.error
      )
    }
    if (
      formData?.pubtype === 'schedule_date' &&
      formData?.schedule_time === ''
    ) {
      return showToast(
        'Publication Schedule time is required',
        TOAST_TYPE.error
      )
    }
    if (formData?.type === '') {
      return showToast('Ilma type is required', TOAST_TYPE.error)
    }
    if (formData?.type === 'text' && formData.description === '') {
      return showToast('Description is required', TOAST_TYPE.error)
    }
    if (formData?.type === 'video' && formData.file === '') {
      return showToast('Video is required', TOAST_TYPE.error)
    }
    if (formData?.type === 'pdf' && formData.file === '') {
      return showToast('PDF is required', TOAST_TYPE.error)
    }
    try {
      const convertTime = convertToKualaLumpurTime(
        formData?.pubtype === 'publish_imediately'
          ? moment().format('YYYY-MM-DD')
          : formData.schedule_date,
        moment().format('HH:mm')
      )

      const value = await http.post(ILMA_ENDPOINTS.CHECK_ILMA_SCHEDULE, {
        title: formData.title,
        description: formData.description,
        file: formData.file,
        type: formData.type,
        pubtype: formData.pubtype,
        category_ids: formData.category_ids,
        schedule_time: convertTime,
        schedule_date:
          formData?.pubtype === 'publish_imediately'
            ? moment().format('YYYY-MM-DD')
            : formData.schedule_date,
        Ilma: selectedIlma.ilma_id,
      })

      if (value.data.exist) {
        return await OnReplaceItem({
          onApplySuccessFunction: async () => {
            if (!editIlma) {
              await dispatch(
                replaceIlma({
                  ilma_id: value.data.ilmas.ilma_id,
                  title: formData.title,
                  description: formData.description,
                  file: formData.file,
                  type: formData.type,
                  pubtype: formData.pubtype,
                  category_ids: formData.category_ids,
                  schedule_time:
                    formData?.pubtype === 'publish_imediately'
                      ? convertTime
                      : convertToKualaLumpurTime(
                          formData?.schedule_date,
                          formData?.schedule_time
                        ),

                  schedule_date:
                    formData?.pubtype === 'publish_imediately'
                      ? moment().format('YYYY-MM-DD')
                      : formData.schedule_date,
                })
              )
              await onResetFormAndCloseModal()
              Swal.fire('Replaced!', 'Your file has been replaced.', 'success')
            } else {
              if (formData.ilma_id == value.data.ilmas.ilma_id) {
                await dispatch(
                  replaceIlma({
                    ilma_id: value.data.ilmas.ilma_id,
                    title: formData.title,
                    description: formData.description,
                    file: formData.file,
                    type: formData.type,
                    category_ids: formData.category_ids,
                    pubtype: formData.pubtype,
                    schedule_time:
                      formData?.pubtype === 'publish_imediately'
                        ? convertToKualaLumpurTime(
                            formData?.schedule_date,
                            moment().format('HH:mm')
                          )
                        : convertToKualaLumpurTime(
                            formData?.schedule_date,
                            formData?.schedule_time
                          ),

                    schedule_date:
                      formData?.pubtype === 'publish_imediately'
                        ? moment().format('YYYY-MM-DD')
                        : formData.schedule_date,
                  })
                )
                await onResetFormAndCloseModal()
                Swal.fire(
                  'Replaced!',
                  'Your file has been replaced.',
                  'success'
                )
              } else {
                await dispatch(deleteIlma(formData?.ilma_id))
                await dispatch(
                  replaceIlma({
                    ilma_id: value.data.ilmas.ilma_id,
                    title: formData.title,
                    description: formData.description,
                    file: formData.file,
                    type: formData.type,
                    category_ids: formData.category_ids,
                    pubtype: formData.pubtype,
                    schedule_time:
                      formData?.pubtype === 'publish_imediately'
                        ? convertTime
                        : convertToKualaLumpurTime(
                            formData?.schedule_date,
                            formData?.schedule_time
                          ),

                    schedule_date:
                      formData?.pubtype === 'publish_imediately'
                        ? moment().format('YYYY-MM-DD')
                        : formData.schedule_date,
                  })
                )
                await onResetFormAndCloseModal()
                Swal.fire(
                  'Replaced!',
                  'Your file has been replaced.',
                  'success'
                )
              }
            }
          },
          date: value.data.ilmas.schedule_date,
          title: value.data.ilmas.title,
        })
      } else {
        onSaveTheData()
      }

      // if (value.data.message) showToast(value?.data?.message, TOAST_TYPE.error)
    } catch (error) {
      onSaveTheData()
      return showToast(error?.response?.data?.message, TOAST_TYPE.error)
    }
  }

  const onResetFormAndCloseModal = () => {
    onClose()
    setFormData({
      title: '',
      description: '',
      file: '',
      type: '',
      pubtype: '',
      schedule_date: moment().format('YYYY-MM-DD'),
      schedule_time: '',
    })
  }
  const onSaveTheData = async () => {
    const convertTime = convertToKualaLumpurTime(
      formData?.pubtype === 'publish_imediately'
        ? moment().format('YYYY-MM-DD')
        : formData.schedule_date,
      moment().format('HH:mm')
    )
    if (!editIlma) {
      await dispatch(
        createIlma({
          title: formData.title,
          description: formData.description,
          file: formData.file,
          type: formData.type,
          pubtype: formData.pubtype,
          category_ids: formData.category_ids,
          schedule_time:
            formData?.pubtype === 'publish_imediately'
              ? convertTime
              : convertToKualaLumpurTime(
                  formData?.schedule_date,
                  formData?.schedule_time
                ),
          schedule_date:
            formData?.pubtype === 'publish_imediately'
              ? moment().format('YYYY-MM-DD')
              : formData.schedule_date,
        })
      )
      onResetFormAndCloseModal()
    } else {
      await dispatch(
        updateIlma({
          title: formData.title,
          description: formData.description,
          file: formData.file,
          type: formData.type,
          category_ids: formData.category_ids,
          pubtype: formData.pubtype,
          schedule_time:
            formData?.pubtype === 'publish_imediately'
              ? convertTime
              : convertToKualaLumpurTime(
                  formData?.schedule_date,
                  formData?.schedule_time
                ),

          schedule_date:
            formData?.pubtype === 'publish_imediately'
              ? moment().format('YYYY-MM-DD')
              : formData.schedule_date,
        })
      )
      onResetFormAndCloseModal()
    }
  }

  const onPressCancelBtn = () => {
    onClose()
    setFormData({
      title: '',
      description: '',
      file: '',
      type: '',
      pubtype: '',
      schedule_time: '',
      schedule_date: '',
    })
  }

  const onGetCategoryTitle = () => {
    const selectedTitle = categories.filter(
      (item) => parseInt(item.category_id) === parseInt(formData?.category_ids)
    )
    return selectedTitle?.length > 0
      ? selectedTitle[0].title
      : 'Select Category'
  }
  return (
    <Modal
      show={show}
      onHide={onClose}
      size="lg"
      animation={false}
      backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>
          {editIlma && formData?.schedule_date.split('T')[0] <
          moment().format('YYYY-MM-DD') ? (
            <div>View ILMA</div>
          ) : editIlma && formData?.schedule_date.split('T')[0] <=
              moment().format('YYYY-MM-DD') &&
            moment(formData?.schedule_time, ['hh:mm']).format('hh:mm A') <=
              moment().format('hh:mm A') ? (
            <div>View ILMA</div>
          ) : (
            <>{editIlma ? 'Update ILMA' : 'Create New ILMA'}</>
          )}
        </Modal.Title>
      </Modal.Header>
      <Fragment>
        <Modal.Body>
        <Alert key='info' variant='info'>
          Published Ilma will be scheduled in Kuala Lumpur Timezone.
        </Alert>
          <Row>
            <Col lg="4" md="4" sm="12">
              <Form.Group>
                <Form.Label>Title</Form.Label>
                <Form.Control
                  name="title"
                  placeholder="Ilma Title"
                  type="text"
                  value={formData.title}
                  onChange={(e) =>
                    onChangeInput('title', e.target.value)
                  }></Form.Control>
              </Form.Group>
            </Col>
            <Col lg="4" md="4" sm="12">
              <Form.Label>Categories</Form.Label>
              <Form.Group>
                <CustomDropdown
                  title={
                    formData?.category_ids !== ''
                      ? onGetCategoryTitle()
                      : 'Select Category'
                  }
                  items={onReOrderList(categories)}
                  onSelect={(category) =>
                    onChangeInput('category_ids', category.category_id)
                  }
                />
              </Form.Group>
            </Col>
            <Col sm="12" md="4">
              <FormGroup>
                <Form.Label>Publication Type *</Form.Label>
                <select
                  className={classes.select}
                  value={formData?.pubtype}
                  onChange={(e) => onChangeInput('pubtype', e.target.value)}>
                  <option value="">select type</option>
                  <option value="publish_imediately">Immediate</option>
                  <option value="schedule_date">Scheduled</option>
                </select>
              </FormGroup>
            </Col>
            {formData?.pubtype === 'schedule_date' && (
              <>
                <Col sm="12" md="4">
                  <Form.Label>Date</Form.Label>
                  <Form.Control
                    type="date"
                    min={`${moment(new Date())?.format('YYYY-MM-DD')}`}
                    onChange={(e) =>
                      onChangeInput(
                        'schedule_date',
                        moment(e.target.value)
                          // .tz(timeZone)
                          .format('YYYY-MM-DD')
                        // .toString()
                      )
                    }
                    value={formData?.schedule_date.split('T')[0]}
                  />
                </Col>
                <Col sm="12" md="4">
                  <Form.Label>Time</Form.Label>
                  <Form.Control
                    type="time"
                    onChange={(e) =>
                      onChangeInput('schedule_time', e.target.value)
                    }
                    value={formData?.schedule_time}
                  />
                </Col>
              </>
            )}
          </Row>
          <Row>
            <Col lg="6" md="6" sm="12">
              <Form.Label>Ilma Type</Form.Label>
              <CustomDropdown
                title={
                  !isEmpty(formData.type) ? formData.type : 'Select Ilma Type'
                }
                items={ILMA_TYPES}
                onSelect={(type) => onChangeInput('type', type.value)}
              />
            </Col>
          </Row>
          {formData.type === ILMA_TYPE.VIDEO ? (
            <Row style={{ marginTop: 12 }}>
              <Col sm="6" md="3">
                {!formData.file ? (
                  <FileSelectionInput
                    onFilesSelected={(file) => {
                      const _formData = { ...formData }
                      _formData['file'] = file[0]
                      setFormData(_formData)
                    }}
                    accept="video/mp4,video/x-m4v,video/*"
                    title="Select Video"
                    multiple={false}
                  />
                ) : (
                  <FormFileViewer
                    file={formData.file}
                    isPDF={false}
                    isVideo={true}
                    onRemove={(file) => {
                      const _formData = { ...formData }
                      _formData['file'] = ''
                      setFormData(_formData)
                    }}
                  />
                )}
              </Col>
            </Row>
          ) : formData.type === ILMA_TYPE.PDF ? (
            <Row style={{ marginTop: 12 }}>
              <Col sm="6" md="3">
                {!formData.file ? (
                  <FileSelectionInput
                    onFilesSelected={(file) => {
                      const _formData = { ...formData }
                      _formData['file'] = file[0]

                      setFormData(_formData)
                    }}
                    accept=".pdf"
                    title="Select PDF"
                    multiple={false}
                  />
                ) : (
                  <FormFileViewer
                    file={formData.file}
                    isVideo={false}
                    isPDF={true}
                    onOpenPDF={() => {}}
                    onRemove={(file) => {
                      const _formData = { ...formData }
                      _formData['file'] = ''
                      setFormData(_formData)
                    }}
                  />
                )}
              </Col>
            </Row>
          ) : (
            formData.type === ILMA_TYPE.TEXT && (
              <Row>
                <Col lg="12" md="12" sm="12">
                  <Form.Group>
                    <Form.Label>Description</Form.Label>
                    {/* <Form.Control
                      name="description"
                      placeholder="Description"
                      type="text"
                      rows="4"
                      as="textarea"
                      value={formData.description}
                      onChange={(e) =>
                        onChangeInput("description", e.target.value)
                      }
                    ></Form.Control> */}
                    <TextEditor
                      value={editIlma && selectedIlma.description}
                      placeholder="Ilma Blog"
                      onChange={(value) => onChangeInput('description', value)}
                      ref={editorRef}
                    />
                  </Form.Group>
                </Col>
              </Row>
            )
          )}
        </Modal.Body>
        <Modal.Footer>
          {editIlma && formData?.schedule_date.split('T')[0] <
          moment().format('YYYY-MM-DD') ? (
            <div style={{ display: 'flex', justifyContent: 'end' , width:"100%"}}>

              <Button
                type="button"
                variant="default"
                onClick={onPressCancelBtn}>
                Cancel
              </Button>
            </div>
          ) :editIlma && formData?.schedule_date.split('T')[0] <=
              moment().format('YYYY-MM-DD') &&
            moment(formData?.schedule_time, ['hh:mm']).format('hh:mm A') <=
              moment().format('hh:mm A') ? (
                <div style={{ display: 'flex', justifyContent: 'end' , width:"100%"}}>
                <Button
                  type="button"
                  variant="default"
                  onClick={onPressCancelBtn}>
                  Cancel
                </Button>
              </div>
          ) : (
            <>
              <Button
                type="button"
                variant="default"
                onClick={onPressCancelBtn}>
                Cancel
              </Button>
              <Button
                className="btn-fill pull-right"
                onClick={onSubmitForm}
                type="button"
                variant={editIlma ? 'info' : 'primary'}>
                {editIlma ? 'Update' : 'Create'}
              </Button>
            </>
          )}
        </Modal.Footer>
      </Fragment>
    </Modal>
  )
}
