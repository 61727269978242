import React, { useRef } from 'react'

import classes from './quiz.module.css'
import { ButtonLink } from 'components/ButtonLink/ButtonLink'
import { AddQuestionForm } from 'components/AddQuestionForm/AddQuestionForm'
import { Button } from 'react-bootstrap'

export const TableRow = ({
  question = {},
  index,
  togglePages,
  onDelete = () => {},
  onRestore,
  onEdit = () => {},
}) => {
  const questionRef = useRef(null)

  const {
    title = '',
    description = '',
    type = '',
    quiz_id,
    course_id,
    lesson_id,
  } = question

  const onEditClick = () => {
    if (!questionRef.current) return

    questionRef.current.showModal()
    onEdit()
  }

  return (
    <>
      <tr>
        <td>{index + 1}</td>
        <td>{title}</td>
        <td>{description}</td>
        <td>{type}</td>
        <td>
          {!togglePages ? (
            <>
              <ButtonLink
                iconClasses="fas fa-edit"
                tooltip="Edit"
                onClick={onEditClick}
              />
              <ButtonLink
                iconClasses="fas fa-trash ml-1"
                tooltip="Delete"
                iconStyle={classes.icon_style_delete}
                onClick={onDelete}
              />
            </>
          ) : (
            <>
              <Button
                style={{ whiteSpace: 'nowrap' }}
                onClick={onRestore}
                size="sm"
                className="btn-fill ml-1"
                variant={'primary'}>
                Restore
              </Button>
              <Button
                style={{ whiteSpace: 'nowrap' }}
                onClick={onDelete}
                size="sm"
                className="btn-fill ml-1"
                variant={'danger'}>
                Delete Permanently
              </Button>
            </>
          )}
        </td>
      </tr>
      <AddQuestionForm
        ref={questionRef}
        quiz_id={quiz_id}
        course_id={course_id}
        lesson_id={lesson_id}
        questionData={question}
        isUpdating
      />
    </>
  )
}
