import axios from 'axios'
import { Redirect } from 'react-router'
import { AUTH_ACTION_TYPES } from 'reducers/auth/auth.actions'
import { TOKEN_KEY } from 'shared/CONSTANTS'
import { TOAST_TYPE } from 'shared/functions'
import { showToast } from 'shared/functions'
import { removeLocalStorage } from 'shared/functions'

import { getLocalStorage } from 'shared/functions'
import store from '../store'
import history from './history'
import { setAppLoadingState } from '../reducers/appLoading/appLoading.action'

export const BASE_URL = 'https://nk.digitalmotion.us/api/v1'
// export const BASE_URL = 'http://127.0.0.1:4000/api/v1'
// const BASE_URL = "http://192.168.18.5:4000/api/v1";
// const BASE_URL = "http://192.168.138.64:4000/api/v1";

const http = axios.create({
  baseURL: BASE_URL,
  maxRedirects: 4,
})

const responseInterceptor = (response) => {
  store.dispatch(setAppLoadingState(false))
  if (!response.data) {
    showToast('No data', TOAST_TYPE.error)

    removeLocalStorage(TOKEN_KEY)
    history.replace({
      pathname: '/admin/login',
    })
    return history.push({ pathname: '/admin/login' })
  } else if (response.data.loggedOut) {
    removeLocalStorage(TOKEN_KEY)
    store.dispatch({
      type: AUTH_ACTION_TYPES.LOGOUT,
    })

    history.replace({
      pathname: '/admin/login',
    })
  }

  return response
}

http.interceptors.request.use(
  (config) => {
    const token =
      store.getState()?.auth?.user?.token || getLocalStorage('token')

    config.headers.authorization = `Bearer ${token}`
    config.headers['Access-Control-Allow-Origin'] = '*'
    // store.dispatch(setAppLoadingState(true));
    return config
  },
  (err) => Promise.reject(err)
)

http.interceptors.response.use(responseInterceptor, (err) => {
  store.dispatch(setAppLoadingState(false))
  const errors =
    err.response && err.response.status >= 400 && err.response.status < 500
  if (!errors) {
    if (err?.response) {
      const { data } = err?.response
      return showToast(data?.message, TOAST_TYPE.error)
    } else {
      return showToast('Something went wrong', TOAST_TYPE.error)
    }
  } else if (err.response.status === 401) {
    //unauthorized user
    removeLocalStorage(TOKEN_KEY)
    store.dispatch({
      type: AUTH_ACTION_TYPES.LOGOUT,
    })

    history.replace({
      pathname: '/admin/login',
    })
  }

  return Promise.reject(err)
})

export default http
