import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { AUTH_STATE } from 'reducers/auth/getState'
import { deleteCategory } from 'reducers/categories/categories.action'
import { SUPER_ADMIN } from 'shared/CONSTANTS'

import { OnDeleteItem } from 'components/DeleteConfirmation'
import { DUMMY_BOOK } from 'shared/CONSTANTS'

export const TableRow = ({
  category,
  index,
  onEdit = (category) => {},
  restore,
  defaultImage,
}) => {
  const user = useSelector(AUTH_STATE.user)
  const dispatch = useDispatch()

  const [icon, seticon] = useState()

  useEffect(() => {
    try {
      seticon(JSON.parse(category?.icon))
    } catch (error) {}
  }, [category])

  const deleteCat = async (category_id) => {
    OnDeleteItem({
      onApplySuccessFunction: () =>
        dispatch(
          deleteCategory(
            {
              category_id: category_id,
              deletePermanently: restore ? 1 : 0,
            },
            restore
          )
        ),
      title: 'Category',
      restore: false,
    })
  }

  const onRestoreCategory = async (category_id) => {
    OnDeleteItem({
      onApplySuccessFunction: () =>
        dispatch(
          deleteCategory(
            { category_id: category_id, deletePermanently: 0 },
            restore
          )
        ),
      title: 'Category',
      restore: restore,
    })
  }

  function removeTags(str) {
    if (str === null || str === '') return false
    else str = str.toString()

    return str.replace(/(<([^>]+)>)/gi, '')
  }
  console.log(defaultImage)
  return (
    <tr>
      <td>{index + 1}</td>
      <td>{category?.title}</td>
      <td>{!category.desc ? '-' : removeTags(category.desc)}</td>
      <td>
        {!category.icon ? (
          <img
            src={defaultImage !== '' ? defaultImage : DUMMY_BOOK}
            alt=""
            style={{ width: '35px', height: '35px' }}
          />
        ) : (
          <img
            src={icon?.url}
            alt=""
            style={{ width: '50px', height: '50px' }}
          />
        )}
      </td>
      <td style={{ whiteSpace: 'nowrap' }}>
        {!restore && (
          <Button
            size="sm"
            className="btn-fill"
            disabled={user && user?.role === SUPER_ADMIN.role ? false : true}
            variant={
              user && user?.role === SUPER_ADMIN.role ? 'primary' : 'secondary'
            }
            onClick={() => onEdit(category)}>
            Edit
          </Button>
        )}
        {restore && (
          <Button
            size="sm"
            className="btn-fill ml-1"
            disabled={user && user?.role === SUPER_ADMIN.role ? false : true}
            variant={'secondary'}
            onClick={() => onRestoreCategory(category.category_id)}>
            Restore
          </Button>
        )}

        <Button
          size="sm"
          className="btn-fill ml-1"
          disabled={user && user?.role === SUPER_ADMIN.role ? false : true}
          variant={'danger'}
          onClick={() => deleteCat(category.category_id)}>
          {restore ? 'Delete Permanently' : 'Delete'}
        </Button>
      </td>
    </tr>
  )
}
