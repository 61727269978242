import { USER } from 'config/apis'
import { USER_ENDPOINTS, AUTH_ENDPOINTS } from 'config/apis'
import http from 'config/http'
import { setAppCloseableLoadingState } from 'reducers/appLoading/appLoading.action'
import { setAppLoadingState } from 'reducers/appLoading/appLoading.action'
import { AUTH_ACTION_TYPES } from 'reducers/auth/auth.actions'
import { uploadFileToCloudinaryViaAPI } from 'shared/functions'
import { TOAST_TYPE } from 'shared/functions'
import { showToast } from 'shared/functions'
import { ADD_USER, DELETE_USER, EDIT_USER, FETCH_ALL_USERS, FETCH_ALL_USERS_WITH_PAGINATION } from './actionType'

export const USERS_ACTION_TYPES = {
  UPDATE_PROFILE: '[user] UPDATE_PROFILE',
  UPDATE_PASSWORD: '[user] UPDATE_PASSWORD',
}

export const onUpdateProfile = (payload) => {
  return async (dispatch) => {
    try {
      if (
        typeof payload.image == 'string' ||
        payload.image === '' ||
        payload.image === null
      ) {
        var reqBody = {
          ...payload,
        }
      } else {
        const data = {
          file: payload?.image,
          path: 'userprofile',
        }
        const _file = await uploadFileToCloudinaryViaAPI(data,dispatch)
        //send request
        var reqBody = {
          ...payload,
          image: _file.url,
        }
      }

      dispatch(
        setAppCloseableLoadingState({
          status: true,
          closeable: false,
          text: 'Please wait uploading image',
        })
      )
      const updatedProfile = await http.put(
        USER_ENDPOINTS.UPDATE_PROFILE,
        reqBody
      )

      if (updatedProfile.status !== 200 || !updatedProfile.data) {
        showToast('Unable process your request', TOAST_TYPE.error)
        return dispatch(
          setAppCloseableLoadingState({
            status: false,
            closeable: false,
            text: '',
          })
        )
      }
 
      dispatch(
        setAppCloseableLoadingState({
          status: false,
          closeable: false,
          text: '',
        })
      )
      showToast('Profile Update!', TOAST_TYPE.success)
      return dispatch({
        type: AUTH_ACTION_TYPES.LOGIN,
        payload: updatedProfile.data,
      })
    } catch (err) {
      if (err?.response) {
        const { data } = err?.response
        showToast(data?.message, TOAST_TYPE.error)
      } else {
        showToast('Something went wrong!', TOAST_TYPE.error)
      }
      return dispatch(
        setAppCloseableLoadingState({
          status: false,
          closeable: false,
          text: '',
        })
      )
    }
  }
}

export const getProfile = () => {
  return async (dispatch) => {
    try {
      dispatch(setAppLoadingState(true))
      const profile = await http.get(USER_ENDPOINTS.USER.PROFILE)
      dispatch(setAppLoadingState(false))
      return dispatch({
        type: AUTH_ACTION_TYPES.LOGIN,
        payload: profile.data,
      })
    } catch (err) {
      return dispatch(setAppLoadingState(false))
    }
  }
}

export const changePassword = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(setAppLoadingState(true))
      const updatePassoword = await http.put(
        USER_ENDPOINTS.UPDATE_PASSWORD,
        payload
      )
      showToast('Password updated', TOAST_TYPE.success)
    } catch (err) {}
  }
}

export const getAllUsers = () => {
  return async (dispatch) => {
    try {
      dispatch(setAppLoadingState(true))
      const users = await http.get(USER.FETCH_USERS)
      dispatch(setAppLoadingState(false))


      dispatch({
        type: FETCH_ALL_USERS,
        payload: users.data.allUsers,
      })
    } catch (err) {
      return dispatch(setAppLoadingState(false))
    }
  }
}

export const getAllUsersWidthPagination = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(setAppLoadingState(true))
      const users = await http.post(USER.FETCH_USER_WITH_PAGINATION ,payload)
      dispatch(setAppLoadingState(false))

      dispatch({
        type: FETCH_ALL_USERS_WITH_PAGINATION,
        payload: users.data,
      })
    } catch (err) {
      return dispatch(setAppLoadingState(false))
    }
  }
}

//create new account!
export const onAddUser = (payload) => {
  return async (dispatch) => {
    try {
      //checking for admin email
      if (payload?.email.toLocaleLowerCase().match(/admin/g)) {
        return showToast('You cannot use this email', TOAST_TYPE.error)
      }

      dispatch(setAppLoadingState(true))

      const registerUser = await http.post(AUTH_ENDPOINTS.SIGNUP, payload)

      //check for response
      if (registerUser.status !== 200 || !registerUser?.data) {
        showToast('Unable to create account!', TOAST_TYPE.error)
        dispatch(setAppLoadingState(false))
        return dispatch({
          type: AUTH_ACTION_TYPES.AUTH_FAILED,
        })
      }

      //set local storage
      // setLocalStorage(TOKEN_KEY, registerUser.data?.token);

      showToast('Register success', TOAST_TYPE.success)
      dispatch(setAppLoadingState(false))
      return dispatch({
        type: ADD_USER,
        payload: registerUser.data,
      })
    } catch (err) {
      dispatch(setAppLoadingState(false))
      return dispatch({
        type: AUTH_ACTION_TYPES.AUTH_FAILED,
      })
    }
  }
}

export const onEditUser = (payload) => {
  return async (dispatch) => {
    try {

      dispatch(setAppLoadingState(true))
      const updatedProfile = await http.put(USER.EDIT_USER, payload)

      if (updatedProfile.status !== 200 || !updatedProfile.data) {
        showToast('Unable process your request', TOAST_TYPE.error)
        return dispatch(setAppLoadingState(false))
      }

      dispatch(setAppLoadingState(false))
      showToast('Profile Update!', TOAST_TYPE.success)

      return dispatch({
        type: EDIT_USER,
        payload: updatedProfile.data,
      })
    } catch (err) {
      console.log('ERROR ', err)
      if (err?.response) {
        const { data } = err?.response
        showToast(data?.message, TOAST_TYPE.error)
      } else {
        showToast('Something went wrong!', TOAST_TYPE.error)
      }
      return dispatch(setAppLoadingState(false))
    }
  }
}

export const onDeleteUser = (payload) => {
  return async (dispatch) => {
    try {

      dispatch(setAppLoadingState(true))
      const updatedProfile = await http.delete(`${USER.DELETE_USER}/${payload}`)

      if (updatedProfile.status !== 200 || !updatedProfile.data) {
        showToast('Unable process your request', TOAST_TYPE.error)
        return dispatch(setAppLoadingState(false))
      }

      dispatch(setAppLoadingState(false))
      showToast('Profile Deleted!', TOAST_TYPE.success)

      return dispatch({
        type: DELETE_USER,
        payload: payload,
      })
    } catch (err) {
      console.log('ERROR ', err)
      if (err?.response) {
        const { data } = err?.response
        showToast(data?.message, TOAST_TYPE.error)
      } else {
        showToast('Something went wrong!', TOAST_TYPE.error)
      }
      return dispatch(setAppLoadingState(false))
    }
  }
}
