import axios from 'axios'
import http from 'config/http'
import { BASE_URL } from 'config/http'
import { isEmpty } from 'lodash'
import { toast } from 'react-toastify'
import { CLOUDINARY_API } from './CONSTANTS'
import { useDispatch } from 'react-redux'
import { setAppCloseableLoadingState } from 'reducers/appLoading/appLoading.action'

export const TOAST_TYPE = {
  error: 'error',
  success: 'success',
  warning: 'warning',
  info: 'info',
}

const config = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
}

export function redirectTo(props, { path = '/', state = {} }) {
  const { history: { push = () => {} } = {} } = props || {}
  if (!isEmpty(state)) return push(path, state)
  return push(path)
}

export const getLocalStorage = (key) => {
  return localStorage.getItem(key)
}

export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, value)
}

export const removeLocalStorage = (key) => {
  return localStorage.removeItem(key)
}

export const customDebounce = (func, delay = 300) => {
  return function () {
    setTimeout(() => {
      func()
    }, delay)
  }
}

export const showToast = (message, type = TOAST_TYPE.info) => {
  switch (type) {
    case TOAST_TYPE.info:
      return toast.info(message, config)

    case TOAST_TYPE.success:
      return toast.success(message, config)

    case TOAST_TYPE.warning:
      return toast.warning(message, config)

    case TOAST_TYPE.error:
      return toast.error(message, config)

    default:
      return toast.info(message, config)
  }
}

export const isEmailValid = (email) => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  return regex.test(String(email).toLocaleLowerCase())
}

export const errorMessage = (message) => {
  return {
    error: true,
    message: message,
  }
}

export const getFile = (file) => {
  const fileReg = /(?:\.([^.]+))?$/
  const res = {
    file,
    ext: fileReg.exec(`${file}`)[1],
  }
  return res
}

export const debounce = (func, delay = 300) => {
  let timer
  return function () {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func()
    }, delay)
  }
}


let cancelTokenSource;

export const uploadFileToCloudinaryViaAPI = async (payload, dispatch) => {
  const formData = new FormData()
  const token = getLocalStorage('token')
  formData.append('file', payload.file)
  formData.append('path', payload.path)
  try {
    const response = await axios({
      method: 'POST',
      url: BASE_URL + '/uploader/upload',
      data: formData,
      headers: {
        authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
      },
      onUploadProgress: (progress) => {
        const { total, loaded } = progress
        const totalSizeInMB = total / 1000000
        const loadedSizeInMB = loaded / 1000000
        const uploadPercentage = (loadedSizeInMB / totalSizeInMB) * 100
        dispatch(
          setAppCloseableLoadingState({
            status: true,
            closeable: true,
            url: BASE_URL + '/uploader/upload',
            text: `Please wait your file is ${
              uploadPercentage === 100
                ? 'processing'
                : `uploading ${uploadPercentage.toFixed(1)}%`
            }`,
          })
        )
      },
      encType: 'multipart/form-data',
      cancelToken: new axios.CancelToken(function executor(c) {
        cancelTokenSource = c;
      }),
    })
    return response?.data?.file
  } catch (error) {
    console.log(error)
    showToast(error.response.data.message, TOAST_TYPE.error)
  }
}

export const cancelFileUpload = () => {
  if (cancelTokenSource) {
    showToast('Video upload is successfully canceled', TOAST_TYPE.success)
    cancelTokenSource('Request canceled by user.');
  }
};
export const onReOrderList = (objArray) => {
  const array = objArray.sort(function (a, b) {
    var textA = a.title.toUpperCase()
    var textB = b.title.toUpperCase()
    return textA < textB ? -1 : textA > textB ? 1 : 0
  })
  return array
}
