import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import DraftToHtml from "draftjs-to-html";
import HtmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import style from "./textEditor.module.css";

const HASH_CONFIG = {
  trigger: "#",
  separator: " ",
};

export const TextEditor = forwardRef(
  ({ placeholder = "", value = "", onChange = (value) => {} }, ref) => {
    const [editorState, setEditorState] = useState(() =>
      EditorState.createEmpty()
    );

    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const markUp = DraftToHtml(rawContentState);

    const onEditorStateChange = (e) => {
      setEditorState(e);
      onChange(markUp);
    };

    useImperativeHandle(ref, () => ({
      getValue: () => {
        return markUp;
      },
    }));

    useEffect(() => {
      if (!value) return;
      const contentBlock = HtmlToDraft(value);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const _editorState = EditorState.createWithContent(contentState);
      setEditorState(_editorState);
    }, [value]);

    return (
      <div className={style.wrapper}>
        <Editor
          placeholder={placeholder || "Editor"}
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={onEditorStateChange}
          editorStyle={{
            backgroundColor: "#fff",
            padding: "0 10px",
            minHeight: "200px",
          }}
          value={"<p>Hello World</p>"}
          toolbar={{
            options: [
              "inline",
              "blockType",
              "fontSize",
              "fontFamily",
              "list",
              "textAlign",
              "colorPicker",
              "link",
              "embedded",
              // "emoji",
              // "image",
              "remove",
              // "history",
            ],
          }}
        />
      </div>
    );
  }
);
