const { COURSES_ACTIONS_TYPES } = require('./courses.action')

const INITIAL_STATE = {
  courses: [],
  course: {},
}

const CoursesReducer = (state = INITIAL_STATE, actions) => {

  switch (actions.type) {
    case COURSES_ACTIONS_TYPES.FETCH_COURSES: {
      return {
        ...state,
        courses: [...actions.payload],
      }
    }
    case COURSES_ACTIONS_TYPES.SET_SELECTED_COURSE: {
      return {
        ...state,
        course: { ...actions.payload },
      }
    }

    case COURSES_ACTIONS_TYPES.UPDATE_SELECTED_COURSE: {
      const _course = { ...state.course }
      state.course.lessons.filter((item,index) => {
        if(item.lesson_id == actions.payload.lesson_id)
        {
          _course.lessons[index].topic = actions.payload.topic
          _course.lessons[index].description = actions.payload.description
          _course.lessons[index].file = actions.payload.file          
        }
      })
      return {
        ...state,
        course: { ..._course },
      }
    }
    case COURSES_ACTIONS_TYPES.COURSES_CREATE: {
      return {
        ...state,
        courses: [...actions.payload],
      }
    }

    case COURSES_ACTIONS_TYPES.SET_COURSES: {
      return {
        ...state,
        courses: [...actions.payload],
      }
    }

    default:
      return state
  }
}

export default CoursesReducer
