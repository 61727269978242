import React, { useState } from 'react'
import { Badge, Button } from 'react-bootstrap'
import { ButtonLink } from 'components/ButtonLink/ButtonLink'
import { useDispatch, useSelector } from 'react-redux'

import classes from './CourseDetailTable.module.css'
import { AUTH_STATE } from 'reducers/auth/getState'
import { WatchLessonModal } from '../WatchLessonModal/WatchLessonModal'
import { removeLesson } from 'reducers/videos/videos.action'
import { COURSES_STATE } from 'reducers/course/getState'
import { OnDeleteItem } from 'components/DeleteConfirmation'

export const TableRow = ({
  lesson,
  user,
  index,
  totalLessons,
  onQuizClick = () => {},
  onEdit = (lesson) => {},
  onSwapUp = (lesson) => {},
  onSwapDown = (lesson) => {},
  onQuestionsClick = (lesson) => {},
}) => {
  const [showVideoModal, setVideoModalState] = useState(false)
  const currentUser = useSelector(AUTH_STATE.user)
  const selectedCourse = useSelector(COURSES_STATE.course)
  const dispatch = useDispatch()

  const onDelete = async (lesson_id) => {
    await dispatch(
      removeLesson({
        lesson_id: lesson?.lesson_id,
        course_id: selectedCourse?.course_id,
      })
    );
  };

  const showHideVideoModal = (visible) => {
    setVideoModalState(visible);
  };

  function stripHtml(html)
{
   let tmp = document.createElement("DIV");
   tmp.innerHTML = html;
   return tmp.textContent || tmp.innerText || "";
  }

  return (
    <tr>
      <td>{index + 1}</td>
      <td>
        <ButtonLink
          iconClasses="nc-icon nc-button-play"
          tooltip="View Lesson"
          iconStyle={classes.icon_style_arrow}
          onClick={() => showHideVideoModal(true)}
        />
      </td>
      <td>{lesson.topic}</td>
      <td>{!lesson.description ? '-' : stripHtml(lesson.description)}</td>
      {/* <td>
        <Button
          onClick={() => onQuizClick(lesson.lesson_id)}
          className="btn-fill"
          size="sm"
        >
          View Quiz
        </Button>
      </td> */}
      {/* <td>
        <Button
          onClick={() => onQuestionsClick(lesson.lesson_id)}
          className="btn-fill"
          style={{ whiteSpace: 'nowrap' }}
          size="sm"
        >
          View Questions
        </Button>
      </td> */}
      <td>
        <ButtonLink
          iconClasses="fas fa-edit"
          tooltip="Edit"
          // disabled={currentUser && currentUser?.user_id === user?.user_id ? false : true}
          // variant={currentUser?.user_id === course?.user?.user_id ? "primary" : 'secondary'}
          onClick={() => onEdit(lesson)}
        />
        &nbsp;
        <ButtonLink
          iconClasses="fas fa-trash"
          tooltip="Delete"
          // disabled={currentUser && currentUser?.user_id === user?.user_id ? false : true}
          // variant={currentUser?.user_id === course?.user?.user_id ? "primary" : 'secondary'}
          iconStyle={
            currentUser && currentUser?.user_id !== user?.user_id
              ? `${classes.icon_style_delete} color:gray`
              : classes.icon_style_delete
          }
          onClick={() =>
            OnDeleteItem({ onApplySuccessFunction: onDelete, title: 'Lesson' })
          }
          // onClick={() => onDelete(lesson.lesson_id)}
        />
      </td>
      <td>
        <>
          <ButtonLink
            iconClasses={`${index !== 0 ? 'nc-icon nc-stre-up' : 'nc-icon nc-stre-up'} `}
            tooltip="Move Up"
            iconStyle={classes.icon_style_arrow}
            disabled={index === 0 ? true : false}
            onClick={() => onSwapUp(lesson)}
          />

          <ButtonLink
            iconClasses={`${
              index+1 !== totalLessons ? 'nc-icon nc-stre-down' : 'nc-icon nc-stre-down'
            }`}
            tooltip="Move Down"
            disabled={index+1 === totalLessons ? true : false}
            iconStyle={classes.icon_style_arrow}
            onClick={() => onSwapDown(lesson)}
          />
        </>
      </td>
      <WatchLessonModal
        visible={showVideoModal}
        file={lesson.file}
        onClose={() => showHideVideoModal(false)}
      />
    </tr>
  )
}
