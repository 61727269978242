import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import RootReducer from "./rootReducer";

const middlewares = applyMiddleware(thunk);

const enhancers =
  (typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const store = createStore(RootReducer, enhancers(middlewares));

export default store;
