import React from "react";
import PropTypes from "prop-types";

import classes from "./style.module.css";

export const ConversationCard = (props) => {
  function stripHTML(myString) {
    let el = document.createElement("p");
    el.innerHTML = myString;
    return el.textContent || el.innerText || "";
  }

  return (
    <div
      className={`${classes.conersation_card} ${
        props?.highlight && classes.card_highlighted
      }`}
    >
      <div className={classes.card_avatar}>
        <img
          alt="..."
          className="avatar border-gray"
          src={
            props?.user?.thumbnail
              ? { uri: props?.user?.thumbnail }
              : require("assets/img/default-avatar.png").default
          }
        />
        <b className="title">{props?.user?.fname + " " + props?.user?.lname}</b>
      </div>

      <p className={classes.description}>{stripHTML(props?.item?.message)}</p>
    </div>
  );
};

ConversationCard.propTypes = {
  highlight: PropTypes.bool,
  user: PropTypes.object,
  item: PropTypes.object
};
