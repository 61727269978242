import { QUESTIONS_ENDPOINTS } from 'config/apis'
import http from 'config/http'
import { setAppLoadingState } from 'reducers/appLoading/appLoading.action'
import { setQuiz } from 'reducers/quiz/quiz.actions'

export const QUESTION_ACTION_TYPES = {
  ADD_QUESTION: '[QUESTIONS] ADD_QUESTION',
}

export const addQuestion = (payload) => {
  return async (dispatch, getState) => {
    try {
      const quiz = getState().quizes.quiz
      dispatch(setAppLoadingState(true))
      const newQuestion = await http.post(
        QUESTIONS_ENDPOINTS.ADD_QUESTION,
        payload
      )
      dispatch(setAppLoadingState(false))

      const quizQuestions = quiz?.questions?.length ? [...quiz?.questions] : []
      quizQuestions.push(newQuestion.data)

      quiz.questions = quizQuestions

      return dispatch(setQuiz(quiz))
    } catch (err) {
      console.log(err)
    }
  }
}

export const updateQuestion = (question) => {
  return async (dispatch, getState) => {
    try {
      const quiz = getState().quizes.quiz //get the current quiz

      dispatch(setAppLoadingState(true))
      const updatedQuestion = await http.put(
        QUESTIONS_ENDPOINTS.UPDATE_QUESTION,
        {
          ...question,
        }
      )
      dispatch(setAppLoadingState(false))
      const questions = [...quiz?.questions]
      const index = questions.findIndex(
        (q) => q?.question_id === question?.question_id
      )

      if (index === -1) return

      questions[index] = updatedQuestion.data.data
      quiz.questions = [...questions]
      return dispatch(setQuiz(quiz))
    } catch (err) {
      console.log(err)
    }
  }
}

export const deleteQuestion = (question_id, deletePermanently) => {
  return async (dispatch, getState) => {
    try {
      const quiz = getState().quizes.quiz //get the current quiz

      dispatch(setAppLoadingState(true))
      const deletedQuestion = await http.delete(
        QUESTIONS_ENDPOINTS.DELETE_QUESTION,
        {
          data: {
            quiz_id: quiz.quiz_id,
            question_id,
            deletePermanently: deletePermanently,
          },
        }
      )
      dispatch(setAppLoadingState(false))

      let questions = [...quiz?.questions]
        console.log(deletePermanently)
      if (deletePermanently === 0) {
        for (let index = 0; index < questions.length; index++) {
          if (questions[index]?.question_id === question_id) {
            questions[index].deleted = deletedQuestion?.data?.deleted
          }
        }
        quiz.questions = questions
        return dispatch(setQuiz(quiz))
      } else {
        questions = await quiz.questions.filter(
          (item) => item.question_id !== question_id
        )

        quiz.questions = questions
        return dispatch(setQuiz(quiz))
      }
    } catch (err) {
      console.log(err)
    }
  }
}
