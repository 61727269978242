import { FileSelectionInput } from 'components/AddLessonsForm/FileSelectionInput.js'
import { FormFileViewer } from 'components/AddLessonsForm/FormFileViewer'
import { TextEditor } from 'components/TextEditor/TextEditor'
import React, {
  Fragment,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { Button, Col, Form, Row, Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { updateCategory } from 'reducers/categories/categories.action'
import { createCategory } from 'reducers/categories/categories.action'

const MAX_DESC_LENGTH = 200

export const AddCategoryForm = forwardRef((props, ref) => {
  const [show, setModal] = useState(false)
  const [descCount, setDescCount] = useState(MAX_DESC_LENGTH)
  const [formData, setFormData] = useState({
    title: '',
    desc: '',
    icon: '',
  })

  const { isEdit = false, editFormData = {} } = props || {}

  const dispatch = useDispatch()
  const editorRef = useRef(null)

  useImperativeHandle(ref, () => ({
    showCatModal: () => {
      setModal(true)
    },
  }))

  useEffect(() => {
    if (!isEdit) return

    setFormData(editFormData)
  }, [isEdit, editFormData])

  const onClose = () => {
    ref.current.showCatModal()
    setModal(false)
  }

  const onChangeText = (key, value) => {
    const _formData = { ...formData }
    _formData[key] = value
    setFormData(_formData)
  }

  const onSubmitForm = async (e) => {
    e.preventDefault()

    if (!isEdit) {
      await dispatch(createCategory(formData))
      setModal(false)
    }
    else if (isEdit) await dispatch(updateCategory(formData))

    if (show) {
      setModal(false)
      setFormData({
        title: '',
        desc: '',
        icon: '',
      })
    }
  }

  return (
    <Modal
      show={show}
      onHide={onClose}
      size="lg"
      animation={false}
      backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{isEdit ? 'Edit Category' : 'Add new category'}</Modal.Title>
      </Modal.Header>

      <Fragment>
        <Modal.Body>
          <Row>
            <Col sm="12" lg="12" md="12">
              <Form.Group>
                <Form.Label>Title</Form.Label>
                <Form.Control
                  placeholder="Title"
                  type="text"
                  name="title"
                  value={formData.title}
                  onChange={(e) => onChangeText('title', e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col sm="12" lg="12" md="12">
              <Form.Group>
                <Form.Label>Description</Form.Label>
                {/* <Form.Control
                  placeholder="Category Description"
                  type="text"
                  as="textarea"
                  rows="4"
                  name="desc"
                  value={formData.desc}
                  onChange={(e) => onChangeText("desc", e.target.value)}
                /> */}
                <TextEditor
                  value={isEdit && editFormData.desc}
                  placeholder="Category Description"
                  ref={editorRef}
                  onChange={(value) => onChangeText('desc', value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col sm="12" lg="3" md="4">
              <Form.Group>
                <Form.Label>Icon</Form.Label>
                {!formData.icon ? (
                  <FileSelectionInput
                    onFilesSelected={(file) => {
                      const _formData = { ...formData }
                      _formData['icon'] = file[0]
                      setFormData(_formData)
                    }}
                    title="Click to upload Icon"
                    accept="image/png, image/gif, image/jpeg , image/webp"
                    multiple={false}
                  />
                ) : (
                  <FormFileViewer
                    file={formData.icon}
                    isVideo={false}
                    isPDF={false}
                    isImage={true}
                    onRemove={(file) => {
                      const _formData = { ...formData }
                      _formData['icon'] = ''
                      setFormData(_formData)
                    }}
                  />
                )}
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button type="button" variant="default" onClick={onClose}>
            Cancel
          </Button>
          <Button
            className="btn-fill pull-right"
            onClick={onSubmitForm}
            // type="submit"
            variant={isEdit ? 'info' : 'primary'}
            disabled={!formData?.title}>
            {isEdit ? 'Update' : 'Add'}
          </Button>
        </Modal.Footer>
      </Fragment>
    </Modal>
  )
})
