import * as cloudinary from 'cloudinary'

cloudinary.v2.config({
  cloud_name: 'digital-motion',
  api_key: '331813958894194',
  api_secret: '1NJN4L_AFey6B4cFWJOQtjsjAPs',
})

export const uploadFileToCloudinary = async (path, file) => {
  const base64File = await getBase64(file)

  try {
    return await cloudinary.v2.uploader.upload(base64File, {
      overwrite: true,
      resource_type: 'auto',
      public_id: `${path}`,
      'Access-Control-Allow-Headers': '*',
    })
  } catch (err) {
    return err
    console.log(`Video upload error: `, err)
  }
}

export const deleteFileFromCloudinary = async (files = []) => {
  try {
    const deletedResouces = files?.map(async (file) => {
      return await cloudinary.v2.uploader.destroy(file.public_id, {
        resource_type: 'auto',
        invalidate: true,
      })
    })
  } catch (err) {
    console.log(`Unable to delete file: `, err)
  }
}

export const deleteCompleteDirectoryFromCloudinary = async (directoryPath) => {
  try {
    await cloudinary.v2.api.delete_folder(`${directoryPath}`)
  } catch (err) {}
}

const getBase64 = (file) => {
  return new Promise((res, rej) => {
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      res(reader.result)
    }
    reader.onerror = function (error) {
      console.log('Error: ', error)
      rej(`Error: ${err}`)
    }
  })
}
