import { TAGS_ENDPOINTS } from "config/apis";
import http from "config/http";

export const TAGS_ACTION_TYPES = {
  TAG_LIST: "[TAGS] TAG_LIST",
};

const setTagsList = (payload) => {
  return async (dispatch) => {
    return dispatch({
      type: TAGS_ACTION_TYPES.TAG_LIST,
      payload,
    });
  };
};

export const searchTags = (payload) => {
  return async (dispatch) => {
    try {
      const tags = await http.get(`${TAGS_ENDPOINTS.SEARCH_TAGS}/${payload}`);

      if (!tags.data) return;

      return dispatch(setTagsList([...tags.data.tags]));
    } catch (err) {}
  };
};

export const getallTags = () => {
  return async (dispatch) => {
    try {
      const tags = await http.get(`${TAGS_ENDPOINTS.GET_TAGS}`);

      return dispatch(setTagsList([...tags.data.tags]));
    } catch (err) {}
  };
};

export const addTag = (tag) => {
  return async (dispatch, getState) => {
    try {
      const tags = [];
      tags.push(tag);
      const newTag = await http.post(TAGS_ENDPOINTS.CREATE_TAGS, {
        tags: [...tags],
      });

      const tagsList = [...getState().tags.tags];
      const newTagList = [...tagsList, ...newTag.data.tags];
      dispatch(setTagsList(newTagList));
      return newTagList[0];
    } catch (err) {}
  };
};
