import React, { useEffect, useRef, useState } from 'react'
import { Button, Col } from 'react-bootstrap'
import { Trash2Fill, Eye } from 'react-bootstrap-icons'
import { getFile } from 'shared/functions'

import classes from './addVideoForm.module.css'

export const FormFileViewer = ({
  file,
  onSelect = (file) => {},
  onRemove = (file) => {},
  isSelected = false,
  isVideo = true,
  isPDF,
  isImage
}) => {
  const videoRef = useRef()
  const [localFile, setFile] = useState('')
  const [preUploaded, setpreUploaded] = useState('')
  // const newFile = JSON.parse(file);
  const PDF_IMG = 'https://files.slack.com/files-pri/T02CRQKKA5Q-F043N7UTP18/pngegg.png' 
  useEffect(() => {
    if (!file) return
    // if(file?.public_id )
    // {
    //   setFile(getFile(file.url))
    // }
    if (typeof file === 'string' && isImage) {
      setpreUploaded(JSON.parse(file)?.url)
    }
    if (file?.file?.type?.toLocaleLowerCase() === 'application/pdf') {
      setFile(URL.createObjectURL(file?.file))
    }
    if (file.file && typeof file.file === 'object') {
      setFile(URL.createObjectURL(file?.file))
    } else if (typeof file === 'object' && !file?.public_id)
      setFile(URL.createObjectURL(file))
    else setFile(file)
  }, [file])

  return (
    <div
      className={`${classes.form_video} ${isSelected && classes.active}`}
      onClick={() => onSelect(file)}>
      <div style={{ display: 'flex' }}>
        <Button
          size="sm"
          className="btn-fill"
          variant="danger"
          onClick={() => onRemove(file)}>
          <Trash2Fill />
        </Button>
        &nbsp;
        {isPDF === true && (
          <Button
            style={{ marginRight: '40px' }}
            size="sm"
            className="btn-fill"
            variant="primary"
            onClick={() =>
              window.open(
                file.name
                  ? (window.URL || window.webkitURL).createObjectURL(file)
                  : file
              )
            }>
            <Eye />
          </Button>
        )}
      </div>

      { isVideo && !isPDF ? (
        <video src={file.url || localFile} ref={videoRef} 
        
        controls autoPlay={false} />
      ) : (
        <img src={isPDF === true ? 'https://play-lh.googleusercontent.com/BkRfMfIRPR9hUnmIYGDgHHKjow-g18-ouP6B2ko__VnyUHSi1spcc78UtZ4sVUtBH4g' : preUploaded || localFile} className={classes.img_viewer} />
      )}
    </div>
  )
}
