import React, { useEffect, useState } from 'react'
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  Form,
  Row,
} from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { withRouter } from 'react-router'

import { CreateIlmaForm } from 'components/CreateIlmaForm/CreateIlmaForm'
import { IlmasTable } from 'components/IlmasTable/IlmasTable'

import classes from './ilma.module.css'
import { getAllIlmas } from 'reducers/ilma/ilma.actions'
import { UpdateIlmaDefaultVideoForm } from 'components/UpdateilmaDefaultVideo/UpdateIlmaForm'
import { getAllCat } from 'reducers/categories/categories.action'

const Ilma = (props) => {
  const [showModal, setModalState] = useState(false)
  const [showUpdateModal, setshowUpdateModal] = useState(false)
  const [searchedItem, setsearchedItem] = useState('')
  const [searchedFile, setSearchedFile] = useState('All')
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAllIlmas())
    dispatch(getAllCat())
  }, [props.location])

  const handleItemClick = (item) => {
    setSearchedFile(item)
  }

  return (
    <Container fluid>
      <Row>
        <Col sm="12" lg="12" md="12">
          <Card>
            <Card.Header>
              <Row>
                <Col sm="12" lg="9" md="9">
                  <Card.Title>Ilma List</Card.Title>
                </Col>
                <Col
                  sm="12"
                  lg="3"
                  md="3"
                  style={{ display: 'flex', justifyContent: 'end' }}>
                  <Button
                    size="sm"
                    className="btn btn-fill text-nowrap"
                    onClick={() => setModalState(true)}>
                    Add Ilma
                    <i
                      className={`nc-icon nc-simple-add ${classes.create_btn_icon}`}
                    />
                  </Button>
                  &nbsp;
                  <Button
                    size="sm"
                    className="btn btn-fill text-nowrap"
                    onClick={() => setshowUpdateModal(true)}>
                    Default Video
                    <i
                      className={`nc-icon nc-simple-add ${classes.create_btn_icon}`}
                    />
                  </Button>
                </Col>
              </Row>

              <Row className="mt-2 d-flex justify-content-end">
                <Col sm="12" lg="5" md="4">
                  <div style={{ display: 'flex' }}>
                    <Dropdown className="">
                      <Dropdown.Toggle
                        style={{ height: '40px' }}
                        variant="info"
                        id="dropdown-basic">
                        Publication Type
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => handleItemClick('Video')}>
                          Video
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => handleItemClick('Pdf')}>
                          Pdf
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => handleItemClick('Text')}>
                          Text
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>{' '}
                    <Form.Control
                      name="name"
                      placeholder="Enter Title to Search Ilma"
                      type="text"
                      className="ml-2"
                      onChange={(e) => setsearchedItem(e.target.value)}
                      // value={formData.fname}
                      required></Form.Control>
                  </div>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <Alert key="info" variant="info">
                Published Ilma will be scheduled in Kuala Lumpur Timezone.
              </Alert>

              <IlmasTable
                searchedItem={searchedItem}
                searchedFile={searchedFile}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <CreateIlmaForm show={showModal} onClose={() => setModalState(false)} />
      <UpdateIlmaDefaultVideoForm
        show={showUpdateModal}
        onClose={() => setshowUpdateModal(false)}
      />
    </Container>
  )
}

export default withRouter(Ilma)
