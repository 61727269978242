import React from "react";
import { Provider as ReduxProvider } from "react-redux";
import { Worker } from "@react-pdf-viewer/core";

import store from "./store";
import { CustomToast } from "components/CustomToast/CustomToast";
import { AppLoader } from "components/AppLoader/AppLoader";
// import { HOC } from "layouts/HOC";
// import history from "config/history";
import { App } from "App";

export const Root = (props) => {
  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.10.377/build/pdf.worker.js">
      <CustomToast>
        <ReduxProvider store={store}>
          <AppLoader />
          <App />
        </ReduxProvider>
      </CustomToast>
    </Worker>
  );
};
