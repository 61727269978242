import moment from "moment";
import React from "react";
import { Button, Row } from "react-bootstrap";

import classes from "./table.module.css";

export const TableRow = ({
  item,
  index,
  onClick = (item) => {},
  onEdit = (item) => {},
  onDelete = (imla) => {},
  deleteEnable = "",
  editEnable = ""
}) => {

  return (
    <>
      <tr className={classes.row}>
        <td>{index + 1}</td>
        <td>
          {" "}
          <img
            alt="..."
            className="avatar border-gray"
            src={
              !item?.image
                ? require("assets/img/faces/face-0.jpg").default
                : item?.image
            }
          ></img>{" "}
        </td>
        <td>{item.fname}</td>
        <td>{item.lname}</td>
        <td>{item.email}</td>
        <td>{item.role}</td>
        <td>{item.country}</td>

        <td>{moment(item.createdAt).format("DD-MM-YYYY hh:mm A")}</td>
        <td>
          <div style={{ whiteSpace: "nowrap" , display:"flex"}}>
        
              <Button
                size="sm"
                disabled={editEnable? false : true}
                variant={editEnable? "primary" : 'secondary'}
                className={`btn-fill ${classes.backBtn}`}
                onClick={() => onEdit(item)}
              >
                Edit
              </Button> 

              <Button
                size="sm"
                disabled={deleteEnable? false : true}
                variant={deleteEnable? "danger" : 'secondary'}
                className={`btn-fill ${classes.backBtn}`}
                onClick={() => onDelete(item)}
              >
                Delete
                <i
                  className={`nc-icon nc-simple-remove ${classes.create_btn_icon}`}
                />
              </Button> 
          </div>
        </td>
      </tr>
    </>
  );
};
