import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const CustomToast = (props) => {
  return (
    <>
      <ToastContainer />
      {props.children}
    </>
  );
};
