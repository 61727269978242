import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { Button, Col, Form, FormGroup, Modal, Row } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import {
  deleteQuestion,
  replyToQuestion,
} from '../../../reducers/askASheikh/askASheikh.actions'
import { TextEditor } from 'components/TextEditor/TextEditor'
import { Input } from 'reactstrap'
import { SUPER_ADMIN } from 'shared/CONSTANTS'
import { AUTH_STATE } from 'reducers/auth/getState'

const QuestionItem = forwardRef((props, ref) => {
  const user = useSelector(AUTH_STATE.user)
  const [visible, setVisible] = useState(false)
  const [answer, setAnswerValue] = useState('')
  const [des, setdes] = useState('')
  const { question = {} } = props || {}
  const editorRef = useRef(null)
  const dispatch = useDispatch()

  useImperativeHandle(ref, () => ({
    toggle: () => setVisible(!visible),
  }))

  const onChange = (value) => {
    setAnswerValue(value)
  }

  const onSave = async () => {
    const payload = {
      message: answer,
      description: des,
      category_id: question?.category_id,
      question_id: question?.question_id,
      draft: 1,
    }

    await dispatch(replyToQuestion(payload))
    setAnswerValue('')
    setVisible(false)
  }

  const onDelete = async () => {
    const payload = {
      message: answer,
      description: des,
      category_id: question?.category_id,
      question_id: question?.question_id,
      draft: 1,
    }

    await dispatch(deleteQuestion(payload))
    setAnswerValue('')
    setVisible(false)
  }

  
  const onSubmit = async () => {
    // if (!answer) return alert('Please enter your message')

    const payload = {
      message: answer || question?.answer,
      description: des || question.description,
      category_id: question?.category_id,
      question_id: question?.question_id,
      draft: 0,
    }

    await dispatch(replyToQuestion(payload))
    setAnswerValue('')
    setVisible(false)
  }

  return (
    <Modal
      show={visible}
      onHide={() => setVisible(false)}
      size="lg"
      animation={false}
      backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title style={{wordBreak:"break-all"}}>{question.question}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm="12" md="12" lg="12">
            <Form.Label>Description *</Form.Label>
            <FormGroup>
              <Input
                placeholder="Description *"
                type="textarea"
                defaultValue={question.description}
                // disabled
                onChange={(e) => setdes(e.target.value)}
                rows="2"
              />
            </FormGroup>
          </Col>
        </Row>
        <TextEditor
          placeholder="Your Answer!"
          value={question?.answer}
          onChange={(value) => onChange(value)}
          ref={editorRef}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          variant="default"
          onClick={() => setVisible(false)}>
          Cancel
        </Button>
        <div className="pull-right">
          {user.role === SUPER_ADMIN.role && (
            <Button
              className="btn-fill pull-right"
              type="submit"
              variant="danger"
              onClick={() => onDelete()}>
              Delete
            </Button>
          )}
          &nbsp;
          <Button
            className="btn-fill pull-right"
            type="submit"
            variant="primary"
            onClick={() => onSave()}>
            Save
          </Button>
          &nbsp;
          <Button
            className="btn-fill pull-right"
            type="submit"
            variant="primary"
            onClick={() => onSubmit()}>
            Publish
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
})

QuestionItem.propTypes = {
  question: PropTypes.objectOf({
    question_id: PropTypes.number,
    question: PropTypes.string,
    description: PropTypes.string,
    category_id: PropTypes.number,
    sheikh_id: PropTypes.number,
    user_id: PropTypes.number,
    unAnswered: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
  }),
}

export default QuestionItem
