import { isEmpty } from "lodash";
import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";

export const CustomDropdown = ({
  items = [],
  title = "",
  onSelect = (item) => {},
}) => {
  const [selected, setSelected] = useState("");

  const onSelectItem = (item) => {
    setSelected(item);
    onSelect(item);
  };

  return (
    <Dropdown>
      <Dropdown.Toggle
        style={{ minWidth: "250px", textTransform: "capitalize" , height:"38px"}}
      >
        {!isEmpty(selected) ? selected?.title : title}
      </Dropdown.Toggle>

      <Dropdown.Menu style={{ minWidth: "250px" }}>
        {items &&
          items?.length &&
          items?.map((item, key) => (
            <Dropdown.Item
              onClick={() => onSelectItem(item)}
              // active={
              //   item?.value?.toLocaleLowerCase() ===
              //     selected?.value?.toLocaleLowerCase()
              // }
            >
              {item?.title}
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
