import moment from 'moment'
import React from 'react'
import { Button, Row } from 'react-bootstrap'

import classes from './ilma.module.css'

export const TableRow = ({
  ilma,
  index,
  onClick = (ilma) => {},
  onEdit = (ilma) => {},
  onDelete = (imla) => {},
  onPublish = (imla) => {},
}) => {
  console.log(ilma)
  return (
    <>
      <tr className={classes.row}>
        <td>{index + 1}</td>
        <td>{ilma.title}</td>
        {/* <td>
          <Button
            size="sm"
            className={`btn-fill`}
            onClick={() => onClick(ilma)}
            variant="primary"
          >
            View
          </Button>
        </td> */}
        <td>
          {ilma?.schedule_date.split('T')[0]} &nbsp;{' '}
          {ilma?.schedule_time
            ? moment(ilma?.schedule_time, ['hh:mm']).format('hh:mm A')
            : ''}
        </td>
        <td>
          <Button
            size="sm"
            variant={'secondary'}
            className={`btn-fill ${classes.backBtn}`}>
            {ilma?.schedule_date.split('T')[0] <
            moment().format('YYYY-MM-DD') ? (
              <span>
                Published{' '}
                <i
                  style={{
                    color: 'white',
                    fontWeight: 'bolder',
                    cursor: 'pointer',
                  }}
                  className={`nc-icon nc-check-2 ${classes.create_btn_icon}`}
                />
              </span>
            ) : ilma?.schedule_date.split('T')[0] <=
                moment().format('YYYY-MM-DD') &&
              moment(ilma?.schedule_time, ['hh:mm']).format('hh:mm A') <=
                moment().format('hh:mm A') ? (
              <span>
                Published{' '}
                <i
                  style={{
                    color: 'white',
                    fontWeight: 'bolder',
                    cursor: 'pointer',
                  }}
                  className={`nc-icon nc-check-2 ${classes.create_btn_icon}`}
                />
              </span>
            ) : (
              <span>
                Unpublished{' '}
                <i
                  style={{
                    color: 'white',
                    fontWeight: 'bolder',
                    cursor: 'pointer',
                  }}
                  className={`nc-icon nc-time-alarm ${classes.create_btn_icon}`}
                />
              </span>
            )}
          </Button>
        </td>
        <td>
          <Row>
            {ilma?.schedule_date.split('T')[0] <
            moment().format('YYYY-MM-DD') ? (
              <Button
                size="sm"
                variant="primary"
                disabled={false}
                className={`btn-fill ${classes.backBtn}`}
                onClick={() => onEdit(ilma)}>
                View
              </Button>
            ) : ilma?.schedule_date.split('T')[0] <=
                moment().format('YYYY-MM-DD') &&
              moment(ilma?.schedule_time, ['hh:mm']).format('hh:mm A') <=
                moment().format('hh:mm A') ? (
              <Button
                size="sm"
                variant="primary"
                disabled={false}
                className={`btn-fill ${classes.backBtn}`}
                onClick={() => onEdit(ilma)}>
                View
              </Button>
            ) : (
              <Button
                size="sm"
                variant="primary"
                disabled={false}
                className={`btn-fill ${classes.backBtn}`}
                onClick={() => onEdit(ilma)}>
                Edit
              </Button>
            )}

            <Button
              size="sm"
              variant="danger"
              className={`btn-fill ${classes.backBtn}`}
              onClick={() => onDelete(ilma)}>
              Delete
              <i
                className={`nc-icon nc-simple-remove ${classes.create_btn_icon}`}
              />
            </Button>
            {/* <Button
              size="sm"
              variant={ilma?.is_Publish ? "danger" : "primary"}
              className={`btn-fill ${classes.backBtn}`}
              onClick={() => onPublish(ilma)}
            >
              {ilma?.is_Publish ? (
                <>
                  UnPublish
                  <i
                    className={`nc-icon nc-simple-remove ${classes.create_btn_icon}`}
                  />
                </>
              ) : (
                <>
                  Publish
                  <i
                    className={`nc-icon nc-simple-remove ${classes.create_btn_icon}`}
                  />
                </>
              )}
            </Button> */}
          </Row>
        </td>
      </tr>
    </>
  )
}
