/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component, useState } from 'react'
import { useLocation, NavLink, Link } from 'react-router-dom'

import { Nav } from 'react-bootstrap'

import logo from 'assets/img/reactlogo.png'
import { onLogoutUser } from 'reducers/auth/auth.actions'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import Swal from 'sweetalert2'

function Sidebar({ color, image, routes }) {
  const location = useLocation()
  const dispatch = useDispatch()
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? 'active' : ''
  }

  const [screenSize, setscreenSize] = useState(false)

  const onLogout = async (e) => {
    e.preventDefault()
    Swal.fire({
      title: `Logout`,
      text: `Are you sure want to logout?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Logout',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(onLogoutUser())
      } else if (result.dismiss) {
        // Swal.fire('Cancelled', 'You are .', 'error')
      }
    })
  }

  useEffect(() => {
    const onChangeInScreen = () => {
      if (window.screen.width < 1024 && window.screen.height < 768) {
        setscreenSize(false)
      }
    }
    if (window.screen.width >= 1024 && window.screen.height >= 768) {
      setscreenSize(true)
    }

    window.addEventListener('resize', onChangeInScreen)
    return () => {
      document.removeEventListener('resize', onChangeInScreen)
    }
  }, [])

  return (
    <div className="sidebar" data-image={image} data-color={color}>
      <div
        className="sidebar-background"
        style={{
          backgroundImage: 'url(' + image + ')',
        }}
      />
      <div className="sidebar-wrapper">
        <div className="logo d-flex align-items-center justify-content-start">
          <NavLink to="/admin/dashboard" className="simple-text logo-mini mx-1">
            <div className="logo-img">
              {/* <img src={require("assets/img/reactlogo.png").default} /> */}
            </div>
          </NavLink>
          <NavLink to="/admin/dashboard" className="simple-text">
            <div>
              <div>Ilma Dashboard</div>{' '}
              <div style={{ fontSize: '10px' }}>Version {process.env.REACT_APP_VERSION}</div>
            </div>
          </NavLink>
        </div>
        <Nav>
          {routes.map((prop, key) => {
            if (!prop.redirect)
              return (
                <li
                  className={
                    prop.upgrade
                      ? 'active active-pro'
                      : activeRoute(prop.layout + prop.path)
                  }
                  key={key}>
                  <NavLink
                    to={prop.layout + prop.path}
                    className="nav-link"
                    activeClassName="active">
                    <i className={prop.icon} />
                    <p>
                      {prop.name.length > 18 && screenSize
                        ? prop.name.substring(0, 15).concat('...')
                        : prop.name}
                    </p>
                  </NavLink>
                </li>
              )
            return null
          })}
          <li className={'hover:active active-pro'}>
            <div
              style={{ cursor: 'pointer' }}
              // to={prop.layout + prop.path}
              className="nav-link pointer"
              // activeClassName="active"
              onClick={onLogout}>
              <i className="fas fa-door-open mr-4"></i>
              <p>Logout</p>
            </div>
          </li>
        </Nav>
      </div>
    </div>
  )
}

export default Sidebar
