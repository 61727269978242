import { isEmpty } from 'lodash'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { Col, Form, Modal, Row, Button } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { addLessonToCourse } from 'reducers/videos/videos.action'

import classes from './addVideoForm.module.css'
import { FormFileViewer } from './FormFileViewer'
import { FileSelectionInput } from './FileSelectionInput.js.js'
import { TextEditor } from 'components/TextEditor/TextEditor'
import { updateLessonToCourse } from 'reducers/videos/videos.action'

export const EditLessonsForm = ({ Lesson, onModalState, modal }) => {
  const [formData, setFormData] = useState([])
  const [selectedVideo, setSelectedVideo] = useState(null)
  const [description, setdescription] = useState('')
  const editorRef = useRef(null)

  const dispatch = useDispatch()
 
  useEffect(() => {
    let arr = []
    Lesson?.file.filter((item) => {
      arr.push({
        topic: Lesson?.topic,
        description: Lesson?.description,
        file: item || '',
      })
    })
    setdescription(Lesson?.description)
    setFormData(arr)
  }, [Lesson])

  const onClose = () => {
    setFormData([])
    setSelectedVideo(null)
    onModalState()
  }

  const isSelected = (file) => file === selectedVideo

  const onChangeInput = (key, value) => {
    const _formData = [...formData]
    // formData?.filter((item, index) => {

    _formData[0][key] = value

    setFormData(_formData)
  }

  const onSelectVideo = (file) => {
    setSelectedVideo(file)
  }

  const onRemoveVideo = (file) => {
    const _formData = [...formData]

    const newFormData = _formData.filter((item) => item.file !== file)
    if (!newFormData?.length) {
      setSelectedVideo(null)
    } else {
      setSelectedVideo(newFormData[0])
    }
    setFormData(newFormData)
  }

  const onFilesSelected = (files) => {
    const _formData = [...formData]
    const _files = []
    if (!files?.length) return

    files?.map((file) => {
      const obj = {
        topic: '',
        description: '',
        file: file,
      }

      _files.push(obj)
    })

    const updatedFiles = _formData.concat(_files)

    setSelectedVideo(updatedFiles[0])
    isSelected(updatedFiles[0])
    setFormData(updatedFiles)
  }

  const onSubmitForm = async (e) => {
    e.preventDefault()
    const clone = [...formData]
    for (var i = 0; i < formData.length; i++) {
      clone[i].description = description
    }  
    await dispatch(
      updateLessonToCourse({
        videos: clone,
        course_id: Lesson?.course_id,
        lesson_id: Lesson?.lesson_id,
      })
    )
    onClose()
  }

  return (
    <Modal
      show={modal}
      size="lg"
      animation={false}
      onHide={onClose}
      backdrop="static">
      <Modal.Header closeButton={true} closeVariant="white" closeLabel="Close">
        <Modal.Title>Edit Lesson</Modal.Title>
      </Modal.Header>
      <Form onSubmit={onSubmitForm} encType="multipart/form-data">
        <Modal.Body>
          <Row>
            <Col lg="12" md="12" sm="12">
              <Form.Group>
                <Form.Label>Course Lessons</Form.Label>
                <div className={classes.form_video_wrapper}>
                  {formData?.length > 0 ? (
                    formData?.map((item, index) => (
                      <FormFileViewer
                        key={index}
                        isPDF={false}
                        isVideo={true}
                        file={item.file}
                        onSelect={onSelectVideo}
                        onRemove={onRemoveVideo}
                        isSelected={isSelected(item.file)}
                      />
                    ))
                  ) : (
                    <></>
                  )}
                  <FileSelectionInput onFilesSelected={onFilesSelected} />
                </div>
              </Form.Group>
            </Col>
          </Row>
          <div className={classes.inputWrapper}>
            {/* {!selectedVideo ? <div className={classes.blockOverlay} /> : <></>} */}
            <Row>
              <Col sm="12">
                <Form.Group>
                  <Form.Label>Lesson / Topic</Form.Label>
                  <Form.Control
                    placeholder="Video Lessons/topic"
                    value={formData[0]?.topic}
                    onChange={(e) => onChangeInput('topic', e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm="12">
                <Form.Group>
                  <Form.Label>Description</Form.Label>

                  <TextEditor
                    value={Lesson?.description}
                    placeholder="Lesson Details"
                    ref={editorRef}
                    onChange={(value) => setdescription(value)}
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" variant="default" onClick={onClose}>
            Cancel
          </Button>
          <Button
            className="btn-fill pull-right"
            type="submit"
            variant="primary"
            disabled={!formData?.length}>
            UPDATE
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
