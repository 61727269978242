import { ASK_A_SHEIKH_ACTION_TYPES } from "./askASheikh.actions";

const INITIAL_STATE = {
  questions: [],
  unAnswered: [],
  selectedQuestion: {}
};

const AskASkheikhReducer = (state = INITIAL_STATE, actions) => {
  switch (actions.type) {
    case ASK_A_SHEIKH_ACTION_TYPES.SET_QUESTIONS:
      return {
        ...state,
        questions: [...actions.payload]
      };

    case ASK_A_SHEIKH_ACTION_TYPES.SET_UNANSWERED_QUESTIONS:
      return {
        ...state,
        unAnswered: [...actions.payload]
      };
    case ASK_A_SHEIKH_ACTION_TYPES.ADD_UNANSWERED_QUESTIONS: {
      let clone = [...state.unAnswered];
      state.unAnswered.filter((item, index) => {
        if (item.category_id === actions.payload.category_id) {
          clone[index].questions.push(actions.payload);
        }
      });
      return {
        ...state,
        unAnswered: clone
      };
    }
    default:
      return state;
  }
};

export default AskASkheikhReducer;
