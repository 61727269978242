import { ProfileUpdateForm } from 'components/ProfileUpdateForm/ProfileUpdateForm'
import { UserProfileCard } from 'components/UserProfileCard/UserProfileCard'
import React, { useEffect } from 'react'

// react-bootstrap components
import { Card, Container, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { AUTH_STATE } from 'reducers/auth/getState'
import { getProfile } from 'reducers/users/users.action'

export const UserProfilePage = (props) => {
  const user = useSelector(AUTH_STATE.user)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getProfile())
  }, [props.location])

  return (
    <>
      <Container fluid>
        <ProfileUpdateForm />
      </Container>
    </>
  )
}
