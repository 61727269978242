import moment from "moment";
import React from "react";
import { Button, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { CATEGORY_STATE } from "reducers/categories/getState";

import classes from "./table.module.css";

export const TableRow = ({
  item,
  index,
  onClick = (item) => {},
  onEdit = (item) => {},
  onDelete = (imla) => {},
  deleteEnable = "",
  editEnable = ""
}) => {
  const topics = useSelector(CATEGORY_STATE.categories);

  const onGetSelectCategoryName = () => {
    const arr = topics.filter((_item,index) => _item.category_id == item.category_id)
    return arr.length > 0 ? arr[0].title : 'N/A'
  }
  return (
    <>
      <tr className={classes.row} >
        <td>{index + 1}</td>
        <td>{item.title || item.question}</td>
        <td>{onGetSelectCategoryName()}</td>
        {item?.level && <td>{item.level}</td>}
        <td>{moment(item?.schedule_date || item?.createdAt).format("DD-MM-YYYY hh:mm A")}</td>
        <td>
          <div style={{display:"flex"}}>
            {editEnable && (
              <Button
                size="sm"
                variant="primary"
                className={`btn-fill ${classes.backBtn}`}
                onClick={() => onEdit(item)}
              >
                Edit
              </Button>
            )}
            {deleteEnable && (
              <Button
                size="sm"
                variant="danger"
                className={`btn-fill ${classes.backBtn}`}
                onClick={() => onDelete(item)}
              >
                Delete
                <i
                  className={`nc-icon nc-simple-remove ${classes.create_btn_icon}`}
                />
              </Button>
            )}
          </div>
        </td>
      </tr>
    </>
  );
};
