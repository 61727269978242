import { AddCategoryForm } from 'components/AddCategoryForm/AddCategoryForm'
import React, { useEffect, useRef, useState } from 'react'
import { Col, Row, Table, Button } from 'react-bootstrap'
import { useSelector } from 'react-redux'

import { CATEGORY_STATE } from 'reducers/categories/getState'
import { onReOrderList } from 'shared/functions'
import { TableRow } from './TableRow'
import http from 'config/http'
import { CATEGORY_ENDPOINTS } from 'config/apis'

export const CategoriesTable = (props) => {
  const [editFormData, setEditFormData] = useState({})

  const categories = useSelector(CATEGORY_STATE.categories)

  const [defaultImage, setDefaultImage] = useState('')

  const formRef = useRef()

  useEffect(() => {
    onGetCategoryDefaultImage()
  }, [])

  const onEdit = (category) => {
    setEditFormData(category)

    if (formRef.current) {
      formRef.current?.showCatModal()
    }
  }

  const onGetCategoryDefaultImage = async () => {
    const result = await http.get(CATEGORY_ENDPOINTS?.GET_DEFAULT_IMAGE, '')
    if (result) {
      setDefaultImage(result?.data?.defaultImage?.file)
    }
  }

  return (
    <>
      <Row>
        <Col sm="12" md="12" lg="12">
          <Table className="table-hover table-striped">
            <thead>
              <tr>
                <th className="border-0">#</th>
                <th className="border-0">Title</th>
                <th className="border-0">Description</th>
                <th className="border-0">Icon</th>
                <th className="border-0">{props?.restorePage ? 'Restore' : 'Edit/Delete'}</th>
              </tr>
            </thead>
            <tbody>
              {props?.restorePage === false
                ? categories &&
                  categories?.length &&
                  onReOrderList(categories)?.map(
                    (category, index) =>
                    category?.title?.toLowerCase().includes(props.searchedItem.toLowerCase()) && (category.deleted === 0 || category.deleted === null) && (
                        <TableRow
                          key={index?.toString()}
                          category={category}
                          index={index}
                          onEdit={onEdit}
                          defaultImage={defaultImage}
                        />
                      )
                  )
                : categories &&
                  categories?.length &&
                  onReOrderList(categories)?.map(
                    (category, index) =>
                    category?.title?.toLowerCase().includes(props.searchedItem.toLowerCase()) && category.deleted === 1 && (
                        <TableRow
                          key={index?.toString()}
                          category={category}
                          index={index}
                          onEdit={onEdit}
                          restore={props?.restorePage}
                          defaultImage={defaultImage}
                        />
                      )
                  )}
            </tbody>
          </Table>
        </Col>
      </Row>
      <AddCategoryForm
        ref={formRef}
        isEdit={true}
        editFormData={editFormData}
      />
    </>
  )
}
