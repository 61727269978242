import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Container, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, withRouter } from 'react-router'

import classes from './courseDetail.module.css'
import { COURSES_STATE } from 'reducers/course/getState'
import { CourseDetailTable } from 'components/CourseDetailTable/CourseDetailTable'
import { useMemo } from 'react'
import { getCourse } from 'reducers/course/courses.action'
import { useRef } from 'react'
import { AddLessonsForm } from 'components/AddLessonsForm/AddLessonsForm'
import { setLocalStorage } from 'shared/functions'
import { getLocalStorage } from 'shared/functions'

export const CourseDetail = withRouter((props) => {

  const currentCourse = useSelector(COURSES_STATE.course)
  const location = useHistory()
  const dispatch = useDispatch()
  const [courseSelected, setcourseSelected] = useState()
  useEffect(() => {
    setcourseSelected(currentCourse)
  }, [currentCourse])
  
  const lessonsFormRef = useRef(null)

  function isObjectEmpty(obj) {
    return Object.keys(obj).length === 0;
}

  useEffect(() => {
    if(isObjectEmpty(currentCourse))
    {
      dispatch(getCourse({ course_id: getLocalStorage('course_id') }))
    }
    else {
      setLocalStorage('course_id',currentCourse?.course_id)
    }

  }, [currentCourse])

  const onClickAddLesson = () => {
    if (!lessonsFormRef.current) return
    lessonsFormRef.current.showModal()
  }
  
  return (
    <>
      <Container fluid>
        <Row>
          <Col sm="12" lg="12" md="12">
            <Card>
              <Card.Header className={classes.header}>
                <div>
                  <Button
                    size="sm"
                    className={`btn-fill ${classes.backBtn}`}
                    onClick={() => props?.history.goBack()}>
                    <i
                      className="nc-icon nc-stre-left"
                      style={{ fontSize: 14, fontWeight: 'bolder' }}
                    />
                  </Button>
                  <Card.Title>{currentCourse.title}</Card.Title>

                </div>

                <Button
                  size="sm"
                  variant={'primary'}
                  className={`btn-fill ${classes.backBtn}`}
                  onClick={onClickAddLesson}
                  >
                  Add Lesson
                </Button>
              </Card.Header>
              <Card.Body>
                {useMemo(
                  () => (
                    <CourseDetailTable course={courseSelected} {...props} />
                  ),
                  [courseSelected]
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <AddLessonsForm course={currentCourse} ref={lessonsFormRef} />
    </>
  )
})
