import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

import classes from "./style.module.css";
import { useSelector } from "react-redux";

import { showToast } from "shared/functions";
import { TOAST_TYPE } from "shared/functions";
import { isEmailValid } from "shared/functions";
import { GLOBAL_STATE } from "reducers/appLoading/getAppState";
import { REG_ROLES } from "shared/CONSTANTS";
import { Fragment } from "react";

export const AddUser = ({
  show = false,
  onClose = () => {},
  onSubmit = (formData) => {}
}) => {
  const [formData, setFormData] = useState({
    email: "",
    fname: "",
    lname: "",
    password: "",
    cpassword: "",
    role: "",
    usageType: JSON.stringify({ courseOffer: false, askASheikh: false })
  });

  const appLoading = useSelector(GLOBAL_STATE.loading);

  const onChangeInput = (key, value) => {
    const _formData = { ...formData };

    _formData[key] = value;

    setFormData(_formData);
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    const { password, cpassword, email } = formData;
    if (password !== cpassword) {
      return showToast("Password does not match", TOAST_TYPE.error);
    } else if (!isEmailValid(email)) {
      return showToast("Please enter valid email", TOAST_TYPE.error);
    }

    onSubmit(formData);
  };

  const resetForm = () => {
    setFormData({
      email: "",
      fname: "",
      lname: "",
      password: "",
      cpassword: "",
      role: "",
      usageType: JSON.stringify({ courseOffer: false, askASheikh: false })
    });
  };

  const onFormClose = () => {
    resetForm();
    onClose();
  };

  return (
    <Modal
      show={show}
      onHide={onFormClose}
      size="lg"
      animation={false}
      backdrop="static"
    >
      <Fragment>
        <Modal.Header style={{ display: "flex", alignItems: "center" }}>
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>
        <Modal.Body className={classes.modal_body}>
          <div>
            <Row>
              <Col className="pl-1" md="6" lg="6" sm="12">
                <Form.Group>
                  <label>First Name</label>
                  <Form.Control
                    name="name"
                    placeholder="First Name"
                    type="text"
                    onChange={(e) => onChangeInput("fname", e.target.value)}
                    value={formData.fname}
                    required
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col className="pl-1" md="6" lg="6" sm="12">
                <Form.Group>
                  <label>Last Name</label>
                  <Form.Control
                    name="name"
                    placeholder="Last Name"
                    type="text"
                    onChange={(e) => onChangeInput("lname", e.target.value)}
                    value={formData.lname}
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className="pl-1" md="12" lg="12" sm="12">
                <Form.Group>
                  <label>Email address</label>
                  <Form.Control
                    name="email"
                    placeholder="Email"
                    type="email"
                    onChange={(e) => onChangeInput("email", e.target.value)}
                    value={formData.email}
                    required
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className="pl-1" md="12" lg="6" sm="12">
                <Form.Group>
                  <label>Password</label>
                  <Form.Control
                    name="password"
                    placeholder="Password"
                    type="password"
                    onChange={(e) => onChangeInput("password", e.target.value)}
                    value={formData.password}
                    required
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col className="pl-1" md="12" lg="6" sm="12">
                <Form.Group>
                  <label>Confirm Password</label>
                  <Form.Control
                    name="cpassword"
                    placeholder="Confirm Password"
                    type="password"
                    onChange={(e) => onChangeInput("cpassword", e.target.value)}
                    value={formData.cpassword}
                    required
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md="12" lg="12" sm="12">
                <Form.Group>
                  <label>Who you are?</label>

                  <select
                    className={classes.select}
                    onChange={(e) => onChangeInput("role", e.target.value)}
                    required
                  >
                    <option value="">Who you are?</option>
                    <option value="Instructor">Instructor</option>
                    <option value="student">User</option>
                  </select>
                </Form.Group>
              </Col>
            </Row>

            <div className="clearfix"></div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            size="sm"
            type="button"
            variant="default"
            onClick={onFormClose}
          >
            Cancel
          </Button>
          <Button
            className="btn-fill pull-right"
            onClick={onSubmitForm}
            type="submit"
            variant="primary"
            size="sm"
            block
            disabled={
              appLoading ||
              !formData.fname ||
              !formData.email ||
              !formData.password ||
              !formData.cpassword ||
              !formData.role
            }
          >
            Submit
          </Button>
        </Modal.Footer>
      </Fragment>
    </Modal>
  );
};
