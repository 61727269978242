import React from 'react'
import { Button, Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { APP_LOADING_ACTIONS_TYPES } from 'reducers/appLoading/appLoading.action'
// import { setAppLoadingState } from 'reducers/appLoading/appLoading.action'
// import { GLOBAL_STATE } from "reducers/appLoading/getAppState";

import classes from './AppLoader.module.css'
import { cancelFileUpload } from 'shared/functions'

export const AppLoader = () => {
  const { app_loading, closeable, text, url } = useSelector(
    (state) => state.app_loading
  )

  console.log(url)
  const dispatch = useDispatch()

  const onCancelRequest = async () => {
    await cancelFileUpload()
    dispatch({
      type: APP_LOADING_ACTIONS_TYPES.APP_LOADING,
      payload: false,
    })
  }
  return (
    <>
      {app_loading ? (
        <div className={classes.wrapper}>
          <Spinner animation="grow" color="#fff"></Spinner>
          {closeable ? (
            <>
              {' '}
              <br />
              <div className="visually-hidden" style={{ color: 'white' }}>
                {text}
              </div>
              <br />
              <Button
                onClick={onCancelRequest}
                className="btn-fill pull-right"
                variant={'primary'}>
                Cancel
              </Button>
            </>
          ) : (
            <div
              className="visually-hidden"
              style={{ color: 'white', marginTop: '15px' }}>
              {text !== '' ? text : 'Please wait data is loading'}
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  )
}
