const { ILMA_ACTION_TYPES } = require("./ilma.actions");

const INITIAL_STATE = {
  list: [],
  selectedIlma: {},
};

const IlmaReducer = (state = INITIAL_STATE, actions) => {
  switch (actions.type) {
    case ILMA_ACTION_TYPES.SET_ILMAS:
      return {
        ...state,
        list: [...actions.payload],
      };

    case ILMA_ACTION_TYPES.SET_SELECTED_ILMA:
      return {
        ...state,
        selectedIlma: actions.payload,
      };

    default:
      return state;
  }
};

export default IlmaReducer;
