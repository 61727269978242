import React, { useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import classes from "./style.module.css";
import { LoginForm } from "components/LoginForm/LoginForm";
import { Link } from "react-router-dom";
import { onLogin } from "reducers/auth/auth.actions";
import { redirectTo } from "shared/functions";
import { GLOBAL_STATE } from "reducers/appLoading/getAppState";
import { AUTH_STATE } from "reducers/auth/getState";

export const Login = (props) => {
  const dispatch = useDispatch();
  const appLoading = useSelector(GLOBAL_STATE.loading);
  const authSuccess = useSelector(AUTH_STATE.authSuccess);

  useEffect(() => {
    if (!appLoading && authSuccess) {
      return redirectTo(props, { path: "/admin/dashboard" });
    }
  }, [appLoading, authSuccess]);

  const onSubmitForm = async (data) => {
    dispatch(onLogin(data));
  };

  return (
    <div className={`${classes.wrapper}`}>
      <Container className={classes.login_container}>
        <Col lg="4" md="6" sm="6">
          <Card className={classes.card}>
            <Card.Header className={classes.card_header}>
              <Card.Title as="h4">Login</Card.Title>
            </Card.Header>
            <Card.Body>
              <LoginForm onSubmit={onSubmitForm} />
            </Card.Body>
            <Card.Footer>
              <Row>
                <Col>
                  Don't have account?{" "}
                  <Link to="/admin/signup">Create new one</Link>
                </Col>
              </Row>
            </Card.Footer>
          </Card>
        </Col>
      </Container>
    </div>
  );
};
