import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  Button,
  Col,
  Form,
  Row,
  InputGroup,
  Card,
  FormGroup,
  FormControl,
} from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

import { AUTH_STATE } from 'reducers/auth/getState'
import { onUpdateProfile } from 'reducers/users/users.action'
import { SUPER_ADMIN } from 'shared/CONSTANTS'
import { TextEditor } from 'components/TextEditor/TextEditor'
import { ChangePassword } from './ChangePassword'
import { showToast } from 'shared/functions'
import { TOAST_TYPE } from 'shared/functions'
import countryList from 'react-select-country-list'
import Select from 'react-select'
import PostalCodes from 'postal-codes-js'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { values } from 'lodash'
import { UserProfileCard } from 'components/UserProfileCard/UserProfileCard'

export const ProfileUpdateForm = () => {
  const options = useMemo(() => countryList().getData(), [])
  const [image, setimage] = useState(null)

  const [userLocal, setLocalUserState] = useState({
    fname: '',
    lname: '',
    email: '',
    city: '',
    country: '',
    zipCode: '',
    bio: '',
    contact: '',
    oldPass: '',
    newPass: '',
    confirmPass: '',
    contactCountry: 'MY',
    usageType: { courseOffer: false, askASheikh: false },
  })
  const [usageType, setUsageType] = useState({
    courseOffer: false,
    askASheikh: false,
  })
  const user = useSelector(AUTH_STATE.user)
  const dispatch = useDispatch()
  const editorRef = useRef(null)
  const [validCode, setvalidCode] = useState(null)
  const [validPhoneNumber, setvalidPhoneNumber] = useState(null)

  useEffect(() => {
    if (!user) return

    if (user.contact != null) {
      onCheckValidPhoneNo(user.contact)
    }
    if (user.zipCode != null) {
      onCheckZipCode(user.zipCode)
    }

    setLocalUserState({ ...user })
    const { usageType = {} } = user
    let _usageType = {}

    if (typeof usageType === 'string') {
      _usageType = JSON.parse(usageType)
    } else {
      _usageType = { ...usageType }
    }
    setUsageType(_usageType)
  }, [user])

  const onCheckZipCode = (value) => {
    const Check =
      value?.length > 0
        ? PostalCodes.validate(userLocal?.contactCountry, value)
        : null

    if (Check === true) {
      setvalidCode(Check)
    } else {
      setvalidCode(false)
    }
  }

  const onCheckValidPhoneNo = (value) => {
    const isValid = value?.length > 4 ? isValidPhoneNumber(value) : false

    if (isValid === true) {
      setvalidPhoneNumber(isValid)
    } else {
      setvalidPhoneNumber(isValid)
    }
  }

  const onChangeInput = (key, value) => {
    if (key.toLowerCase() === 'contact' && value !== undefined) {
      const isValid = value?.length > 5 ? isValidPhoneNumber(value) : false
      const _userLocal = { ...userLocal }
      if (isValid === true) {
        setvalidPhoneNumber(isValid)
      } else {
        setvalidPhoneNumber(isValid)
      }
      _userLocal[key] = value

      setLocalUserState(_userLocal)
      return
    }
    if (key.toLowerCase() === 'country') {
      const _userLocal = { ...userLocal }
      _userLocal[key] = value.label
      setLocalUserState(_userLocal)
      return
    }
    if (key.toLowerCase() === 'zipcode') {
      if (userLocal.contactCountry === null)
        return showToast('Please select country first', TOAST_TYPE.error)
      const Check =
        value.length > 0
          ? PostalCodes.validate(userLocal.contactCountry, value)
          : false
      const _userLocal = { ...userLocal }
      _userLocal[key] = value

      setLocalUserState(_userLocal)
      if (Check === true) {
        setvalidCode(Check)
      } else {
        setvalidCode(false)
      }
      return
    } else {
      const _userLocal = { ...userLocal }
      _userLocal[key] = value
      setLocalUserState(_userLocal)
      return
    }
  }

  const onChangeUsageType = (key, checked) => {
    const _usageType = { ...usageType }
    _usageType[key] = checked
    setUsageType(_usageType)

    const _userLocal = { ...userLocal }
    _userLocal.usageType = JSON.stringify(_usageType)
    setLocalUserState(_userLocal)
  }

  const onSubmitForm = async (e) => {
    e.preventDefault()
    if (
      userLocal.confirmPass !== '' &&
      userLocal.newPass !== '' &&
      userLocal.confirmPass !== userLocal.newPass
    ) {
      return alert('Password Does not match!')
    }
    if (userLocal.contactCountry !== '' && !validPhoneNumber)
      return showToast('Valid Phone Number is required', TOAST_TYPE.error)
    if (userLocal.contact !== '' && !validCode)
      return showToast('Valid Zip code is required', TOAST_TYPE.error)
    if (image !== null) {
      await dispatch(onUpdateProfile({ ...userLocal, image: image }))
    } else {
      await dispatch(onUpdateProfile(userLocal))
    }
  }

  return (
    <>
      <Row>
        <Col md="8">
          <Card>
            <Card.Header>
              <Card.Title as="h4">Edit Profile</Card.Title>
            </Card.Header>
            <Card.Body>
              <Form onSubmit={onSubmitForm}>
                <Row>
                  <Col md="6">
                    <Form.Group>
                      <label>First Name</label>
                      <Form.Control
                        placeholder="First Name"
                        type="text"
                        name="fname"
                        value={userLocal?.fname}
                        onChange={(e) =>
                          onChangeInput('fname', e.target.value)
                        }></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group>
                      <label>Last Name</label>
                      <Form.Control
                        placeholder="Last Name"
                        type="text"
                        name="lname"
                        value={userLocal?.lname}
                        onChange={(e) =>
                          onChangeInput('lname', e.target.value)
                        }></Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <Form.Group>
                      <label>Email address</label>
                      <Form.Control
                        placeholder="Email"
                        type="email"
                        value={userLocal?.email}
                        onChange={(e) =>
                          onChangeInput('email', e.target.value)
                        }></Form.Control>
                    </Form.Group>
                  </Col>

                  <Col md="6">
                    <Form.Group>
                      <label>Contact</label>
                      <PhoneInput
                        international
                        countryCallingCodeEditable={false}
                        value={userLocal.contact}
                        defaultCountry={'MY'}
                        style={{ height: '40px' }}
                        onCountryChange={(country) => {
                          userLocal.contactCountry = country
                        }}
                        onChange={(contact) =>
                          onChangeInput('contact', contact)
                        }
                        className={
                          validPhoneNumber !== null
                            ? validPhoneNumber
                              ? 'square border border-2 border-success'
                              : 'square border border-2 border-danger'
                            : ''
                        }
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Form.Group>
                      <label>
                        Description of experience and specialization
                      </label>
                      <TextEditor
                        value={user && user.bio}
                        placeholder="Description of experience and specialization"
                        ref={editorRef}
                        onChange={(value) => onChangeInput('bio', value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-1" md="4">
                    <Form.Group>
                      <label>City</label>
                      <Form.Control
                        placeholder="City"
                        type="text"
                        value={userLocal.city}
                        onChange={(e) =>
                          onChangeInput('city', e.target.value)
                        }></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col className="px-1" md="4">
                    <Form.Group>
                      <label>Country</label>
                      <Select
                        options={options}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            zIndex: 10000,
                          }),
                          option: (base) => ({
                            ...base,
                            zIndex: 1000,
                            opacity: 1,
                            backgroundColor: 'white',
                          }),
                        }}
                        value={countryList()
                          .getData()
                          .filter(
                            (item, index) => item.label === userLocal.country
                          )}
                        onChange={(value) => onChangeInput('country', value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col className="pl-1" md="4">
                    <Form.Group>
                      <label>Postal Code</label>
                      <Form.Control
                        placeholder="ZIP Code"
                        type="number"
                        value={userLocal.zipCode}
                        className={
                          validCode !== null
                            ? validCode
                              ? 'square border border-2 border-success'
                              : 'square border border-2 border-danger'
                            : ''
                        }
                        onChange={(e) =>
                          onChangeInput('zipCode', e.target.value)
                        }></Form.Control>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md="12">
                    <Row>
                      <Col sm="12" md="4">
                        <FormGroup>
                          <Form.Label>Current Password</Form.Label>
                          <FormControl
                            value={userLocal.oldPass}
                            placeholder="Current Password"
                            type="password"
                            autoComplete="off"
                            minLength="6"
                            onChange={(e) =>
                              onChangeInput('oldPass', e.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>

                      <Col sm="12" md="4">
                        <FormGroup>
                          <Form.Label>New Password</Form.Label>
                          <FormControl
                            value={userLocal.newPass}
                            placeholder="New Password"
                            type="password"
                            autoComplete="off"
                            minLength="6"
                            onChange={(e) =>
                              onChangeInput('newPass', e.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="12" md="4">
                        <FormGroup>
                          <Form.Label>Confirm Password</Form.Label>
                          <FormControl
                            value={userLocal.confirmPass}
                            placeholder="Confirm Password"
                            type="password"
                            autoComplete="off"
                            minLength="6"
                            onChange={(e) =>
                              onChangeInput('confirmPass', e.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                {user?.role !== SUPER_ADMIN.role && (
                  <Row>
                    <Col md="12">
                      <label>How do you want be part of our community</label>
                      <Form.Group>
                        <InputGroup>
                          <InputGroup.Checkbox
                            checked={usageType.courseOffer}
                            onChange={(e) =>
                              onChangeUsageType('courseOffer', e.target.checked)
                            }
                            aria-label="I would like offer courses"
                          />
                          <Form.Control
                            disabled
                            value="I would like offer courses"
                          />
                        </InputGroup>
                      </Form.Group>
                      <Form.Group>
                        <InputGroup>
                          <InputGroup.Checkbox
                            checked={usageType.askASheikh}
                            onChange={(e) =>
                              onChangeUsageType('askASheikh', e.target.checked)
                            }
                            aria-label="I would like offer courses"
                          />
                          <Form.Control
                            disabled
                            value="I would like to be part of the ask a sheikh forum"
                          />
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>
                )}
                <Button
                  className="btn-fill pull-right"
                  type="submit"
                  variant="info">
                  Save
                </Button>
                <div className="clearfix"></div>
              </Form>
              {/* <hr /> */}
            </Card.Body>
          </Card>
        </Col>
        <Col md="4">
          <UserProfileCard onSetImage={(value) => setimage(value)} />
        </Col>
      </Row>
    </>
  )
}
