import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Container, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router'

import classes from './askASheikh.module.css'
import { getAllCat } from 'reducers/categories/categories.action'
import { CATEGORY_STATE } from 'reducers/categories/getState'
import { Questions } from './Questions'
import { getQuestions } from 'reducers/askASheikh/askASheikh.actions'
import { onReOrderList } from 'shared/functions'

const ViewAllQuestions = () => {
  const [showQuestions, setQuestionsState] = useState(false)
  const [selectedCat, setSelectedCat] = useState({})
  const dispatch = useDispatch()
  const categories = useSelector(CATEGORY_STATE.categories)

  useEffect(() => {
    //fetch categories
    dispatch(getAllCat())
  }, [])

  const onSelectCategory = async (category) => {
    await dispatch(getQuestions(category.category_id))
    setQuestionsState(true)
    setSelectedCat(category)
  }

  return (
    <Container fluid>
      <Row>
        <Col sm="12" lg="12" md="12">
          <Card>
            <Card.Body>
              <h3>Topics</h3>
              <Row>
                {onReOrderList(categories)?.map(
                  (cat, ind) =>
                    cat.title !== 'uncategorize' && (
                      <Col md="3" sm="6" key={ind?.toString() + cat?.title}>
                        <Button
                          className={classes.category_btn}
                          onClick={() => onSelectCategory(cat)}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection:"column",
                              justifyContent: 'space-between',
                              // background: 'red',
                              width: '100%',
                            }}>
                            <div>{cat.title}</div>{' '}
                            {/* <div>Total - {cat.questions}</div> */}
                          </div>
                        </Button>
                      </Col>
                    )
                )}
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Questions
        visible={showQuestions}
        onClose={() => setQuestionsState(false)}
        category={selectedCat}
      />
    </Container>
  )
}

export default withRouter(ViewAllQuestions)
