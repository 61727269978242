import { OnDeleteItem } from "components/DeleteConfirmation";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getAllCat } from "reducers/categories/categories.action";
import { CATEGORY_STATE } from "reducers/categories/getState";
import { KNOWLEDGE_JOURNEY_STATE } from "reducers/knowledgeJourney/getState";
import { updateAssessments } from "reducers/knowledgeJourney/knowledgeJourney.action";
import { deleteAssessment } from "reducers/knowledgeJourney/knowledgeJourney.action";
import { getAssessments } from "reducers/knowledgeJourney/knowledgeJourney.action";
import { showToast } from "shared/functions";
import { TOAST_TYPE } from "shared/functions";
import { redirectTo } from "shared/functions";
import { CreateAssessment } from "./components/CreateAssessment";
import { CreateMultiAssessment } from "./components/CreateMultiAssessment";
import { EditMultiAssessment } from "./components/EditMultiAssessment";
import { TableComponent } from "./components/table/Table";

import classes from "./knowledge.module.css";

export const KnowledgeJourney = (props) => {
  const assessments = useSelector(KNOWLEDGE_JOURNEY_STATE.assessments);
  const categories = useSelector(CATEGORY_STATE.categories);
  const [showModal, setModalState] = useState(false);
  const [showModalMulti, setModalMultiState] = useState(false);
  const [showupdatedModal, setupdatedModal] = useState(false);
  const [editableData, seteditableData] = useState();
  const [searchedItem, setsearchedItem] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (!categories.length) dispatch(getAllCat());
    dispatch(getAssessments());
  }, []);

  const onEditItem = (question) => {
    seteditableData(question);
    setupdatedModal(!showupdatedModal);

    // dispatch(updateAssessments(question.id));
  };
  const onDeleteAssement = (question) => { 
    if(question?.id === undefined)return showToast("Question id is not valid", TOAST_TYPE.error);
    else
    OnDeleteItem({onApplySuccessFunction:() =>   dispatch(deleteAssessment(question?.id)),title:'Assessment'})
  };

  return (
    <Container fluid>
      <Row>
        <Col sm="12" lg="12" md="12">
          <Card>
            <Card.Header>
              <Row>
                <Col sm="12" lg="6" md="6">
                <Card.Title>
                    Assessments List
                </Card.Title>
                </Col>
                <Col sm="12" lg="6" md="6" className={classes.header_col}>
                  {/* <Button
                    size="sm"
                    className="btn btn-fill"
                    onClick={() => setModalState(true)}
                  >
                    Add Assessments
                    <i
                      className={`nc-icon nc-simple-add ${classes.create_btn_icon}`}
                    />
                  </Button> */}
                  <Button
                    size="sm"
                    className="btn btn-fill"
                    onClick={() => setModalMultiState(true)}
                    style={{ marginLeft: "10px" }}
                  >
                    Add Assessments
                    <i
                      className={`nc-icon nc-simple-add ${classes.create_btn_icon}`}
                    />
                  </Button>
                  <Button
                    size="sm"
                    className="btn btn-fill"
                    onClick={() =>
                      redirectTo(props, {
                        path: "/admin/knowledge-journey/view-questions"
                      })
                    }
                    style={{ marginLeft: "10px" }}
                  >
                    View Questions
                  </Button>
                </Col>
              </Row>
              <Row className='mt-2 d-flex justify-content-end'>
                <Col sm="12" lg="3" md="2">
                  <Form.Control
                    name="name"
                    placeholder="Enter Title to Search Assessment"
                    type="text"
                    onChange={(e) => setsearchedItem(e.target.value)}
                    // value={formData.fname}
                    required></Form.Control>
                  </Col>
                </Row>
            </Card.Header>
            <Card.Body>
              <TableComponent
                data={assessments}
                onDelete={onDeleteAssement}
                deleteEnable={true}
                editEnable={true}
                onEdit={onEditItem}
                searchedItem={searchedItem}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <CreateAssessment onClose={() => setModalState(false)} show={showModal} />
      <EditMultiAssessment
        content={editableData}
        onClose={() => setupdatedModal(false)}
        show={showupdatedModal}
      />
      <CreateMultiAssessment
        onClose={() => setModalMultiState(false)}
        show={showModalMulti}
      />
    </Container>
  );
};
