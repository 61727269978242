import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Form, Modal, Row, FormGroup } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'

import { FileSelectionInput } from 'components/AddLessonsForm/FileSelectionInput.js'
import { FormFileViewer } from 'components/AddLessonsForm/FormFileViewer'
import { isEmpty } from 'lodash'
import { ILMA_STATE } from 'reducers/ilma/getState'

import classes from './style.module.css'
import { showToast } from 'shared/functions'
import { TOAST_TYPE } from 'shared/functions'
import http from 'config/http'
import { ILMA_ENDPOINTS } from 'config/apis'

import { uploadFileToCloudinaryViaAPI } from 'shared/functions'
import { setAppCloseableLoadingState } from 'reducers/appLoading/appLoading.action'
import { toast } from 'react-toastify'

export const UpdateIlmaDefaultVideoForm = ({
  show = false,
  onClose = () => {},
  editIlma = false,
}) => {
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    file: '',
    type: '',
    pubtype: '',
    schedule_time: '',
    schedule_date: '',
  })

  const dispatch = useDispatch()

  useEffect(() => {
    onGetIma()
  }, [])

  const onGetIma = async () => {
    try {
      const data = await http.get(`${ILMA_ENDPOINTS.GET_ILMA_DEFAUT_VIDEO}`)
      const form = { ...formData };
      form.file = data?.data?.ilmas.file; 
      setFormData(form)
    } catch (error) {
      console.log(error)
    }
  }
  const onSubmitForm = async (e) => {
    e.preventDefault()
    onSaveTheData()
  }

  const onResetFormAndCloseModal = () => {
    onClose()
    setFormData({
      title: '',
      description: '',
      file: '',
      type: '',
      pubtype: '',
      schedule_date: moment().format('YYYY-MM-DD'),
      schedule_time: '',
    })
  }
  const onSaveTheData = async () => {
    if(formData.file === '') return showToast('Please upload the video',TOAST_TYPE.error)
    dispatch(
      setAppCloseableLoadingState({
        status: true,
        closeable: true,
        text: 'Please wait updating Ilma',
      })
    )
    const data = {
      file: formData?.file,
      path: 'ilmas',
    }
    const _file = await uploadFileToCloudinaryViaAPI(data,dispatch)

    var reqBody = {
      title: 'default Video',
      description: 'NA',
      schedule_time: Date.now(),
      schedule_date: Date.now(),
      file: _file.url,
      filePath: _file.public_id,
    }

    try {
      await http.put(`${ILMA_ENDPOINTS.UPDATE_ILMA_DEFAUT_VIDEO}`, reqBody)

      dispatch(
        setAppCloseableLoadingState({
          status: false,
          closeable: false,
          text: '',
        })
      )
      showToast('video successfully updated.', TOAST_TYPE.success)
      onResetFormAndCloseModal()
    } catch (error) {
      console.log(error)
    }
  }

  const onPressCancelBtn = () => {
    onClose()
    setFormData({
      title: '',
      description: '',
      file: '',
      type: '',
      pubtype: '',
      schedule_time: '',
      schedule_date: '',
    })
  }

  return (
    <Modal
      show={show}
      onHide={onClose}
      size="lg"
      animation={false}
      backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Default Video</Modal.Title>
      </Modal.Header>
      <Form onSubmit={onSubmitForm}>
        <Modal.Body>
          <Row style={{ marginTop: 12 }}>
            <Col sm="6" md="3">
              {!formData.file ? (
                <FileSelectionInput
                  onFilesSelected={(file) => {
                    const _formData = { ...formData }
                    _formData['file'] = file[0]
                    setFormData(_formData)
                  }}
                  accept="video/mp4,video/x-m4v,video/*"
                  title="Select Video"
                  multiple={false}
                />
              ) : (
                <FormFileViewer
                  file={formData.file}
                  isPDF={false}
                  isVideo={true}
                  onRemove={(file) => {
                    const _formData = { ...formData }
                    _formData['file'] = ''
                    setFormData(_formData)
                  }}
                />
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" variant="default" onClick={onPressCancelBtn}>
            Cancel
          </Button>
          <Button
            className="btn-fill pull-right"
            type="submit"
            variant={editIlma ? 'info' : 'primary'}>
            {'Submit'}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
