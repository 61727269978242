import React, { useState } from 'react'
import { Button, Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { AUTH_STATE } from 'reducers/auth/getState'
import { onUpdateProfile } from 'reducers/users/users.action'

export const UserProfileCard = ({onSetImage}) => {
  const user = useSelector(AUTH_STATE.user)
  const [uploading, setuploading] = useState(false)
  const dispatch = useDispatch()
  const [tempImage, settempImage] = useState('')

  const UploadImage = async (e) => {
    e.preventDefault()
    setuploading(true)
    var file = e.target.files[0]
    settempImage(URL.createObjectURL(file))
    // await dispatch(onUpdateProfile({ ...user, image: file }))
    onSetImage(file)
    setuploading(false)
  }

  return (
    <>
      <Card className="card-user">
        <div className="card-image">
          <img
            alt="..."
            src={
              require('assets/img/photo-1431578500526-4d9613015464.jpeg')
                .default
            }></img>
        </div>
        <Card.Body>
          <div className="author"
      style={{
        display: 'flex',
        flexDirection:"column",
        alignItems: 'center',
        justifyContent: 'center',
      }}
>
            {/* <a href="/admin/account" onClick={(e) => e.preventDefault()}> */}

            <img
              alt="..."
              className="avatar border-gray"
              src={
                tempImage !== ''
                  ? tempImage
                  : !user?.image
                  ? require('assets/img/faces/face-0.jpg').default
                  : user?.image
              }>

            </img>
            <div
                style={{
                  // position:"absolute",
                  zIndex: 100,
                  marginTop: "-35px",
                  padding: '0 5px',
                  background: 'white',
                  border:"1px solid gray",
                  width: '25px',
                  height: '25px',
                  borderRadius: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <label
                  htmlFor="image"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <i
                    style={{ color: 'black', marginTop: '6px' , fontWeight:"bold" , cursor:"pointer" }}
                    className="nc-icon nc-cloud-upload-94"></i>
                </label>
              </div>
            <input
              type="file"
              id="image"
              style={{ display: 'none' }}
              onChange={(e) => {
                UploadImage(e)
              }}
              accept="image/*"
            />
            <div>{uploading && 'Uploading....'}</div>
            <h5 className="title">{user?.name}</h5>
            {/* </a> */}
          </div>
          <p
            className="description text-center"
            dangerouslySetInnerHTML={{
              __html: !user?.bio ? '-' : user?.bio,
            }}></p>
        </Card.Body>
        <hr></hr>
        <div className="button-container mr-auto ml-auto">
          <Button
            className="btn-simple btn-icon"
            href="#pablo"
            onClick={(e) => e.preventDefault()}
            variant="link">
            <i className="fab fa-facebook-square"></i>
          </Button>
          <Button
            className="btn-simple btn-icon"
            href="#pablo"
            onClick={(e) => e.preventDefault()}
            variant="link">
            <i className="fab fa-twitter"></i>
          </Button>
          <Button
            className="btn-simple btn-icon"
            href="#pablo"
            onClick={(e) => e.preventDefault()}
            variant="link">
            <i className="fab fa-google-plus-square"></i>
          </Button>
        </div>
      </Card>
    </>
  )
}
