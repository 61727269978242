import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Container, Row, Accordion } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";

import classes from "./components/style.module.css";
import { getQuestions } from "reducers/askASheikh/askASheikh.actions";
import { getUnAnswerdQuestions } from "reducers/askASheikh/askASheikh.actions";
import UnAnsweredListItem from "./components/UnAnsweredListItem";
import { ASK_A_SHEIKH_GET_STATE } from "reducers/askASheikh/getState";
import QuestionItem from "./components/QuestionItem";
import { redirectTo } from "shared/functions";
import { AskQuestion } from "./components/AskQuestion";

const AskASheikh = (props) => {
  const dispatch = useDispatch();
  const unAnswered = useSelector(ASK_A_SHEIKH_GET_STATE.unAnswered);
  const questions = useSelector(ASK_A_SHEIKH_GET_STATE.questions);

  const [selectedQuestion, setSelectedQuestion] = useState({});
  const [modalState, setmodalState] = useState(false);
  const [selectedTopic, setselectedTopic] = useState();
  const modalRef = useRef(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    return dispatch(getUnAnswerdQuestions());
  };

  const onQuestionSelect = (question) => {
    if (!modalRef.current) return;
    setSelectedQuestion(question);
    return modalRef.current.toggle();
  };

  const viewAll = () => {
    return redirectTo(props, {
      path: "/admin/answer-a-questions/view-all-questions"
    });
  };
  const askShiekhQuestion = (item) => {
    setSelectedQuestion(item);
    setmodalState(!modalState);
    // return redirectTo(props, {
    //   path: "/admin/answer-a-questions/ask-shiekh-question"
    // });
  };

  return (
    <Container fluid>
      <Row>
        <Col sm="12" lg="12" md="12">
          <Card>
          <Card.Header>
              <Row>
                <Col sm="12" lg="9" md="9">
                  <Card.Title>Manage Q&A</Card.Title>
                </Col>

                <Col sm="12" lg="3" md="3" style={{display:"flex", justifyContent:"end"}}>
                <Button size="sm" className={"btn btn-fill"} onClick={viewAll}>
                    View All
                  </Button>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              {/* <h3>Topics</h3> */}
              <Accordion defaultActiveKey="0">
                {unAnswered?.map((item, index) => {
                  if (item.title !== "uncategorize") {
                    return (
                      <Accordion.Item
                        eventKey={index}
                        className={classes.accordion_item}
                      >
                        <Accordion.Header
                          className={classes.accordion_item_header}
                        >
                          <div>{item.title}</div>
                          <div className={`${classes.view_all} `}>
                            <Button
                              size="sm"
                              className={"btn btn-fill"}
                              style={{ whiteSpace: "nowrap" }}
                              onClick={() => askShiekhQuestion(item)}
                            >
                              Add Question
                            </Button>
                            &nbsp;
                            {item?.questions?.length > 0 && (
                              <Button
                                size="sm"
                                className={"btn btn-fill"}
                                style={{ whiteSpace: "nowrap" }}
                                // onClick={viewAll}
                              >
                                View Past Questions {item?.questions?.length}
                              </Button>
                            )}
                          </div>
                        </Accordion.Header>
                        <Accordion.Body className={classes.accordion_item_body}>
                          {item?.questions?.length ? (
                            item?.questions?.map((q) => (
                              <div
                                key={q.question_id}
                                className={classes.question_item}
                                onClick={() => onQuestionSelect(q)}
                              >
                                <strong>{q.question}</strong>
                              </div>
                            ))
                          ) : (
                            <div
                              className={`${classes.question_item} ${classes.no_more_questions}`}
                            >
                              No open questions
                            </div>
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  }
                })}
              </Accordion>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <QuestionItem ref={modalRef} question={selectedQuestion} />
      <AskQuestion
        payload={selectedQuestion}
        show={modalState}
        onClose={() => setmodalState(!modalState)}
      />
    </Container>
  );
};

export default withRouter(AskASheikh);
