import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  Modal,
  Row
} from "react-bootstrap";

import classes from "./style.module.css";
import { useDispatch } from "react-redux";
import { updateMultiAssessments } from "reducers/knowledgeJourney/knowledgeJourney.action";

export const EditMultiAssessment = ({
  content,
  show = false,
  onClose = () => {}
}) => {
  const [formData, setFormData] = useState({
    title: content?.title,
    scoref: content?.scoref,
    scoret: content?.scoret,
    id: content?.id
  });

  useEffect(() => {
    setFormData({
      title: content?.title,
      scoref: content?.scoref,
      scoret: content?.scoret,
      id: content?.id
    });
  }, [content]);

  const dispatch = useDispatch();

  const resetForm = () => {
    setFormData({
      title: "",
      scoref: 0,
      scoret: 0,
      id: content?.id
    });
  };

  const onChangeInput = (key, value) => {
    const _formData = { ...formData };
    _formData[key] = value;
    setFormData(_formData);
  };

  const onFormClose = () => {
    // resetForm();
    onClose();
  };

  const onSubmit = (e) => {
    e.preventDefault();

    dispatch(updateMultiAssessments(formData));
    onFormClose();
  };

  return (
    <Modal
      show={show}
      onHide={onFormClose}
      size="lg"
      animation={false}
      backdrop="static"
    >
      <Form onSubmit={onSubmit}>
        <Modal.Header style={{ display: "flex", alignItems: "center" }}>
          <Modal.Title>Update Multi Assessment</Modal.Title>
        </Modal.Header>
        <Modal.Body className={classes.modal_body}>
          <div className={classes.question}>
            <Row>
              <Col sm="12" md="12" lg="12">
                <FormGroup>
                  <Form.Label>Assessment Title *</Form.Label>
                  <FormControl
                    type="text"
                    placeholder="Assessment Title *"
                    value={formData.title}
                    onChange={(e) => onChangeInput("title", e.target.value)}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row style={{ marginTop: "10px" }}>
              <Col sm="12" md="6" lg="6">
                <FormGroup>
                  <Form.Label>Scores From *</Form.Label>
                  <FormControl
                    type="number"
                    min="0"
                    max="100"
                    placeholder="Scores From *"
                    value={formData.scoref}
                    onChange={(e) => onChangeInput("scoref", e.target.value)}
                    required
                  />
                </FormGroup>
              </Col>
              <Col sm="12" md="6" lg="6">
                <FormGroup>
                  <Form.Label>Scores To *</Form.Label>
                  <FormControl
                    type="number"
                    min="0"
                    max="100"
                    placeholder="Scores To *"
                    value={formData.scoret}
                    onChange={(e) => onChangeInput("scoret", e.target.value)}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            size="sm"
            type="button"
            variant="default"
            onClick={onFormClose}
          >
            Cancel
          </Button>
          <Button
            className="btn-fill pull-right"
            type="submit"
            variant="primary"
            size="sm"
          >
            Update Questions
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
