import { OnDeleteItem } from 'components/DeleteConfirmation'
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap'
import Pagination from 'react-bootstrap/Pagination'
import { useDispatch, useSelector } from 'react-redux'
import { onAddUser } from 'reducers/users/users.action'
import { onDeleteUser } from 'reducers/users/users.action'
import { onEditUser } from 'reducers/users/users.action'
import { AddUser } from './components/AddUser'

import { EditUser } from './components/EditUser'
import { TableComponent } from './components/table/Table'

import classes from './knowledge.module.css'
import { getAllUsersWidthPagination } from 'reducers/users/users.action'

export const UserManagement = (props) => {
  const { usersList , users } = useSelector((state) => state.users)

  const [showModal, setModalState] = useState(false)
  const [showModalEditModal, setEditModalState] = useState(false)
  const [userEditData, setuserEditData] = useState()
  const [searchedItem, setsearchedItem] = useState(false)
  const [page, setpage] = useState(1)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      getAllUsersWidthPagination({
        page: page,
        search: 0,
        searchQuery: '',
      })
    )
  }, [page])

  console.log(users)
  const onDeleteUserID = (userData) => {
    OnDeleteItem({
      onApplySuccessFunction: () => dispatch(onDeleteUser(userData.user_id)),
      title: 'User',
    })
  }

  const onEditItem = async (userData) => {
    setEditModalState(!showModalEditModal)
    await setuserEditData(userData)
  }

  let items = []
  for (let number = 1; number <= usersList?.pagination?.total_pages; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === page}
        activeLabel=""
        onClick={() => setpage(number)}>
        {number}
      </Pagination.Item>
    )
  }

  const paginationBasic = (
    <div className="d-flex justify-content-end">
      <Pagination>{items}</Pagination>

      <br />
    </div>
  )

  const onSearchUser = (e) => {
    if (e?.target?.value.length > 2) {
      dispatch(
        getAllUsersWidthPagination({
          page: page,
          search: 1,
          searchQuery: e.target.value,
        })
      )
      setsearchedItem(true)
    } else {
      dispatch(
        getAllUsersWidthPagination({
          page: 1,
          search: 0,
          searchQuery: '',
        })
      )
      setsearchedItem(false)
    }
  }
  return (
    <Container fluid>
      <Row>
        <Col sm="12" lg="12" md="12">
          <Card>
            <Card.Header>
              <Row>
                <Col sm="12" lg="6" md="6">
                  Users List
                </Col>
                <Col sm="12" lg="6" md="6" className={classes.header_col}>
                  <Button
                    size="sm"
                    className="btn btn-fill"
                    onClick={() => setModalState(true)}
                    style={{ marginLeft: '10px' }}>
                    Add User
                    <i
                      className={`nc-icon nc-simple-add ${classes.create_btn_icon}`}
                    />
                  </Button>
                </Col>
              </Row>
              <Row className="mt-2 d-flex justify-content-end">
                <Col sm="12" lg="3" md="2">
                  <Form.Control
                    name="name"
                    placeholder="Enter Title to Search Ilma"
                    type="text"
                    onChange={onSearchUser}
                    // value={formData.fname}
                    required></Form.Control>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <TableComponent
                data={users.slice(0,9)}
                onDelete={onDeleteUserID}
                onEdit={onEditItem}
                deleteEnable={true}
                editEnable={true}
              />
              {!searchedItem && paginationBasic}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <AddUser
        onClose={() => setModalState(false)}
        show={showModal}
        onSubmit={(data) => {
          dispatch(onAddUser(data))
          setModalState(false)
        }}
      />
      <EditUser
        onClose={() => setEditModalState(false)}
        userData={userEditData}
        show={showModalEditModal}
        onSubmit={(data) => {
          dispatch(onEditUser(data))
          setEditModalState(false)
        }}
      />
    </Container>
  )
}
