import React, { useEffect, useRef } from "react";
import {
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  Row
} from "react-bootstrap";

import classes from "./style.module.css";
import {
  QUESTION_SELECTION_TYPES,
  CHALLENGE_TIMING_TYPES
} from "shared/CONSTANTS";
import { QuestionType } from "components/QuestionType/QuestionType";
import { QUESTION_TYPES } from "shared/CONSTANTS";
import { CATEGORY_STATE } from "reducers/categories/getState";
import { useSelector } from "react-redux";
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import { onReOrderList } from "shared/functions";

export const QuestionItem = ({
  question = {},
  onChange = (key, value, index) => {},
  onAddTag = (key, value, index) => {},
  onRemove = (question) => {},
  onUpdateQuestionOptions = (options, index) => {},
  settags,
  tags,
  id = ""
}) => {

  const scrollRef = useRef(null);
  const topics = useSelector(CATEGORY_STATE.categories);

  useEffect(() => {
    if (!scrollRef.current) return;

    scrollRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);

  const questionOptions = (options) => {

    onUpdateQuestionOptions(options, id);
  };

  return (
    <div className={classes.question} ref={scrollRef}>
      <Row>
        <Col
          sm="12"
          md="12"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            variant="danger"
            size="sm"
            className="btn btn-fill"
            onClick={() => onRemove(question)}
          >
            &times;
          </Button>
        </Col>
      </Row>
      <Row>
        <Col className={classes.separate} sm="12" md="12">
          <FormGroup>
            <Form.Label>Question *</Form.Label>
            <FormControl
              type="text"
              value={question.question}
              placeholder="Question"
              onChange={(e) => onChange("question", e.target.value, id)}
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col className={classes.separate} sm="12" md="12">
          <Form.Label>Difficulty Level *</Form.Label>
          <FormControl
            placeholder="Difficulty Level"
            type="number"
            max="16"
            min="1"
            value={question.level}
            onChange={(e) => {
              const level = parseInt(e.target.value);
              onChange("level", level, id);
            }}
            required
          />
        </Col>
      </Row>

      <Row>
        <Col className={classes.separate} sm="12" md="12">
          <Form.Label>Topic *</Form.Label>
          <select
            className={classes.select}
            value={question.category_id}
            onChange={(e) => onChange("category_id", e.target.value, id)}
          >
            <option value="">Select Topic</option>
            {onReOrderList(topics).map((topic, index) => {
              if (topic.title.toLowerCase() !== 'uncategorize')
                return (
                  <option value={topic.category_id} key={index.toString()}>
                    {topic.title}
                  </option>
                )
            })}
          </select>
        </Col>
      </Row>

      {/**
       * Question Type
       */}
      <Row>
        <Col className={classes.separate} sm="12" md="12">
          <Form.Label>Question Type *</Form.Label>
          <select
            value={question.type}
            onChange={(e) => onChange('type', e.target.value, id)}
            className={classes.select}>
            <option value="">Question Type</option>
            {QUESTION_SELECTION_TYPES.map((type, index) => (
              <option value={type.type} key={index.toString()}>
                {type.title}
              </option>
            ))}
          </select>
        </Col>
      </Row>
      {question.type == QUESTION_TYPES.CHECK_BOX && (
        <>
          <Row>
            <Col className={classes.separate} sm="12" md="12">
              <Form.Label>Challenge Timing *</Form.Label>
              <select
                value={question.challenge_timing}
                onChange={(e) => onChange('time', e.target.value, id)}
                className={classes.select}>
                <option value="">Challenge Timing</option>
                {CHALLENGE_TIMING_TYPES.map((type, index) => (
                  <option value={type.type} key={index.toString()}>
                    {type.title}
                  </option>
                ))}
              </select>
            </Col>
          </Row>
          <Row>
            <Col className={classes.separate} sm="12" md="12">
              <FormGroup>
                <Form.Label>Possible Answer *</Form.Label>
                <FormControl
                  type="text"
                  placeholder="Possible Answer *"
                  // value={formData.title}
                  onChange={(e) => onChange('answer', e.target.value, id)}
                />
              </FormGroup>
              {/* <Form.Label>Possible Answers *</Form.Label> */}

              {/* <ReactTagInput
                tags={tags}
                onChange={(newTags) => onAddTag("options", newTags, id)}
              /> */}
            </Col>
          </Row>
        </>
      )}
      {question.type === QUESTION_TYPES.TEXT_INPUT && (
        <Row>
          <Col className={classes.separate} sm="12" md="12">
            <FormGroup>
              <Form.Label>Possible Answer *</Form.Label>
              <FormControl
                type="text"
                placeholder="Possible Answer *"
                onChange={(e) => onChange('answer', e.target.value, id)}
              />
            </FormGroup>
          </Col>
        </Row>
      )}


      <Row>
        <Col className={classes.separate} sm="12" md="12">
          {question.type &&
            question.type !== QUESTION_TYPES.TEXT_INPUT &&
            question.type !== QUESTION_TYPES.CHECK_BOX && (
              <QuestionType
                type={question.type}
                setQuestions={questionOptions}
              />
            )}
        </Col>
      </Row>
    </div>
  )
}
