import React, { useEffect, useRef, useState } from 'react'
import {
  Button,
  Card,
  Col,
  Container,
  Row,
  Accordion,
  Form,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router'

import classes from './components/style.module.css'
import { getQuestions } from 'reducers/askASheikh/askASheikh.actions'
import { getUnAnswerdQuestions } from 'reducers/askASheikh/askASheikh.actions'
import UnAnsweredListItem from './components/UnAnsweredListItem'
import { ASK_A_SHEIKH_GET_STATE } from 'reducers/askASheikh/getState'
import QuestionItem from './components/QuestionItem'
import { redirectTo } from 'shared/functions'
import { AUTH_STATE } from 'reducers/auth/getState'
import { SUPER_ADMIN } from 'shared/CONSTANTS'

const AskASheikh = (props) => {
  const dispatch = useDispatch()
  const currentUser = useSelector(AUTH_STATE.user)
  const unAnswered = useSelector(ASK_A_SHEIKH_GET_STATE.unAnswered)
  const [selectedQuestion, setSelectedQuestion] = useState({})
  const [searchedItem, setsearchedItem] = useState('')
  const [searchedQuestion, setsearchedQuestion] = useState('')

  const modalRef = useRef(null)

  useEffect(() => {
    //fetch unAnswered questions
    getData()
  }, [])

  const getData = async () => {
    return dispatch(getUnAnswerdQuestions())
  }

  const onQuestionSelect = (question) => {
    if (!modalRef.current) return
    setSelectedQuestion(question)
    return modalRef.current.toggle()
  }

  const viewAll = () => {
    return redirectTo(props, {
      path: '/admin/answer-a-questions/view-all-questions',
    })
  }
  const askShiekhQuestion = () => {
    return redirectTo(props, {
      path: '/admin/answer-a-questions/ask-shiekh-question',
    })
  }
  console.log(unAnswered)
  return (
    <Container fluid>
      <Row>
        <Col sm="12" lg="12" md="12">
          <Card>
            <Card.Header>
              <Row>
                <Col sm="12" lg="9" md="9">
                  <Card.Title>Manage Q&A</Card.Title>
                </Col>

                <Col
                  sm="12"
                  lg="3"
                  md="3"
                  style={{ display: 'flex', justifyContent: 'end' }}>
                  {currentUser.role === SUPER_ADMIN.role && (
                    <Button
                      size="sm"
                      className={'btn btn-fill'}
                      onClick={askShiekhQuestion}>
                      Add Question
                    </Button>
                  )}
                  &nbsp;
                  <Button
                    size="sm"
                    className={'btn btn-fill '}
                    onClick={viewAll}>
                    View All
                  </Button>
                </Col>
              </Row>
              <Row className="mt-2 d-flex justify-content-end">
                <Col sm="12" lg="3" md="2">
                  <Form.Control
                    name="name"
                    placeholder="Enter Question to Search"
                    type="text"
                    onChange={(e) => setsearchedQuestion(e.target.value)}
                    required></Form.Control>
                </Col>
                <Col sm="12" lg="3" md="2">
                  <Form.Control
                    name="name"
                    placeholder="Enter Category to Search"
                    type="text"
                    onChange={(e) => setsearchedItem(e.target.value)}
                    required></Form.Control>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              {/* CATEGORIES TOPICS QUESTIONS */}
              {searchedQuestion === '' && (
                <Accordion defaultActiveKey="0">
                  {unAnswered?.map(
                    (item, index) =>
                      item?.title
                        ?.toLowerCase()
                        .includes(searchedItem.toLowerCase()) &&
                      item.title !== 'uncategorize' && (
                        <UnAnsweredListItem
                          eventKey={index.toString()}
                          key={index.toString()}
                          item={item}
                          onQuestionSelect={onQuestionSelect}
                        />
                      )
                  )}
                </Accordion>
              )}

              {searchedQuestion !== '' &&
                searchedItem === '' &&
                unAnswered?.map((item, index) =>
                  item?.questions.map(
                    (item, index) =>
                      item?.question
                        ?.toLowerCase()
                        .includes(searchedQuestion.toLowerCase()) && (
                        <Accordion onClick={() => onQuestionSelect(item)}>
                          <Accordion.Item className={classes.accordion_item}>
                            <Accordion.Header
                              className={classes.accordion_item_header}>
                              <div
                                style={{
                                  display: 'flex',
                                  // justifyContent: 'space-between',
                                  textAlign: 'left',
                                  width: '100%',
                                }}>
                                <div
                                  style={{
                                    whiteSpace: 'nowrap',
                                    width: '100px',
                                  }}>
                                  Q - {index + 1} :{' '}
                                </div>
                                <div
                                  style={{
                                    width: '90%',
                                    wordBreak: 'break-all',
                                  }}>
                                  {item?.question}
                                </div>
                              </div>
                            </Accordion.Header>
                          </Accordion.Item>
                        </Accordion>
                      )
                      // <li
                      //   style={{ padding: '5px 0', fontSize: '16px' }}
                      //   onClick={() => onQuestionSelect(item)}>
                      //   {item.question}
                      // </li>
                  )
                )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <QuestionItem ref={modalRef} question={selectedQuestion} />
    </Container>
  )
}

export default withRouter(AskASheikh)
