import { OnDeleteItem } from "components/DeleteConfirmation";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAllCat } from "reducers/categories/categories.action";
import { CATEGORY_STATE } from "reducers/categories/getState";

import { KNOWLEDGE_JOURNEY_STATE } from "reducers/knowledgeJourney/getState";
import { updateQuestions } from "reducers/knowledgeJourney/knowledgeJourney.action";
import {
  getQuestions,
  deleteQuestion
} from "reducers/knowledgeJourney/knowledgeJourney.action";
import { CreateQuestions } from "../components/CreateQuestions";
import { EditQuestions } from "../components/EditQuestions";
import { TableComponent } from "../components/table/Table";

import classes from "../knowledge.module.css";

export const ViewQuestions = () => {
  const [showModal, setModalState] = useState(false);
  const [showEditModal, setEditModalState] = useState(false);
  const questions = useSelector(KNOWLEDGE_JOURNEY_STATE.questions);
  const categories = useSelector(CATEGORY_STATE.categories);
  const [editQuestion, seteditQuestion] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!categories.length) dispatch(getAllCat());

    dispatch(getQuestions());
  }, []);
  
  // useEffect(() => {
  //     seteditQuestion(questions)
  // }, [questions])
  
  const onDelete = (question) => {
    OnDeleteItem({onApplySuccessFunction:() => dispatch(deleteQuestion(question.id)),title:'Question'})
;
  };

  const onEdit = (question) => {
    const Options = JSON.parse(question.options);
    let clone = { ...question };
    clone.category_id = parseInt(clone.category_id)
    clone.options = Options;
    dispatch(updateQuestions(clone))
    setTimeout(() => {
      seteditQuestion(clone);
      setEditModalState(true);
    }, 500);
  };

  return (
    <Container fluid>
      <Row>
        <Col sm="12" lg="12" md="12">
          <Card>
            <Card.Header>
              <Row>
                <Col sm="12" lg="6" md="6">
                  Questions List
                </Col>
                <Col sm="12" lg="6" md="6" className={classes.header_col}>
                  <Button
                    size="sm"
                    className="btn btn-fill"
                    onClick={() => setModalState(true)}
                  >
                    Add Question
                    <i
                      className={`nc-icon nc-simple-add ${classes.create_btn_icon}`}
                    />
                  </Button>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <TableComponent
                data={questions}
                onDelete={onDelete}
                onEdit={onEdit}
                deleteEnable={true}
                editEnable={true}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <EditQuestions
        Question={editQuestion}
        setQuestion={seteditQuestion}
        show={showEditModal}
        onClose={() => setEditModalState(false)}
      />
      <CreateQuestions show={showModal} onClose={() => setModalState(false)} />
    </Container>
  );
};
