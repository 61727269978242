import React, { useState } from "react";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";

import style from "./style.module.css";
import "./style.css";

import { Button } from "react-bootstrap";

export const CustomOffCanvas = (props) => {
  return (
    <CSSTransition
      in={props?.show}
      timeout={300}
      classNames="container"
      unmountOnExit
      onExit={props?.onExit}
    >
      <div  className={style.wrapper}>
        <div className={`${style.container}`}>
          <div className={style.header}>
            <h3 style={{width:"85%"}}>{props.title}</h3>
            <Button
              onClick={props?.onClose}
              variant="primary"
              size="sm"
              className={`btn-fill ${style.closeBtn}`}
            >
              &times;
            </Button>
          </div>
          {props.children}
        </div>
      </div>
    </CSSTransition>
  );
};

CustomOffCanvas.propTypes = {
  title: PropTypes.string,
  show: PropTypes.bool,
  onExit: PropTypes.func,
  onClose: PropTypes.func,
};
