import { AUTH_ENDPOINTS } from "config/apis";
import { setAppLoadingState } from "reducers/appLoading/appLoading.action";
import { TOKEN_KEY } from "shared/CONSTANTS";
import { removeLocalStorage } from "shared/functions";
import { TOAST_TYPE } from "shared/functions";
import { showToast } from "shared/functions";
import { setLocalStorage } from "shared/functions";
import http from "../../config/http";

export const AUTH_ACTION_TYPES = {
  LOGIN: "[auth] LOGIN_ACTION",
  REGISTER: "[auth] REGISTER_ACTION",
  AUTH_FAILED: "[auth] AUTH_FAILED",
  RESET_AUTH_STATE: "[auth] RESET_AUTH_STATE",
  LOGOUT: "[auth] USER_LOGOUT"
};

//reset authentication redux state
export const resetAuthState = () => {
  return (dispatch) => {
    return dispatch({
      type: AUTH_ACTION_TYPES.RESET_AUTH_STATE
    });
  };
};

//login user
export const onLogin = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(setAppLoadingState(true));

      const login = await http.post(AUTH_ENDPOINTS.LOGIN, payload);
      //check for response
      if (login.status !== 200 || !login.data) {
        // showToast("Invalid email/password", TOAST_TYPE.error);
        dispatch(setAppLoadingState(false));
        return dispatch({
          type: AUTH_ACTION_TYPES.AUTH_FAILED
        });
      }
      if (login.data.is_blocked) {
        showToast("You are blocked by the admin.", TOAST_TYPE.error);
        dispatch(setAppLoadingState(false));
        return dispatch({
          type: AUTH_ACTION_TYPES.AUTH_FAILED
        });
      }

      //set local storage
      setLocalStorage(TOKEN_KEY, login.data?.token);

      showToast("Login success", TOAST_TYPE.success);
      dispatch(setAppLoadingState(false));
      return dispatch({
        type: AUTH_ACTION_TYPES.LOGIN,
        payload: login.data
      });
    } catch (err) {
      dispatch(setAppLoadingState(false));
      return dispatch({
        type: AUTH_ACTION_TYPES.AUTH_FAILED
      });
    }
  };
};

//create new account!
export const onRegister = (payload) => {
  return async (dispatch) => {
    try {
      //checking for admin email
      if (payload?.email.toLocaleLowerCase().match(/admin/g)) {
        return showToast("You cannot use this email", TOAST_TYPE.error);
      }

      dispatch(setAppLoadingState(true));
      const registerUser = await http.post(AUTH_ENDPOINTS.SIGNUP, payload);

      //check for response
      if (registerUser.status !== 200 || !registerUser?.data) {
        showToast("Unable to create account!", TOAST_TYPE.error);
        dispatch(setAppLoadingState(false));
        return dispatch({
          type: AUTH_ACTION_TYPES.AUTH_FAILED
        });
      }

      //set local storage
      setLocalStorage(TOKEN_KEY, registerUser.data?.token);

      showToast("Register success", TOAST_TYPE.success);
      dispatch(setAppLoadingState(false));
      return dispatch({
        type: AUTH_ACTION_TYPES.REGISTER,
        payload: registerUser.data
      });
    } catch (err) {
      dispatch(setAppLoadingState(false));
      return dispatch({
        type: AUTH_ACTION_TYPES.AUTH_FAILED
      });
    }
  };
};

//delete user session
export const onLogoutUser = () => {
  return async (dispatch) => {
    try {
      dispatch(setAppLoadingState(true));
      const logout = await http.post(AUTH_ENDPOINTS.LOGOUT);

      if (logout.status !== 200 || !logout.data) {
        showToast("Unable to process your request", TOAST_TYPE.error);
        dispatch(setAppLoadingState(false));
        return dispatch({
          type: AUTH_ACTION_TYPES.AUTH_FAILED
        });
      }

      //remove token from local storage
      removeLocalStorage(TOKEN_KEY);
      showToast("Logout success", TOAST_TYPE.success);
      dispatch(setAppLoadingState(false));
      return dispatch({
        type: AUTH_ACTION_TYPES.LOGOUT
      });
    } catch (err) {
      dispatch(setAppLoadingState(false));
      return dispatch({
        type: AUTH_ACTION_TYPES.AUTH_FAILED
      });
    }
  };
};

//get the logged-in user details from server
export const getSessionUserDetails = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setAppLoadingState(true));
      const sessionUser = await http.post(AUTH_ENDPOINTS.SESSION_USER);

      dispatch(setAppLoadingState(false));
      return dispatch({
        type: AUTH_ACTION_TYPES.LOGIN,
        payload: sessionUser.data
      });
    } catch (err) {
      dispatch(setAppLoadingState(false));
      removeLocalStorage(TOKEN_KEY);
      return dispatch({
        type: AUTH_ACTION_TYPES.RESET_AUTH_STATE
      });
    }
  };
};
