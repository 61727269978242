import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'

export const OnDeleteItem = ({ onApplySuccessFunction, title, restore }) => {
  Swal.fire({
    title: `Are you sure you want to ${
      restore !== undefined && restore === true ? 'restore' : 'delete'
    } this ${title}?`,
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: `Yes, ${
      restore !== undefined && restore === true ? 'restore' : 'delete'
    } it!`,
  }).then((result) => {
    if (result.isConfirmed) {
      Swal.fire(
        `${
          restore !== undefined && restore === true ? 'Restored' : 'Deleted!'
        }`,
        `Your file has been ${
          restore !== undefined && restore === true ? 'restore' : 'delete'
        }.`,
        'success'
      )
      onApplySuccessFunction()
    } else if (result.dismiss) {
      Swal.fire(
        'Cancelled',
        `${
          restore !== undefined && restore === true ? 'restore' : 'delete'
        } function cancelled.`,
        'error'
      )
    }
  })
}
