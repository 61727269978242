import React, { useEffect, useState } from 'react'
import { Col, Row, Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { AUTH_STATE } from 'reducers/auth/getState'
import { setSelectedCourse } from 'reducers/course/courses.action'
import { getCourse } from 'reducers/course/courses.action'

import { COURSES_STATE } from 'reducers/course/getState'
import { getQuiz } from 'reducers/quiz/quiz.actions'
import { SUPER_ADMIN } from 'shared/CONSTANTS'
import { INSTRUCTOR_ROLE } from 'shared/CONSTANTS'
import { redirectTo } from 'shared/functions'
import { TableRow } from './TableRow'
import { CreateCourseForm as UpdateCourseForm } from 'components/CreateCourseForm/CreateCourseForm'
import { getallTags } from 'reducers/tags/tags.actions'
import { onReOrderList } from 'shared/functions'

export const CoursesTable = (props) => {
  const [showCourseForm, setCourseForm] = useState({
    show: false,
    course: {},
  })
  const currentUser = useSelector(AUTH_STATE.user)
  const courses = useSelector(COURSES_STATE.courses)
  const [coursesList, setcoursesList] = useState([])
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getallTags())
    setcoursesList(courses)
  }, [courses])

  const onCourseSelect = async (course) => {
    await dispatch(getCourse({ course_id: course?.course_id }))

    return redirectTo(props, {
      path: '/admin/courses/course',
    })
  }

  const onQuizView = async (course) => {
    dispatch(setSelectedCourse(course))

    await dispatch(
      getQuiz({
        course_id: course?.course_id,
      })
    )

    return redirectTo(props, {
      path: '/admin/courses/quizes',
      state: {
        course_id: course?.course_id,
      },
    })
  }

  const onEdit = (course) => {
    setCourseForm({
      show: true,
      course: course,
    })
  }

  const onFilterListAccordingToPage = () => {
    return coursesList?.length > 0
      ? coursesList.filter((item) =>
          props?.restorePage
            ? item?.deleted === 1 && item
            : item?.deleted === 0 || (item?.deleted === null && item)
        )
      : []
  }

  return (
    <>
      <Row>
        <Col sm="12" md="12" lg="12">
          {!coursesList || !coursesList?.length ? (
            <p>
              {currentUser.role === SUPER_ADMIN.role ||
              currentUser.role === INSTRUCTOR_ROLE
                ? "You didn't create any course yet"
                : 'Please enter roll to a course'}
            </p>
          ) : (
            <Table className="table-hover table-striped">
              <thead>
                <tr>
                  <th className="border-0">#</th>
                  <th className="border-0">Title</th>
                  <th className="border-0">Created By</th>
                  <th className="border-0">View Details</th>
                  <th className="border-0">Add Lesson</th>
                  <th className="border-0">Status</th>
                  <th className="border-0">Quiz</th>
                  <th className="border-0">
                    {props.restorePage ? 'Restore | Delete' : 'Edit | Delete'}
                  </th>
                </tr>
              </thead>
              <tbody>
                {coursesList &&
                  coursesList?.length &&
                  onReOrderList(onFilterListAccordingToPage())?.map(
                    (course, index) =>
                      course.title
                        .toLowerCase()
                        .includes(props.searchedItem.toLowerCase()) && (
                        <TableRow
                          key={index?.toString()}
                          course={course}
                          index={index}
                          onClick={onCourseSelect}
                          onQuizView={onQuizView}
                          onEdit={onEdit}
                          restore={props?.restorePage}
                        />
                      )
                  )}

                {/* {coursesList?.map((course, index) => (
                  <TableRow
                    key={index?.toString()}
                    course={course}
                    index={index}
                    onClick={onCourseSelect}
                    onQuizView={onQuizView}
                    onEdit={onEdit}
                  />
                ))} */}
              </tbody>
            </Table>
          )}
        </Col>
      </Row>
      <UpdateCourseForm
        show={showCourseForm.show}
        onClose={() => setCourseForm({ show: false, course: {} })}
        editCourse={showCourseForm.course}
      />
    </>
  )
}
