import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

import classes from "./style.module.css";
import { QuestionItem } from "./QuestionItem";
import { useDispatch } from "react-redux";
import { createQuestions } from "reducers/knowledgeJourney/knowledgeJourney.action";
import { updateQuestions } from "reducers/knowledgeJourney/knowledgeJourney.action";
import { EditQuestionItem } from "./EditQuestionItem";

const QUESTION = {
  question: "",
  options: [],
  type: "",
  category_id: "",
  level: 1,
  answer: ""
};

export const EditQuestions = ({
  Question,
  setQuestion,
  show = false,
  onClose = () => {}
}) => {
  // const [Questions, setQuestions] = useState(editQuestion);

  const dispatch = useDispatch();
  const [tags, setTags] = React.useState([]);


  const resetForm = () => {
    // setQuestion(Question);
    setTags([]);
  };

  const onChangeInput = (key, value, index) => {
    // const _questionsList = [...Questions];
    // const _question = _questionsList[index];
    const clone = { ...Question };
    clone[key] = value;

    // _questionsList[index] = _question;
    setQuestion(clone);
  };

  const onAddTag = async (key, value, index) => {
    // const _questionsList = [...Questions];
    // const _question = _questionsList[index];
    // _question[key] = value;
    const clone = { ...Questions };
    clone[key] = value;
    setTags(value);
    // setQuestions(clone);
    // _questionsList[index] = _question;
    setQuestion(clone);
  };

  // const addQuestion = () => {
  //   const _questions = [...Questions];
  //   _questions.push({ ...QUESTION, id: _questions.length });
  //   setQuestions(_questions);
  // };

  const onRemove = (question) => {
    // const qq = [...Questions];
    // const _updatedQuestions = qq.filter((q) => q.id !== question.id);
    // if (_updatedQuestions?.length < 1) return;
    // return setQuestions(() => [..._updatedQuestions]);
  };

  const onUpdateQuestionOptions = (options, index) => {
    // const _questions = [...Questions];

    const currentQuestion = { ...Question };
    // const currentQuestion = _questions[index];

    currentQuestion.options = [...options];
    for (let i = 0; i < options?.length; i++) {
      if (options[i].isCorrect) {
        currentQuestion.answer = options[i].label;
      }
    }
    // _questions[index] = currentQuestion;

    setQuestion(currentQuestion);
  };

  const onFormClose = () => {
    resetForm();
    onClose();
  };

  const onSubmit = (e) => {
    e.preventDefault();

    dispatch(updateQuestions(Question));
    onFormClose();
  };

  return (
    <Modal
      show={show}
      onHide={onFormClose}
      size="lg"
      animation={false}
      backdrop="static"
    >
      <Form onSubmit={onSubmit}>
        <Modal.Header style={{ display: "flex", alignItems: "center" }} closeButton>
          <Modal.Title>Edit Questions</Modal.Title>
        </Modal.Header>
        <Modal.Body className={classes.modal_body}>
          <EditQuestionItem
            onChange={onChangeInput}
            question={Question}
            onRemove={onRemove}
            onAddTag={onAddTag}
            settags={(tag) => setTags(tag)}
            tags={tags}
            onUpdateQuestionOptions={onUpdateQuestionOptions}
            id={Question?.id ? Question?.id : 0}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="sm"
            type="button"
            variant="default"
            onClick={onFormClose}
          >
            Cancel
          </Button>
          <Button
            className="btn-fill pull-right"
            type="submit"
            variant="primary"
            size="sm"
          >
            Submit Questions
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
