/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { Navbar, Container, Nav, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'

import { NotificationsMenu } from 'components/NotificationMenu/NotificationsMenu'
import { onLogoutUser } from 'reducers/auth/auth.actions'
import { AUTH_STATE } from 'reducers/auth/getState'
import { GLOBAL_STATE } from 'reducers/appLoading/getAppState'
import { setAppRoutes } from 'reducers/appLoading/appLoading.action'

function Header() {
  const user = useSelector(AUTH_STATE.user)
  const routes = useSelector(GLOBAL_STATE.routes)
  const dispatch = useDispatch()
  const location = useLocation()

  const mobileSidebarToggle = (e) => {
    e.preventDefault()
    document.documentElement.classList.toggle('nav-open')
    var node = document.createElement('div')
    node.id = 'bodyClick'
    node.onclick = function () {
      this.parentElement.removeChild(this)
      document.documentElement.classList.toggle('nav-open')
    }
    document.body.appendChild(node)
  }

  const getBrandText = () => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i]
      }
    }
    return 'Brand'
  }

  const onLogout = async (e) => {
    e.preventDefault()

    await dispatch(onLogoutUser())
  }

  return (
    <Navbar bg="light" expand="lg">
      <Container fluid>
        <div className="d-flex justify-content-center align-items-center ml-2 ml-lg-0 mb-2">
          <Button
            variant="dark"
            className="d-lg-none btn-fill d-flex justify-content-center align-items-center rounded-circle p-2"
            onClick={mobileSidebarToggle}>
            <i className="fas fa-ellipsis-v"></i>
          </Button>

          <Navbar.Brand
            href="#home"
            onClick={(e) => e.preventDefault()}
            className="mr-2">
            <div style={{ display: 'flex' , alignItems:"center" , justifyContent:"center"}}>
              <i className={getBrandText().icon} style={{fontSize:"28px" , height:"12px"}}/>
              {getBrandText().name === "User Management" ? <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> : <span>&nbsp;&nbsp;&nbsp;</span>}
              <div style={{ height:"5px" }}>{getBrandText().name}</div>
            </div>
          </Navbar.Brand>
        </div>

        <Navbar.Toggle
          onClick={mobileSidebarToggle}
          aria-controls="basic-navbar-nav"
          className="mr-2">
          <span className="navbar-toggler-bar burger-lines"></span>
          <span className="navbar-toggler-bar burger-lines"></span>
          <span className="navbar-toggler-bar burger-lines"></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="nav mr-auto" navbar>
            {/* <Nav.Item>
              <Nav.Link
                className="m-0"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <i className="nc-icon nc-zoom-split"></i>
                <span className="d-lg-block"> Search</span>
              </Nav.Link>
            </Nav.Item> */}
          </Nav>
          <Nav className="ml-auto" navbar>
            {/* <Nav.Item>
              <Nav.Link
                className="m-0"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <span className="no-icon">Account</span>
              </Nav.Link>
            </Nav.Item> */}

            {/* <NotificationsMenu /> */}
            {/* <div className="d-sm-block  d-md-none">
              {routes.map((prop, key) => {
                if (!prop.redirect)
                  return (
                    <Nav.Item>
                      <NavLink
                        to={prop.layout + prop.path}
                        className="m-0"
                        activeClassName="active"
                        onClick={mobileSidebarToggle}
                      >
                        <p>{prop.name}</p>
                      </NavLink>
                    </Nav.Item>
                  );
                return null;
              })}
            </div> */}
            {/* <Nav.Item>
              <Nav.Link className="m-0" href="#" onClick={onLogout}>
                <span className="no-icon">Log out</span>
              </Nav.Link>
            </Nav.Item> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
      <Navbar.Brand style={{marginTop:"15px" , fontSize:"16px"}} className="text-nowrap"> {user?.role === "SUPER_ADMIN" ? 'SUPER ADMIN' : user?.role }</Navbar.Brand>
    </Navbar>
  )
}

export default Header
