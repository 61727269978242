import React, { useRef, useState } from 'react'
import { Accordion, Button } from 'react-bootstrap'
import PropTypes from 'prop-types'

import classes from './style.module.css'

const UnAnsweredListItem = (props) => {
  const {
    item = {},
    onQuestionSelect = (question) => {},
    eventKey = '0',
  } = props || {}

  return (
    <Accordion.Item eventKey={eventKey} className={classes.accordion_item}>
      <Accordion.Header className={classes.accordion_item_header}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            // background: 'red',
            width:"100%"
          }}>
          <div>{item?.title}</div> <div>{item?.questions?.length > 0 ? `Total ${item?.questions?.length}` : '' }</div>
        </div>
      </Accordion.Header>
      <Accordion.Body className={classes.accordion_item_body}>
        {item?.questions?.length ? (
          item?.questions?.map((q) => (
            <div
              key={q?.question_id}
              className={classes?.question_item}
              onClick={() => onQuestionSelect(q)}>
              <strong>{q?.question}</strong>
            </div>
          ))
        ) : (
          <div
            className={`${classes.question_item} ${classes.no_more_questions}`}>
            No open questions 
          </div>
        )}
      </Accordion.Body>
    </Accordion.Item>
  )
}

UnAnsweredListItem.propTypes = {
  item: PropTypes.objectOf({
    category_id: PropTypes.number,
    title: PropTypes.string,
    desc: PropTypes.string,
    icon: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    questions: PropTypes.arrayOf({
      question_id: PropTypes.number,
      question: PropTypes.string,
      description: PropTypes.string,
      category_id: PropTypes.number,
      sheikh_id: PropTypes.number,
      user_id: PropTypes.number,
      unAnswered: PropTypes.string,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
    }),
  }),
  onQuestionSelect: PropTypes.func,
  eventKey: PropTypes.string,
}

export default UnAnsweredListItem
