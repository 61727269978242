import { KNOWLEDGE_ACTION_TYPES } from "./knowledgeJourney.action";

const INITIAL_STATE = {
  questions: [],
  assessments: []
};

const knowledgeJourneyReducer = (state = INITIAL_STATE, actions) => {
  switch (actions.type) {
    case KNOWLEDGE_ACTION_TYPES.SET_QUESTIONS:
      return {
        ...state,
        questions: actions.payload
      };

    case KNOWLEDGE_ACTION_TYPES.SET_ASSESSMENTS:
      return {
        ...state,
        assessments: actions.payload
      };
    case KNOWLEDGE_ACTION_TYPES.UPDATE_ASSESSMENTS:
      const clone = [...state.assessments];
      console.log(actions.payload.id);
      state.assessments.filter((item, index) => {
        if (item.id === actions.payload.id) {
          clone[index].title = actions.payload.title;
          clone[index].scoref = actions.payload.scoref;
          clone[index].scoret = actions.payload.scoret;
        }
      });

      return {
        ...state,
        assessments: clone
      };

    default:
      return state;
  }
};

export default knowledgeJourneyReducer;
