import {
  ADD_USER,
  DELETE_USER,
  EDIT_USER,
  FETCH_ALL_USERS,
  FETCH_ALL_USERS_WITH_PAGINATION,
} from './actionType'

const INITIAL_STATE = {
  users: [],
  usersList: {},
}

const userReducer = (state = INITIAL_STATE, actions) => {
  switch (actions.type) {
    case FETCH_ALL_USERS:
      return {
        ...state,
        users: actions.payload,
      }
    case FETCH_ALL_USERS_WITH_PAGINATION:
      console.log(actions.payload)
      return {
        usersList: actions.payload,
        users: actions.payload.allUsers,
      }
    case ADD_USER:
      return {
        ...state,
        users: [...state.users, actions.payload],
      }
    case DELETE_USER:
      return {
        ...state,
        users: state.users.filter(
          (item, index) => item.user_id !== actions.payload
        ),
      }
    case EDIT_USER: {
      const clone = [...state.users]
      const index = clone.findIndex(
        (q) => q?.user_id === actions.payload?.user_id
      )

      if (index === -1) return

      clone[index] = actions.payload
      return {
        ...state,
        users: clone,
      }
    }

    default:
      return state
  }
}

export default userReducer
