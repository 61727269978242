import { CustomOffCanvas } from 'components/CustomOffCanvas/CustomOffCanvas'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Row, Table } from 'react-bootstrap'
import { useDispatch } from 'react-redux'

import classes from './CourseDetailTable.module.css'
import { getQuiz } from 'reducers/quiz/quiz.actions'
import { reOrderLesson } from 'reducers/videos/videos.action'
import { redirectTo } from 'shared/functions'
import { TableRow } from './TableRow'
import { QuestionItem } from './QuestionItem'
import { getLessonComments } from 'reducers/videos/videos.action'
import { Questions } from './Questions'
import { EditLessonsForm } from 'components/AddLessonsForm/EditLessonsForm'

export const CourseDetailTable = (props) => {
  const [showQuestionModal, setQuestionModalState] = useState(false)
  const { lessons = [], user = {}, course_id } = props.course || {}
  const [lesson_id, setLessonID] = useState('')
  const [editData, seteditData] = useState()
  const [modal, setmodal] = useState(false)

  const dispatch = useDispatch()

  const onQuizClick = async (lesson_id) => {
    await dispatch(
      getQuiz({
        course_id: course_id,
        lesson_id: lesson_id,
      })
    )

    return redirectTo(props, {
      path: '/admin/courses/quizes',
      state: {
        course_id: course_id,
        lesson_id: lesson_id,
      },
    })
  }

  useEffect(() => {}, [lessons, props.course])

  const onSwapUp = async (currentLesson) => {
    const payload = onSwapCourse(currentLesson, 'up')

    if (isEmpty(payload)) return

    await dispatch(reOrderLesson(payload))
  }

  const onSwapDown = async (currentLesson) => {
    const payload = onSwapCourse(currentLesson, 'down')

    if (isEmpty(payload)) return

    await dispatch(reOrderLesson(payload))
  }

  const onClickOpenQuestions = async (lesson) => {
    setLessonID(lesson)
    await dispatch(getLessonComments(lesson))
    setQuestionModalState(showQuestionModal ? false : true)
  }

  const onSwapCourse = (currentLesson, swap) => {
    const payload = { currentLessonId: null, swapableLessonId: null }

    //find lesson index
    const currentLessonIndex = lessons.findIndex(
      (lesson) => lesson.lesson_id === currentLesson.lesson_id
    )

    if (currentLessonIndex === -1) return

    let swapableLessonIndex = -1
    let swapableLessonId = -1

    if (swap === 'up') {
      //find previous lesson
      if (currentLessonIndex <= 0) return

      const [swapableLesson] = lessons.filter(
        (lesson) =>
          lesson.lesson_id === lessons[currentLessonIndex - 1].lesson_id
      )
      if (!swapableLesson) return

      swapableLessonIndex = lessons.findIndex(
        (lesson) => lesson.lesson_id === swapableLesson.lesson_id
      )
      swapableLessonId = swapableLesson.lesson_id
    } else if (swap === 'down') {
      //find next lesson
      if (currentLessonIndex >= lessons.length - 1) return

      const [swapableLesson] = lessons.filter(
        (lesson) =>
          lesson.lesson_id === lessons[currentLessonIndex + 1].lesson_id
      )
      if (!swapableLesson) return

      swapableLessonIndex = lessons.findIndex(
        (lesson) => lesson.lesson_id === swapableLesson.lesson_id
      )
      swapableLessonId = swapableLesson.lesson_id
    }

    if (swapableLessonIndex === -1) return

    payload.currentLessonId = currentLesson.lesson_id
    payload.swapableLessonId = swapableLessonId

    return payload
  }

  const onEdit = async(lesson) => {
    const _lesson = {
      ...lesson,
    }
    _lesson.file = JSON.parse(lesson.file)
    await seteditData(_lesson)
    setmodal(!modal)
  }

  return (
    <>
      <Row>
        <Col sm="12" md="12" lg="12">
          {lessons && !lessons?.length ? (
            <p>No lessons</p>
          ) : (
            <Table className="table-hover table-striped">
              <thead>
                <tr>
                  <th className="border-0">#</th>
                  <th className="border-0">Lesson</th>
                  <th className="border-0">Title</th>
                  <th className="border-0">Description</th>
                  {/* <th className="border-0">Quiz</th> */}
                  {/* <th className="border-0">Questions</th> */}
                  <th className="border-0">Edit/Delete</th>
                  <th className="border-0">Arrange</th>
                </tr>
              </thead>
              <tbody>
                {lessons.map((lesson, index) => (
                  <TableRow
                    key={lesson?.lesson_id?.toString()}
                    lesson={lesson}
                    totalLessons={lessons.length}
                    index={index}
                    onEdit={() => onEdit(lesson)}
                    onQuizClick={onQuizClick}
                    user={user}
                    onSwapUp={onSwapUp}
                    onSwapDown={onSwapDown}
                    onQuestionsClick={onClickOpenQuestions}
                  />
                ))}
              </tbody>
            </Table>
          )}
        </Col>
      </Row>

      <EditLessonsForm
        Lesson={editData}
        onModalState={() => setmodal(!modal)}
        modal={modal}
      />
      <Questions
        lessonID={lesson_id}
        visible={showQuestionModal}
        onClose={onClickOpenQuestions}
      />
    </>
  )
}
