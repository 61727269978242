import React, { useRef } from "react";
import { Button } from "react-bootstrap";
import { values } from "lodash";

import classes from "./addVideoForm.module.css";

export const FileSelectionInput = ({
  onFilesSelected = (files) => {},
  title = "Add lesson video",
  accept="video/mp4,video/x-m4v,video/*",
  multiple = true 
}) => {
  const inputRef = useRef(null);
  const onFileOpen = () => {
    if (!inputRef.current) return;

    inputRef.current?.click();
  };

  const wordsToRemove = ['video/','image/']
  let pattern = new RegExp(wordsToRemove.join("|"), "gi");

  return (

    <div>
      <Button
        className={`btn-fill ${classes.add_video_btn}`}
        variant="primary"
        onClick={onFileOpen}
      >
        {title}
        <i className={`nc-icon nc-simple-add ${classes.add_video_btn_icon}`} />
      </Button>
      <input
        type="file"
        ref={inputRef}
        className={classes.file_input}
        accept={accept}
        multiple={multiple}
        onChange={(e) => onFilesSelected(values(e.target.files))}
      />
      <div style={{fontSize:"10px", color: 'red' , whiteSpace:"nowrap"}}>Required Format : {accept.replace(pattern, "")} </div>
    </div>
  );
};
