/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from 'views/Dashboard.js'
import CoursesPage from 'views/Courses/Courses'
import { CategoriesPage } from 'views/Categories/CategoriesPage'
import { UserProfilePage } from 'views/UserProfile/UserProfilePage'
import { SUPER_ADMIN } from 'shared/CONSTANTS'
import store from './store'
import { setAppRoutes } from 'reducers/appLoading/appLoading.action'
import Ilma from 'views/Ilma/Ilma'
import AskASheikh from 'views/AskASheikh/AskASheikh'
import { KnowledgeJourney } from 'views/KnowledgeJourney'
import { UserManagement } from 'views/UserManagement'

const ADMIN_ROUTES = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'nc-icon nc-chart-pie-35',
    component: Dashboard,
    layout: '/admin',
  },
  {
    path: '/account',
    name: 'Manage Your Account',
    icon: 'nc-icon nc-circle-09',
    component: UserProfilePage,
    layout: '/admin',
  },
  {
    path: '/courses',
    name: 'Manage Courses',
    icon: 'nc-icon nc-notes',
    component: CoursesPage,
    layout: '/admin',
  },
  {
    path: '/answer-a-questions',
    name: 'Manage Q&A',
    icon: 'nc-icon nc-chat-round',
    component: AskASheikh,
    layout: '/admin',
  },
  {
    path: '/ilma',
    name: 'Manage videos & posts',
    icon: 'nc-icon nc-bulb-63',
    component: Ilma,
    layout: '/admin',
  },

  {
    path: '/knowledge-journey',
    name: 'Manage Assessments',
    icon: 'nc-icon nc-paper-2',
    component: KnowledgeJourney,
    layout: '/admin',
  },

  {
    path: '/categories',
    name: 'Manage Categories',
    icon: 'nc-icon nc-grid-45',
    component: CategoriesPage,
    layout: '/admin',
  },
  {
    path: '/user-management',
    name: 'User Management',
    icon: 'fa fa-users',
    component: UserManagement,
    layout: '/admin',
  },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "nc-icon nc-atom",
  //   component: Icons,
  //   layout: "/admin",
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "nc-icon nc-pin-3",
  //   component: Maps,
  //   layout: "/admin",
  // },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   icon: "nc-icon nc-bell-55",
  //   component: Notifications,
  //   layout: "/admin",
  // },
]

const INSTRUCTOR_USAGE_COURSE_AND_SHEIKH_ROUTES = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'nc-icon nc-chart-pie-35',
    component: Dashboard,
    layout: '/admin',
  },
  {
    path: '/account',
    name: 'Manage Account',
    icon: 'nc-icon nc-circle-09',
    component: UserProfilePage,
    layout: '/admin',
  },
  {
    path: '/courses',
    name: 'Manage Courses',
    icon: 'nc-icon nc-notes',
    component: CoursesPage,
    layout: '/admin',
  },
  {
    path: '/answer-a-questions',
    name: 'Manage Q&A',
    icon: 'nc-icon nc-chat-round',
    component: AskASheikh,
    layout: '/admin',
  },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "nc-icon nc-atom",
  //   component: Icons,
  //   layout: "/admin",
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "nc-icon nc-pin-3",
  //   component: Maps,
  //   layout: "/admin",
  // },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   icon: "nc-icon nc-bell-55",
  //   component: Notifications,
  //   layout: "/admin",
  // },
]

const INSTRUCTOR_USAGE_COURSE_OFFER_ROUTES = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'nc-icon nc-chart-pie-35',
    component: Dashboard,
    layout: '/admin',
  },
  {
    path: '/account',
    name: 'Manage Account',
    icon: 'nc-icon nc-circle-09',
    component: UserProfilePage,
    layout: '/admin',
  },
  {
    path: '/courses',
    name: 'Manage Courses',
    icon: 'nc-icon nc-notes',
    component: CoursesPage,
    layout: '/admin',
  },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "nc-icon nc-atom",
  //   component: Icons,
  //   layout: "/admin",
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "nc-icon nc-pin-3",
  //   component: Maps,
  //   layout: "/admin",
  // },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   icon: "nc-icon nc-bell-55",
  //   component: Notifications,
  //   layout: "/admin",
  // },
]

const INSTRUCTOR_USAGE_ASK_SHEIKH_ROUTES = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'nc-icon nc-chart-pie-35',
    component: Dashboard,
    layout: '/admin',
  },
  {
    path: '/account',
    name: 'Manage Account',
    icon: 'nc-icon nc-circle-09',
    component: UserProfilePage,
    layout: '/admin',
  },
  {
    path: '/answer-a-questions',
    name: 'Manage Q&A',
    icon: 'nc-icon nc-chat-round',
    component: AskASheikh,
    layout: '/admin',
  },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "nc-icon nc-atom",
  //   component: Icons,
  //   layout: "/admin",
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "nc-icon nc-pin-3",
  //   component: Maps,
  //   layout: "/admin",
  // },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   icon: "nc-icon nc-bell-55",
  //   component: Notifications,
  //   layout: "/admin",
  // },
]

const INSTRUCTOR_USAGE_COURSE_NO_OFFER_ROUTES = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'nc-icon nc-chart-pie-35',
    component: Dashboard,
    layout: '/admin',
  },
  {
    path: '/account',
    name: 'Manage Account',
    icon: 'nc-icon nc-circle-09',
    component: UserProfilePage,
    layout: '/admin',
  },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "nc-icon nc-atom",
  //   component: Icons,
  //   layout: "/admin",
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "nc-icon nc-pin-3",
  //   component: Maps,
  //   layout: "/admin",
  // },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   icon: "nc-icon nc-bell-55",
  //   component: Notifications,
  //   layout: "/admin",
  // },
]

const useRoutes = (user) => {
  const { usageType = {}, role } = user
  let _usageType = {}
  if (typeof usageType === 'string') {
    _usageType = JSON.parse(usageType)
  } else {
    _usageType = { ...usageType }
  }
  const { courseOffer = false, askASheikh = false } = _usageType

  if (role === SUPER_ADMIN.role) {
    store.dispatch(setAppRoutes(ADMIN_ROUTES))
  } else {
    if (!courseOffer && !askASheikh) {
      store.dispatch(setAppRoutes(INSTRUCTOR_USAGE_COURSE_NO_OFFER_ROUTES))
    } else if (courseOffer && askASheikh) {
      store.dispatch(setAppRoutes(INSTRUCTOR_USAGE_COURSE_AND_SHEIKH_ROUTES))
    } else if (courseOffer) {
      store.dispatch(setAppRoutes(INSTRUCTOR_USAGE_COURSE_OFFER_ROUTES))
    } else if (askASheikh) {
      store.dispatch(setAppRoutes(INSTRUCTOR_USAGE_ASK_SHEIKH_ROUTES))
    }
  }
}

export default useRoutes
