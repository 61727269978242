import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Form, Row, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { CATEGORY_STATE } from 'reducers/categories/getState'
import { onCreateCourse } from 'reducers/course/courses.action'
import { CustomDropdown } from 'components/CustomDropdown/CustomDropdown'

import classes from './CreateCourseForm.module.css'
import { debounce, isEmpty } from 'lodash'
import { updateCourse } from 'reducers/course/courses.action'
import { FileSelectionInput } from 'components/AddLessonsForm/FileSelectionInput.js'
import { FormFileViewer } from 'components/AddLessonsForm/FormFileViewer'
import { searchTags } from 'reducers/tags/tags.actions'
import { TAGS_STATE } from 'reducers/tags/getState'
import { customDebounce } from 'shared/functions'
import { addTag } from 'reducers/tags/tags.actions'
import { TextEditor } from 'components/TextEditor/TextEditor'
import { showToast } from 'shared/functions'
import { TOAST_TYPE } from 'shared/functions'
import { getallTags } from 'reducers/tags/tags.actions'
import { onReOrderList } from 'shared/functions'

const COURSE_LEVELS = [
  {
    title: 'Beginner',
    value: 'beginner',
  },
  {
    title: 'Intermediate',
    value: 'intermediate',
  },
  {
    title: 'Advance',
    value: 'advance',
  },
]

const COURSE_LANGUAGES = [
  {
    title: 'English',
    value: 'english',
  },
  {
    title: 'Arabic',
    value: 'arabic',
  },
  {
    title: 'Malay',
    value: 'malay',
  },
  {
    title: 'Mandarin',
    value: 'mandarin',
  },
  {
    title: 'Spanish',
    value: 'spanish',
  },
]

const INITIAL_FORM_STATE = {
  title: '',
  description: '',
  language: '',
  level: '',
  category_ids: '',
  institute_name: '',
  thumbnail: '',
  tags: [],
  categories: [],
}

export const CreateCourseForm = ({
  show = false,
  onClose = () => {},
  editCourse = {},
}) => {
  const categories = useSelector(CATEGORY_STATE.categories)
  const tags = useSelector(TAGS_STATE.tags)

  const [tag, setTagInput] = useState('')
  const [formData, setFormData] = useState(INITIAL_FORM_STATE)

  const dispatch = useDispatch()

  const editorRef = useRef(null)

  useEffect(() => {
    if (!show || isEmpty(editCourse)) return

    // const cats = ''

    // editCourse?.categories?.map((cat) => {
    //   // cats.set(cat.category_id, !!!cats.get(cat.category_id))

    // })

    const _tag_ids = editCourse !== '' ? JSON.parse(editCourse?.tag_ids) : []
    let tagList = []
    if (_tag_ids?.length > 0) {
      tags.filter((item, index) => {
        _tag_ids.filter((_item, index) => {
          if (parseInt(item.tag_id) === parseInt(_item)) {
            tagList.push(item)
          }
        })
      })
    }
    setFormData({
      ...editCourse,
      tags: tagList,
      category_ids: editCourse?.categories[0]?.category_id,
    })
  }, [show])

  const onChangeInput = (key, value) => {
    const _formData = { ...formData }
    _formData[key] = value

    setFormData(_formData)
  }

  const onChangeTags = (value) => {
    setTagInput(value)
    if (tags?.includes(value) || !value) return

    //search the api
    customDebounce(dispatch(searchTags(value)))
  }

  const onRemoveTag = (tag) => {
    const _formData = { ...formData }
    const updatedTags = _formData.tags?.filter((t) => t?.tag_id !== tag?.tag_id)
    // delete _formData.tags

    _formData.tags = updatedTags

    // const newFormData = {
    //   ..._formData,
    //   tags: [...updatedTags],
    // }

    setFormData({ ..._formData })
  }

  const onClickAddTag = async () => {
    if (!tag) return

    setTagInput('')
    const newTag = await dispatch(addTag(tag))
    onSelectTag(newTag)
  }

  const onSelectTag = (tag) => {
    const _formData = { ...formData }

    const [existingTag] = formData.tags?.filter(
      (t) => t?.tag_id === tag?.tag_id
    )

    if (existingTag) return
    _formData.tags = [...formData.tags, tag]

    setTagInput('')
    setFormData({ ..._formData })
  }

  const onSubmitForm = async () => {
    if (isEmpty(editCourse)) {
      if (formData?.title === '') {
        return showToast('Course title is required', TOAST_TYPE.error)
      }
      if (formData?.language === '') {
        return showToast('Course Language is required', TOAST_TYPE.error)
      }
      if (formData?.level === '') {
        return showToast('Course Level is required', TOAST_TYPE.error)
      }
      if (formData?.category_ids === '') {
        return showToast('Course Category is required', TOAST_TYPE.error)
      }
      if (formData?.thumbnail === '') {
        return showToast('Image is required', TOAST_TYPE.error)
      }

      await dispatch(onCreateCourse(formData))
    } else if (!isEmpty(editCourse)) {
      await dispatch(updateCourse(formData))
    }
    onClose()
    setFormData(INITIAL_FORM_STATE)
  }

  const onGetCategoryTitle = () => {
    const selectedTitle = categories.filter(
      (item) => parseInt(item.category_id) === parseInt(formData?.category_ids)
    )
    return selectedTitle?.length > 0
      ? selectedTitle[0].title
      : 'Select Category'
  }

  return (
    <Modal
      show={show}
      onHide={onClose}
      size="lg"
      animation={false}
      backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>
          {!isEmpty(editCourse) ? 'Update Course' : 'Create New Course'}
        </Modal.Title>
      </Modal.Header>
      <Form>
        <Modal.Body>
          <Row>
            <Col lg="6" md="6" sm="12">
              <Form.Group>
                <Form.Label>Course Title</Form.Label>
                <Form.Control
                  name="title"
                  placeholder="Course Title"
                  type="text"
                  value={formData.title}
                  onChange={(e) =>
                    onChangeInput('title', e.target.value)
                  }></Form.Control>
              </Form.Group>
            </Col>
            <Col lg="6" md="6" sm="12">
              <Form.Group>
                <Form.Label>Institute Name</Form.Label>
                <Form.Control
                  name="institute_name"
                  placeholder="Institute Name"
                  type="text"
                  value={formData?.institute_name}
                  onChange={(e) =>
                    onChangeInput('institute_name', e.target.value)
                  }></Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg="6" md="6" sm="12">
              <Form.Group>
                <Form.Label>Course Language</Form.Label>
                <CustomDropdown
                  title={
                    !isEmpty(formData.language)
                      ? formData.language
                      : 'Select Course Language'
                  }
                  items={COURSE_LANGUAGES}
                  onSelect={(language) =>
                    onChangeInput('language', language.value)
                  }
                />
              </Form.Group>
            </Col>
            <Col lg="6" md="6" sm="12">
              <Form.Group>
                <Form.Label>Course Level</Form.Label>
                <CustomDropdown
                  title={
                    !isEmpty(formData.level)
                      ? formData.level
                      : 'Select Course Level'
                  }
                  items={COURSE_LEVELS}
                  onSelect={(level) => onChangeInput('level', level.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg="6" md="6" sm="12">
              <Form.Label>Course Categories</Form.Label>
              <Form.Group>
                <CustomDropdown
                  title={
                    formData?.category_ids !== ''
                      ? onGetCategoryTitle()
                      : 'Select Category'
                  }
                  items={onReOrderList(categories)}
                  onSelect={(category) =>
                    onChangeInput('category_ids', category.category_id)
                  }
                />
              </Form.Group>
            </Col>
          </Row>
          <Row style={{ marginTop: '10px' }}>
            <Col sm="6" md="3">
              {!formData.thumbnail ? (
                <FileSelectionInput
                  onFilesSelected={(file) => {
                    const _formData = { ...formData }
                    _formData['thumbnail'] = file[0]
                    setFormData(_formData)
                  }}
                  title="Click to upload Image"
                  accept="image/png, image/gif, image/jpeg , image/webp"
                  multiple={false}
                />
              ) : (
                <FormFileViewer
                  file={formData.thumbnail}
                  isVideo={false}
                  isPDF={false}
                  isImage={true}
                  onRemove={(file) => {
                    const _formData = { ...formData }
                    _formData['thumbnail'] = ''
                    setFormData(_formData)
                  }}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col lg="12" md="12" sm="12">
              <Form.Group>
                <div className={classes.tag_view}>
                  {formData.tags?.length ? (
                    formData.tags?.map((tag, index) => (
                      <div key={index?.toString()}>
                        {tag.name}
                        <button type="button" onClick={() => onRemoveTag(tag)}>
                          &times;
                        </button>
                      </div>
                    ))
                  ) : (
                    <></>
                  )}
                </div>
                <Form.Label>Tags</Form.Label>
                <div>
                  <Form.Control
                    name="Tags"
                    placeholder="Tags"
                    type="text"
                    value={tag}
                    onChange={(e) => onChangeTags(e.target.value)}
                    autoComplete="off"
                  />
                  {tag ? (
                    <div className={classes.tag_wrapper}>
                      {tags?.length ? (
                        tags?.map((tag, index) => (
                          <p
                            key={index?.toString()}
                            onClick={() => onSelectTag(tag)}>
                            {tag.name}
                          </p>
                        ))
                      ) : (
                        <p
                          className={classes.tag_add_new}
                          onClick={onClickAddTag}>
                          Add New tag
                        </p>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col lg="12" md="12" sm="12">
              <Form.Group>
                <Form.Label>Description</Form.Label>
                {/* <Form.Control
                  name="description"
                  placeholder="Description"
                  type="text"
                  rows="4"
                  as="textarea"
                  value={formData.description}
                  onChange={(e) => onChangeInput("description", e.target.value)}
                ></Form.Control> */}
                <TextEditor
                  value={editCourse.description}
                  placeholder="Course Details"
                  ref={editorRef}
                  onChange={(value) => onChangeInput('description', value)}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" variant="default" onClick={onClose}>
            Cancel
          </Button>
          <Button
            className="btn-fill pull-right"
            // type="submit"
            onClick={onSubmitForm}
            variant={!isEmpty(editCourse) ? 'info' : 'primary'}>
            {!isEmpty(editCourse) ? 'Update' : 'Create'}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
