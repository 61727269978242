import { LESSONS_ENDPOINTS } from 'config/apis'
import http from 'config/http'
import { setAppCloseableLoadingState } from 'reducers/appLoading/appLoading.action'
import { setAppLoadingState } from 'reducers/appLoading/appLoading.action'
import { setCourse } from 'reducers/course/courses.action'
import { setSelectedCourse } from 'reducers/course/courses.action'
import { setCourses } from 'reducers/course/courses.action'
import { deleteFileFromCloudinary } from 'shared/cloudinary'
import { uploadFileToCloudinary } from 'shared/cloudinary'
import { uploadFileToCloudinaryViaAPI } from 'shared/functions'
import { TOAST_TYPE } from 'shared/functions'
import { showToast } from 'shared/functions'

export const LESSON_TYPES = {
  SET_COMMENTS: '[LESSONS] SET_COMMENTS',
}

export const setComments = (payload) => {
  return (dispatch) => {
    return dispatch({
      type: LESSON_TYPES.SET_COMMENTS,
      payload,
    })
  }
}

export const addLessonToCourse = (payload) => {
  return async (dispatch, getState) => {
    try {
      // dispatch(setAppCloseableLoadingState({ status: true, closeable: true , text:'Please wait Lesson is uploading'}))
      const user = getState().auth.user

      const uploadAll = payload.videos.map(async (topic) => {

        const data = {
          file: topic?.file,
          path: 'courses'
        }
        const _file = await uploadFileToCloudinaryViaAPI(data,dispatch)

        return {
          topic: payload.videos[0]?.topic,
          description: topic.description,
          file: JSON.stringify({
            public_id: _file.public_id,
            created_at: _file.created_at,
            url: _file.url,
          }),
        }
      })

      const videos = await Promise.all(uploadAll)
      if (videos[videos.length - 1].file == '{}')
        return dispatch(
          setAppCloseableLoadingState({ status: false, closeable: false , text:''})
        )

      try {
        const reqData = {
          course_id: payload.course_id,
          videos: [...videos],
        }
        const addVideos = await http.post(
          LESSONS_ENDPOINTS.ADD_LESSONS,
          reqData
        )
        dispatch(setAppCloseableLoadingState({ status: false, closeable: false ,text:''}))

        if (!addVideos.data || addVideos?.status !== 200) {
          return showToast('Unable to process your request!', TOAST_TYPE.error)
        }

        showToast('Lesson has been added', TOAST_TYPE.success)
        dispatch(setCourses(addVideos.data.courses))
      } catch (err) {
        dispatch(setAppCloseableLoadingState({ status: false, closeable: false ,text:''}))
      }
    } catch (err) {
      dispatch(setAppCloseableLoadingState({ status: false, closeable: false  ,text:''}))
    }
  }
}

export const updateLessonToCourse = (payload) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setAppCloseableLoadingState({ status: true, closeable: true , text:'Please wait updating Lesson' }))
      const user = getState().auth.user

      const uploadAll = payload.videos.map(async (topic) => {
        if (!topic?.file?.public_id) {
          const data = {
            file: topic.file,
            path: 'lesson'
          }
          var _file = await uploadFileToCloudinaryViaAPI(data,dispatch)

          // var uploadedFile = await uploadFileToCloudinary(
          //   `courses/${user.email}/${payload.course_id}/${Date.now()}`,
          //   topic.file
          // )

          // if (uploadedFile?.name === 'Error') {
          //   showToast(uploadedFile?.message, TOAST_TYPE.error)
          // }
        }
        return {
          topic: topic.topic,
          description: topic.description,
          file: topic?.file?.public_id
            ? JSON.stringify(topic.file)
            : JSON.stringify({
                public_id: _file.public_id,
                created_at: _file.created_at,
                url: _file.url,
              }),
        }
      })
 
      const videos = await Promise.all(uploadAll) 
      if (videos[videos.length - 1].file == '{}')
        return dispatch(
          setAppCloseableLoadingState({ status: false, closeable: false , text:''})
        )

      try {
        const reqData = {
          course_id: payload.course_id,
          lesson_id: payload.lesson_id,
          videos: [...videos],
        }
        const updateVideos = await http.post(
          LESSONS_ENDPOINTS.UPDATE_LESSONS,
          reqData
        )

        dispatch(setAppCloseableLoadingState({ status: false, closeable: false ,text:''}))

        if (!updateVideos.data || updateVideos?.status !== 200) {
          return showToast('Unable to process your request!', TOAST_TYPE.error)
        }

        showToast('Lesson successfully updated', TOAST_TYPE.success)

        dispatch(setCourse(updateVideos.data))
      } catch (err) {
        dispatch(setAppCloseableLoadingState({ status: false, closeable: false ,text:''}))
      }
    } catch (err) {
      dispatch(setAppCloseableLoadingState({ status: false, closeable: false , text:''}))
    }
  }
}

export const removeLesson = (payload) => {
  return async (dispatch, getState) => {
    try {
      const course = getState().course.course
      const deletePayload = {
        data: {
          course_id: payload?.course_id,
          lesson_id: payload?.lesson_id,
        },
      }

      //delete file
      const [lesson] = course.lessons?.filter(
        (lesson) => lesson.lesson_id === payload.lesson_id
      )
      if (lesson) {
        const files = []
        files.push(JSON.parse(lesson.file))
        deleteFileFromCloudinary(files)
      }

      const deleted = await http.delete(
        LESSONS_ENDPOINTS.DELETE_LESSON,
        deletePayload
      )
      const lessons = [...course?.lessons]

      //remove lesson from redux state
      const _lessons = lessons.filter(
        (lesson) => lesson?.lesson_id !== payload?.lesson_id
      )
      const _course = {
        ...course,
        lessons: [..._lessons],
      }

      showToast('Lesson has been removed.', TOAST_TYPE.success)
      dispatch(setSelectedCourse(_course))
    } catch (err) {}
  }
}

export const reOrderLesson = (payload) => {
  return async (dispatch, getState) => {
    try {
      const course = getState().course.course

      const updated = await http.put(LESSONS_ENDPOINTS.RE_ORDER_LESSON, payload)

      if (updated.data.error) {
        return showToast('Course not found', TOAST_TYPE.error)
      }

      const lessons = [...course?.lessons]
      //re-order lessons
      const [currentLesson] = lessons.filter(
        (lesson) => lesson.lesson_id === payload.currentLessonId
      )
      const [swapableLesson] = lessons.filter(
        (lesson) => lesson.lesson_id === payload.swapableLessonId
      )

      if (!currentLesson || !swapableLesson)
        return showToast('Course not found', TOAST_TYPE.error)

      const tempCourse = { ...currentLesson }

      //find index
      const currentLessonIndex = lessons.findIndex(
        (lesson) => lesson.lesson_id === currentLesson.lesson_id
      )
      const swapableLessonIndex = lessons.findIndex(
        (lesson) => lesson.lesson_id === swapableLesson.lesson_id
      )

      if (currentLessonIndex === -1 || swapableLessonIndex === -1) {
        return showToast('Course id not found', TOAST_TYPE.error)
      }

      lessons[currentLessonIndex] = swapableLesson
      lessons[swapableLessonIndex] = tempCourse

      course.lessons = [...lessons]

      dispatch(setSelectedCourse(course))
    } catch (err) {}
  }
}

export const getLessonComments = (lesson_id) => {
  return async (dispatch) => {
    try {
      dispatch(setAppLoadingState(true))
      const comments = await http.get(
        `${LESSONS_ENDPOINTS.GET_COMMENTS}/${lesson_id}`
      )

      return dispatch(setComments(comments.data.commentList || []))
    } catch (err) {}
  }
}

export const replyToComment = (payload) => {
  return async (dispatch, getState) => {
    try {
      const commentReply = await http.post(
        LESSONS_ENDPOINTS.REPLY_COMMENT,
        payload
      )

      const oldComments = [...getState().lessons.comments]
      const [selectedComment] = oldComments.filter(
        (c) => c.comment_id === payload.comment_id
      )
      if (selectedComment) {
        //find the index
        const commentIndex = oldComments.indexOf(selectedComment)
        if (commentIndex === -1) return

        const commentReplies = [...selectedComment?.comment_replies]
        commentReplies.push({
          ...commentReply.data,
          user: getState().auth.user,
        })
        selectedComment.comment_replies = commentReplies
        oldComments[commentIndex] = selectedComment
        dispatch(setComments(oldComments))
      }
    } catch (err) {}
  }
}
