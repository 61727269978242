import React, { useEffect, useRef, useState } from 'react'
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'

import { AddCategoryForm } from 'components/AddCategoryForm/AddCategoryForm'
import { CategoriesTable } from 'components/CategoriesTable/CategoriesTable'
import { getAllCat } from 'reducers/categories/categories.action'
import { AUTH_STATE } from 'reducers/auth/getState'
import { SUPER_ADMIN } from 'shared/CONSTANTS'
import { withRouter } from 'react-router'
import { UpdateCategoryDefaultImage } from 'components/UpdateCategoryDefaultImage/UpdateCategoryForm'

export const CategoriesPage = withRouter((props) => {
  const user = useSelector(AUTH_STATE.user)
  const catModalRef = useRef(null)
  const dispatch = useDispatch()
  const [searchedItem, setsearchedItem] = useState('')
  const [togglePages, settogglePages] = useState(false)
  const [categoryModal, setCategoryModal] = useState(false)


  useEffect(() => {
    //fetch categories

    dispatch(getAllCat())
  }, [catModalRef.current])

  const showCatModal = () => {
    if (!catModalRef.current) return
    
    catModalRef.current?.showCatModal()
  }

  return (
    <>
      {categoryModal && (
        <UpdateCategoryDefaultImage
          categoryModal={categoryModal}
          setCategoryModal={setCategoryModal}
        />
      )}
      <Container fluid>
        <Row>
          <Col sm="12" lg="12" md="12">
            <Card>
              <Card.Header>
                <Row>
                  <Col sm="12" lg="7" md="7">
                    <Card.Title>
                      {togglePages ? 'Restore Categories' : 'Categories'}
                    </Card.Title>
                  </Col>
                  {user && user?.role === SUPER_ADMIN.role && (
                    <Col
                      sm="12"
                      lg="5"
                      md="5"
                      style={{ display: 'flex', justifyContent: 'end' }}>
                      <Button
                        onClick={() => setCategoryModal(!categoryModal)}
                        size="sm"
                        className="btn-fill">
                        Default Image
                      </Button>

                      {!togglePages && (
                        <Button
                          onClick={showCatModal}
                          size="sm"
                          className="btn-fill ml-2">
                          Add Category
                        </Button>
                      )}

                      <Button
                        onClick={() => settogglePages(!togglePages)}
                        size="sm"
                        className="btn-fill ml-2"
                        variant={'primary'}>
                        {togglePages ? 'Back' : 'Restore Category'}
                      </Button>
                    </Col>
                  )}
                </Row>
                <Row className="mt-2 d-flex justify-content-end">
                  <Col sm="12" lg="3" md="2">
                    <Form.Control
                      name="name"
                      placeholder="Enter Title to Search Categories"
                      type="text"
                      onChange={(e) => setsearchedItem(e.target.value)}
                      // value={formData.fname}
                      required></Form.Control>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <CategoriesTable
                  restorePage={togglePages}
                  searchedItem={searchedItem}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <AddCategoryForm ref={catModalRef} modal={categoryModal} onModalState={()=> setCategoryModal(!categoryModal)}/>
    </>
  )
})
