import { TAGS_ACTION_TYPES } from "./tags.actions";

const INITIAL_STATE = {
  tags: [],
};

const tagsReducer = (state = INITIAL_STATE, actions) => {
  switch (actions.type) {
    case TAGS_ACTION_TYPES.TAG_LIST:
      return {
        ...state,
        tags: [...actions.payload],
      };
    default:
      return state;
  }
};

export default tagsReducer;
