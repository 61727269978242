import { QUIZ_ENDPOINTS } from "config/apis";
import http from "config/http";
import { setAppLoadingState } from "reducers/appLoading/appLoading.action";
import { showToast } from "shared/functions";
import { TOAST_TYPE } from "shared/functions";

export const QUIZ_ACTION_TYPES = {
  SET_QUIZ: "[QUIZ] SET_QUIZ",
  DELETE_QUIZ: "[QUIZ] DELETE_QUIZ",
};

export const setQuiz = (payload) => {
  return (dispatch) => {
    dispatch({
      type: QUIZ_ACTION_TYPES.SET_QUIZ,
      payload,
    });
  };
};

export const getQuiz = (payload) => {
  return async (dispatch) => {
    try {
      //lesson id is only used in case i need a lesson quiz. otherwise course_id is mendatory.
      dispatch(setAppLoadingState(true));
      const quiz = await http.get(
        `${QUIZ_ENDPOINTS.GET_QUIZ}/${payload.course_id}/${payload.lesson_id}`
      );
      dispatch(setAppLoadingState(false));
      return dispatch(setQuiz(quiz.data));
    } catch (err) {
      console.log(err);
    }
  };
};

export const createQuiz = (payload) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setAppLoadingState(true));
      const newQuiz = await http.post(QUIZ_ENDPOINTS.ADD_QUIZ, payload);
      dispatch(setAppLoadingState(false));
      showToast('Quiz successfully created', TOAST_TYPE.success)
      return dispatch(setQuiz(newQuiz.data));
    } catch (err) {
      console.log(err);
    }
  };
};

export const deleteQuiz = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(setAppLoadingState(true));
      const deletedQuiz = await http.delete(QUIZ_ENDPOINTS.DELETE_QUIZ, {
        data: {
          ...payload,
        },
      });

      dispatch(setAppLoadingState(false));
      return dispatch(setQuiz({}));
    } catch (err) {}
  };
};
