/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect } from "react";
import { useLocation, Switch, withRouter } from "react-router-dom";

import AdminNavbar from "components/Navbars/AdminNavbar";
import Sidebar from "components/Sidebar/Sidebar";

import useRoutes from "../routes";

import { ProtectedRoute } from "../ProtectedRoute";
import { useDispatch, useSelector } from "react-redux";
// import { HOC } from "./HOC";
import { CourseDetail } from "views/CourseDetail/CourseDetail";
import { AUTH_STATE } from "reducers/auth/getState";
import { isEmpty } from "lodash";
import { getSessionUserDetails } from "reducers/auth/auth.actions";
import { isAuthenticated } from "shared/Auth";
import { redirectTo } from "shared/functions";
import { GLOBAL_STATE } from "reducers/appLoading/getAppState";
import { HOC } from "./HOC";
import Quizes from "views/Quizes/Quizes";
import { ViewQuestions } from "views/KnowledgeJourney/pages/ViewQuestions";
import ViewAllQuestions from "views/AskASheikh/ViewAllQuestions";
import AskASheikhQuestion from "views/AskASheikh/AskASheikhQuestion";

function Admin(appProps) {
  const user = useSelector(AUTH_STATE.user);
  const routes = useSelector(GLOBAL_STATE.routes);
  const dispatch = useDispatch();

  const logout = useSelector(AUTH_STATE.logout);

  const checkBackSession = async () => {
    if (isEmpty(user)) await dispatch(getSessionUserDetails());

    if (!isAuthenticated())
      return redirectTo(appProps, { path: "/admin/login" });

    useRoutes(user);
  };

  useEffect(() => {
    checkBackSession();
  }, [user, logout]);

  const location = useLocation();
  const mainPanel = React.useRef(null);

  const getRoutes = () => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <ProtectedRoute
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            {...appProps}
          />
        );
      } else {
        return null;
      }
    });
  };

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      element.parentNode.removeChild(element);
    }
  }, [location]);

  return (
    <>
      <div className="wrapper">
        <Sidebar color={"black"} image={""} routes={routes} />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar />
          <div className="content">
            <Switch>
              <ProtectedRoute
                path="/admin/courses/course"
                component={CourseDetail}
                {...appProps}
              />
              <ProtectedRoute
                path="/admin/courses/quizes"
                component={Quizes}
                {...appProps}
              />
              <ProtectedRoute
                path="/admin/knowledge-journey/view-questions"
                component={ViewQuestions}
                {...appProps}
              />
              <ProtectedRoute
                path="/admin/answer-a-questions/view-all-questions"
                component={ViewAllQuestions}
                {...appProps}
              />
              <ProtectedRoute
                path="/admin/answer-a-questions/ask-shiekh-question"
                component={AskASheikhQuestion}
                {...appProps}
              />
              {getRoutes(routes)}
            </Switch>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(Admin);
