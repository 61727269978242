import React, { useState } from "react";
import {
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  Modal,
  Row,
} from "react-bootstrap";

import classes from "./style.module.css";
import { useDispatch, useSelector } from "react-redux";
import { createAssessment } from "reducers/knowledgeJourney/knowledgeJourney.action";
import { CATEGORY_STATE } from "reducers/categories/getState";
import { showToast } from "shared/functions";
import { TOAST_TYPE } from "shared/functions";

export const CreateAssessment = ({ show = false, onClose = () => {} }) => {
  const [formData, setFormData] = useState({
    title: "",
    points: 30,
    level: 1,
    category_id: 0,
  });

  const topics = useSelector(CATEGORY_STATE.categories);
  const dispatch = useDispatch();

  const resetForm = () => {
    setFormData({});
  };

  const onChangeInput = (key, value) => {
    const _formData = { ...formData };
    _formData[key] = value;

    setFormData(_formData);
  };

  const onFormClose = () => {
    resetForm();
    onClose();
  };

  const onSubmit = (e) => {
    e.preventDefault();

    for(var i = 0 ; i < formData.length ; i++)
    {
      if (formData[i]?.title === '') {
        return showToast('Title is required', TOAST_TYPE.error)
      }
      if (formData[i]?.points === '') {
        return showToast('Assessment Points is required', TOAST_TYPE.error)
      }
      if (formData[i]?.level === '') {
        return showToast('Difficulty Level is required', TOAST_TYPE.error)
      }
      if (formData[i]?.category_id === '') {
        return showToast('Category is required', TOAST_TYPE.error)
      }
    }

    dispatch(createAssessment(formData));
    onFormClose();
  };

  return (
    <Modal
      show={show}
      onHide={onFormClose}
      size="lg"
      animation={false}
      backdrop="static"
    >
      <Form onSubmit={onSubmit}>
        <Modal.Header style={{ display: "flex", alignItems: "center" }}>
          <Modal.Title>Create Assessment</Modal.Title>
        </Modal.Header>
        <Modal.Body className={classes.modal_body}>
          <Row>
            <Col sm="12" md="12" lg="12">
              <FormGroup>
                <Form.Label>Assessment Title *</Form.Label>
                <FormControl
                  type="text"
                  placeholder="Assessment Title *"
                  value={formData.title}
                  onChange={(e) => onChangeInput("title", e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row style={{ marginTop: "10px" }}>
            <Col sm="12" md="6" lg="6">
              <FormGroup>
                <Form.Label>Assessment Points *</Form.Label>
                <FormControl
                  type="number"
                  min="30"
                  placeholder="Assessment Points *"
                  value={formData.points}
                  onChange={(e) => onChangeInput("points", e.target.value)}
                />
              </FormGroup>
            </Col>

            <Col sm="12" md="6" lg="6">
              <FormGroup>
                <Form.Label>Difficulty Level *</Form.Label>
                <FormControl
                  type="number"
                  min="1"
                  max="16"
                  placeholder="Difficulty Level *"
                  value={formData.level}
                  onChange={(e) => onChangeInput("level", e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col className={classes.separate} sm="12" md="12">
              <Form.Label>Topic *</Form.Label>
              <select
                className={classes.select}
                value={formData.category_id}
                onChange={(e) => onChangeInput("category_id", e.target.value)}
              >
                <option value="">Select Topic</option>
                {topics.map((topic, index) => {
                  if (topic.title.toLowerCase() !== "uncategorize")
                    return (
                      <option value={topic.category_id} key={index.toString()}>
                        {topic.title}
                      </option>
                    );
                })}
              </select>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button
            size="sm"
            type="button"
            variant="default"
            onClick={onFormClose}
          >
            Cancel
          </Button>
          <Button
            className="btn-fill pull-right"
            type="submit"
            variant="primary"
            size="sm"
          >
            Submit Questions
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
