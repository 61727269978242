import React, { useEffect } from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import AdminLayout from 'layouts/Admin.js'
import { Login } from 'views/Auth/Login'
import { Register } from 'views/Auth/Register'
import history from 'config/history'

export const App = (props) => {

  return (
    <BrowserRouter history={history}>
      <Switch>
        <Route path="/admin/login" component={Login} />
        <Route path="/admin/signup" component={Register} />
        <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
        <Redirect from="/" to="/admin/dashboard" />
      </Switch>
    </BrowserRouter>
  )
}
