import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { Viewer } from '@react-pdf-viewer/core'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'

import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'

import classes from './VideoModal.module.css'
import { getFile } from 'shared/functions'

export const WatchLessonModal = ({
  visible = false,
  onClose = () => {},
  file = '',
}) => {
  const [localFile, setFile] = useState({
    file: '',
    ext: '',
  })
  const [FileSrc, setFileSrc] = useState()
  const defaultLayoutPluginInstance = defaultLayoutPlugin()

  useEffect(() => {
    if (!file) return
    let files = []
    const _file = JSON.parse(file)

    _file.filter((item) => {
      files.push(getFile(item.url))
    })
    setFile(files)
    setFileSrc(files[0].file)
  }, [file])

  if (!visible) return <></>

  return (
    <Modal
      show={visible}
      size="lg"
      animation={false}
      onHide={onClose}
      backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Watching Lesson</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {localFile.ext === 'pdf' ? (
          <Viewer
            fileUrl={localFile.file}
            plugins={[defaultLayoutPluginInstance]}
          />
        ) : (
          <div style={{ marginTop: '-40px' }}>
            <div style={{ fontSize: '16px' }}>Click on video to play</div>
            <Row xs={1} sm={1} md={2} lg={6} xl={7}>
              {localFile.map((item, index) => (
                <Col key={index} 
                
                onClick={() => setFileSrc(item.file)}>
                  <div
                    style={
                      FileSrc === item.file
                        ? {
                            cursor: 'pointer',
                            backgroundColor: 'gray',
                            color: 'white',
                            textAlign: 'center',
                            borderRadius: '5px',
                            border: '1px solid gray',
                            width: '100%',
                          }
                        : {
                            cursor: 'pointer',
                            backgroundColor: 'white',
                            color: 'gray',
                            textAlign: 'center',
                            borderRadius: '5px',
                            border: '1px solid gray',
                            width: '100%',                            
                          }
                    }>
                    Video {index + 1}
                  </div>
                </Col>
              ))}
            </Row>
            <video
              style={{ marginTop: '10px' }}
              src={FileSrc}
              controls
              className={classes.video}
            />
          </div>
        )}
      </Modal.Body>
    </Modal>
  )
}
