import { CATEGORY_ACTION_TYPES } from "./categories.action";

const INITIAL_STATE = {
  categories: [],
};

const CategoryReducer = (state = INITIAL_STATE, actions) => {
  switch (actions.type) {
    case CATEGORY_ACTION_TYPES.FETCH_CATEGORIES:
      return {
        ...state,
        categories: [...actions.payload],
      };

    case CATEGORY_ACTION_TYPES.CREATE_CATEGORY:
      return {
        ...state,
        categories: [...actions.payload],
      };

    case CATEGORY_ACTION_TYPES.UPDATE_CATEGORY:
      return {
        ...state,
        categories: [...actions.payload],
      };

    case CATEGORY_ACTION_TYPES.DELETE_CATEGORY:
      return {
        ...state,
        categories: [...actions.payload],
      };

    default:
      return state;
  }
};

export default CategoryReducer;
