import { APP_LOADING_ACTIONS_TYPES } from "./appLoading.action";

const INITIAL_STATE = {
  app_loading: false,
  closeable: false,
  text: '',
  url: '',
  routes: []
};

const AppLoadingReducer = (state = INITIAL_STATE, actions) => {

  switch (actions.type) {
    case APP_LOADING_ACTIONS_TYPES.APP_LOADING:
      return {
        ...state,
        app_loading: actions.payload
      };

    case APP_LOADING_ACTIONS_TYPES.APP_ROUTES:
      return {
        ...state,
        routes: [...actions.payload]
      };

    case APP_LOADING_ACTIONS_TYPES.APP_COURSE_LOADED:
      return {
        ...state,
        app_loading: actions.payload.status,
        text: actions.payload.text,
        closeable: actions.payload.closeable,
        url: actions.payload.url,
      };

    default:
      return state;
  }
};

export default AppLoadingReducer;
