import React, { useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'

import { FileSelectionInput } from 'components/AddLessonsForm/FileSelectionInput.js'
import { FormFileViewer } from 'components/AddLessonsForm/FormFileViewer'

import { showToast } from 'shared/functions'
import { TOAST_TYPE } from 'shared/functions'
import http from 'config/http'
import { CATEGORY_ENDPOINTS } from 'config/apis'

import { uploadFileToCloudinaryViaAPI } from 'shared/functions'
import { setAppCloseableLoadingState } from 'reducers/appLoading/appLoading.action'
import { useEffect } from 'react'

export const UpdateCategoryDefaultImage = ({
  categoryModal,
  setCategoryModal,
}) => {
  const [formData, setFormData] = useState({
    title: '',
    file: '',
    filePath: '',
  })

  const dispatch = useDispatch()

  useEffect(() => {
    onGetCategoryDefaultImage()
  }, [])

  const onSubmitForm = async (e) => {
    e.preventDefault()
    onSaveTheData()
  }

  const onSaveTheData = async () => {
    if (formData.file === '')
      return showToast('Please upload the video', TOAST_TYPE.error)
    dispatch(
      setAppCloseableLoadingState({
        status: true,
        closeable: true,
        text: 'Please wait updating Default Image',
      })
    )
    const data = {
      file: formData?.file,
      path: 'default image',
    }
    const _file = await uploadFileToCloudinaryViaAPI(data, dispatch)

    var reqBody = {
      title: 'default image',
      file: _file.url,
      filePath: _file.public_id,
    }

    try {
      await http.put(CATEGORY_ENDPOINTS?.UPDATE_DEFAULT_IMAGE, reqBody)

      dispatch(
        setAppCloseableLoadingState({
          status: false,
          closeable: false,
          text: '',
        })
      )
      showToast('Image successfully updated.', TOAST_TYPE.success)
    } catch (error) {
      console.log(error)
    }
  }

  const onGetCategoryDefaultImage = async () => {
    const result = await http.get(CATEGORY_ENDPOINTS?.GET_DEFAULT_IMAGE, '')
    console.log(result?.data?.defaultImage, 'RESULT')
    if (result) {
      const clone = { ...formData }
      clone.file = result?.data?.defaultImage?.file
      setFormData(clone)
    }
  }

  return (
    <Modal
      show={categoryModal}
      onHide={() => setCategoryModal(!categoryModal)}
      size="lg"
      animation={false}
      backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Default Image</Modal.Title>
      </Modal.Header>
      <Form onSubmit={onSubmitForm}>
        <Modal.Body>
          <Row style={{ marginTop: 12 }}>
            <Col sm="6" md="3">
              {!formData.file ? (
                <FileSelectionInput
                  onFilesSelected={(file) => {
                    const _formData = { ...formData }
                    _formData['file'] = file[0]
                    setFormData(_formData)
                  }}
                  accept="image/*"
                  title="Select Image"
                  multiple={false}
                />
              ) : (
                <FormFileViewer
                  file={formData.file}
                  isPDF={false}
                  isVideo={false}
                  onRemove={(file) => {
                    const _formData = { ...formData }
                    _formData['file'] = ''
                    setFormData(_formData)
                  }}
                />
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="button"
            variant="default"
            onClick={() => setCategoryModal(!categoryModal)}>
            Cancel
          </Button>
          <Button
            className="btn-fill pull-right"
            type="submit"
            variant="primary">
            Submit
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
