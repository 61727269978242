import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Accordion from "react-bootstrap/Accordion";
import { useSelector, useDispatch } from "react-redux";

import classes from "./CourseDetailTable.module.css";
import { CustomOffCanvas } from "components/CustomOffCanvas/CustomOffCanvas";
import { ConversationCard } from "components/ConversationCard/ConversationCard";
import { Button, Form } from "react-bootstrap";
import { LESSONS_STATE } from "reducers/videos/getState";
import { AUTH_STATE } from "reducers/auth/getState";
import { QuestionCard } from "./QuestionCard";
import { replyToComment } from "reducers/videos/videos.action";

export const Questions = (props) => {
  const currentUser = useSelector(AUTH_STATE.user);
  const comments = useSelector(LESSONS_STATE.comments);
  const [selectedComment, setSelectedComment] = useState({});
  const [showQuestionDetail, setQuestionDetailState] = useState(false);
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const scrollRef = useRef();

  useEffect(() => {
    scrollToBottom();
  }, [scrollRef.current]);

  const onQuestionSelect = (question) => {
    setSelectedComment(question);
    setQuestionDetailState(true);
  };

  const onCloseQuestionDetail = () => {
    setQuestionDetailState(false);
  };

  const onChangeInput = (value) => {
    setMessage(value);
  };

  const onReply = async (e) => {
    e.preventDefault();
    const payload = {
      message: message,
      lesson_id: props?.lessonID,
      comment_id: selectedComment?.comment_id,
    };

    dispatch(replyToComment(payload));
    setMessage("");
    scrollToBottom();
  };

  const scrollToBottom = () => {
    if (!scrollRef.current) return;

    scrollRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <CustomOffCanvas
        show={props?.visible}
        onClose={props?.onClose}
        title={props?.category?.title}
      >
        <div className={classes.question_wrapper}>
          <Accordion>
            {comments?.map((comment, index) => (
              <QuestionCard
                question={comment}
                onQuestionSelect={onQuestionSelect}
                key={index?.toString()}
              />
            ))}
          </Accordion>
        </div>
      </CustomOffCanvas>
      <CustomOffCanvas
        show={showQuestionDetail}
        onClose={onCloseQuestionDetail}
        title={selectedComment?.message}
      >
        <div className={classes.coversation_wrapper}>
          <>
            {selectedComment && selectedComment?.comment_replies?.length ? (
              <>
                {selectedComment?.comment_replies?.map((item, index) => (
                  <ConversationCard
                    highlight={item?.user?.user_id === currentUser?.user_id}
                    item={item}
                    key={index?.toString()}
                    user={item?.user}
                    item={item}
                  />
                ))}
                <div ref={scrollRef} />
              </>
            ) : (
              <p>No Conversation!</p>
            )}
          </>

          <div className={classes.conversation_form}>
            <Form onSubmit={onReply}>
              <Form.Group>
                <Form.Control
                  value={message}
                  placeholder="Enter your message here"
                  as="textarea"
                  required
                  onChange={(e) => onChangeInput(e.target.value)}
                />
              </Form.Group>

              <div className={classes.form_btn}>
                <Button size="sm" className={`btn-fill`} type="submit">
                  Reply
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </CustomOffCanvas>
    </>
  );
};

Questions.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};
