import React, { useState } from "react";
import { Col, Form, Row, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { AUTH_STATE } from "reducers/auth/getState";
import { GLOBAL_STATE } from "reducers/appLoading/getAppState";

export const LoginForm = (props) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const appLoading = useSelector(GLOBAL_STATE.loading);

  const { onSubmit = (formData) => {} } = props || {};

  const onChangeInput = (key, value) => {
    const _formData = { ...formData };

    _formData[key] = value;

    setFormData(_formData);
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <Form onSubmit={onSubmitForm}>
      <Row>
        <Col className="pl-1" md="12" lg="12" sm="12">
          <Form.Group>
            <label>Email address *</label>
            <Form.Control
              name="email"
              placeholder="Email"
              type="email"
              onChange={(e) => onChangeInput("email", e.target.value)}
              value={formData.email}
            ></Form.Control>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col className="pl-1" md="12" lg="12" sm="12">
          <Form.Group>
            <label>Password *</label>
            <Form.Control
              name="password"
              placeholder="Password"
              type="password"
              onChange={(e) => onChangeInput("password", e.target.value)}
              value={formData.password}
            ></Form.Control>
          </Form.Group>
        </Col>
      </Row>

      <Button
        className="btn-fill pull-right"
        type="submit"
        variant="primary"
        block
        disabled={appLoading || !formData.password || !formData.email}
      >
        Login
      </Button>
      <div className="clearfix"></div>
    </Form>
  );
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func,
};
