import { isEmpty } from 'lodash'
import React from 'react'
import { Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'

import { QUIZ_STATE } from 'reducers/quiz/getState'
import { TableRow } from './TableRow'
import { deleteQuestion } from 'reducers/questions/question.actions'
import { OnDeleteItem } from 'components/DeleteConfirmation'

export const QuizTable = ({ togglePages }) => {
  const quiz = useSelector(QUIZ_STATE.quiz)
  const dispatch = useDispatch()

  const onDeleteQuestion = async (question_id) => {
    OnDeleteItem({
      onApplySuccessFunction: () =>
        dispatch(deleteQuestion(question_id, togglePages ? 1 : 0)),
      title: 'Question',
      restore: false,
    })
  }

  const onRestoreQuestion = async (question_id) => {
    OnDeleteItem({
      onApplySuccessFunction: () => dispatch(deleteQuestion(question_id, 0)),
      title: 'Question',
      restore: togglePages,
    })
  }

  return (
    <>
      {isEmpty(quiz) ? (
        <p>Please add quiz</p>
      ) : !quiz?.questions?.length ? (
        <p>No Question added</p>
      ) : (
        <>
          {togglePages ? (
            <Table className="table-hover table-striped">
              <thead>
                <tr>
                  <td>#</td>
                  <td>Title</td>
                  <td>Description</td>
                  <td>Type</td>
                  <td>Restore/Delete</td>
                </tr>
              </thead>
              <tbody>
                {quiz?.questions?.length &&
                  quiz?.questions?.map(
                    (q, index) =>
                      q.deleted === 1 && (
                        <TableRow
                          question={q}
                          index={index}
                          togglePages={togglePages}
                          onDelete={() => onDeleteQuestion(q?.question_id)}
                          onRestore={() => onRestoreQuestion(q?.question_id)}
                          key={index?.toString()}
                        />
                      )
                  )}
              </tbody>
            </Table>
          ) : (
            <Table className="table-hover table-striped">
              <thead>
                <tr>
                  <td>#</td>
                  <td>Title</td>
                  <td>Description</td>
                  <td>Type</td>
                  <td>Edit / Delete</td>
                </tr>
              </thead>
              <tbody>
                {quiz?.questions?.length &&
                  quiz?.questions?.map(
                    (q, index) =>
                      q.deleted === 0 && (
                        <TableRow
                          question={q}
                          index={index}
                          onDelete={() => onDeleteQuestion(q?.question_id)}
                          key={index?.toString()}
                        />
                      )
                  )}
              </tbody>
            </Table>
          )}
        </>
      )}
    </>
  )
}
