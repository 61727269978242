import { LESSONS_ENDPOINTS } from 'config/apis'
import { COURSES_ENDPOINTS } from 'config/apis'
import http from 'config/http'
import { setAppCloseableLoadingState } from 'reducers/appLoading/appLoading.action'
import { setAppLoadingState } from 'reducers/appLoading/appLoading.action'
import { deleteFileFromCloudinary } from 'shared/cloudinary'
import { uploadFileToCloudinary } from 'shared/cloudinary'
import { deleteCompleteDirectoryFromCloudinary } from 'shared/cloudinary'
import { uploadFileToCloudinaryViaAPI } from 'shared/functions'
import { showToast } from 'shared/functions'
import { TOAST_TYPE } from 'shared/functions'

export const COURSES_ACTIONS_TYPES = {
  FETCH_COURSES: '[courses] FETCH_ALL_COURSES',
  SET_SELECTED_COURSE: '[courses] SET_SELECTED_COURSE',
  COURSES_CREATE: '[courses] COURSE_CREATE',
  UPDATE_SELECTED_COURSE: 'UPDATE_COURSE',
  SET_COURSES: '[courses] SET_COURSES',
}

//fetch all coureses
export const getCourses = () => {
  return async (dispatch) => {
    try {
      dispatch(
        setAppCloseableLoadingState({
          status: true,
          closeable: false,
          text: '',
        })
      )
      const courses = await http.get(COURSES_ENDPOINTS.ALL_COURSES)
      dispatch(setAppLoadingState(false))

      if (courses.status !== 200 || !courses.data) {
        return showToast('Unable to get courses', TOAST_TYPE.error)
      }

      return dispatch({
        type: COURSES_ACTIONS_TYPES.FETCH_COURSES,
        payload: courses.data.courses,
      })
    } catch (err) {
      return dispatch(setAppLoadingState(false))
    }
  }
}

//fetch course by id
export const getCourse = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(setAppLoadingState(true))
      const course = await http.get(
        `${COURSES_ENDPOINTS.GET_COURSE}/${payload.course_id}`
      )
      dispatch(setAppLoadingState(false))

      return dispatch({
        type: COURSES_ACTIONS_TYPES.SET_SELECTED_COURSE,
        payload: course.data,
      })
    } catch (err) {}
  }
}

export const setCourse = (payload) => ({
  type: COURSES_ACTIONS_TYPES.UPDATE_SELECTED_COURSE,
  payload,
})

//create new course
export const onCreateCourse = (payload) => {
  return async (dispatch, getState) => {
    try {
      if (!payload) {
        return showToast('All fields are required!', TOAST_TYPE.error)
      }

      const {
        title,
        category_ids,
        language,
        thumbnail,
        institute_name,
        tags,
        description,
        level,
      } = payload
      if (!title) {
        return showToast('Please enter course title', TOAST_TYPE.error)
      } else if (!category_ids) {
        return showToast('Please select atleast 1 category', TOAST_TYPE.error)
      } else if (!language) {
        return showToast('Please enter course language', TOAST_TYPE.error)
      } else if (!thumbnail) {
        return showToast('Please add thumbnail', TOAST_TYPE.error)
      }
      // else if (!tags?.length) {
      //   return showToast("Please enter atleast 1 tag", TOAST_TYPE.error);
      // }

      const user = getState().auth.user

      dispatch(
        setAppCloseableLoadingState({
          status: true,
          closeable: true,
          text: 'Please wait creating Course',
        })
      )
      // const fileName = `${thumbnail.name}`.replace(' ', '')

      const data = {
        file: payload?.thumbnail,
        path: 'course',
      }
      const _file = await uploadFileToCloudinaryViaAPI(data, dispatch)

      // const _thumbnail = await uploadFileToCloudinary(
      //   `courses/${user.email}/${new Date().toISOString() + fileName}`,
      //   thumbnail
      // )

      const tag_ids = []
      for (let i = 0; i < payload.tags?.length; i++) {
        tag_ids.push(payload.tags[i].tag_id)
      }

      const createData = {
        category_ids: category_ids,
        description: description,
        language: language,
        institute_name: institute_name,
        level: level,
        thumbnail: JSON.stringify({
          public_id: _file.public_id,
          url: _file.url,
        }),
        title: title,
        tag_ids: [...tag_ids],
      }

      const newCourse = http
        .post(COURSES_ENDPOINTS.CREATE_COURSE, createData)
        .then((res) => {
          if (res?.status !== 200 || !res?.data) {
            return showToast(
              'Unable to process your request!',
              TOAST_TYPE.error
            )
          }
          showToast('Course successfully created', TOAST_TYPE.success)
          dispatch(
            setAppCloseableLoadingState({ status: false, closeable: false })
          )
          return dispatch({
            type: COURSES_ACTIONS_TYPES.COURSES_CREATE,
            payload: res.data.data.courses,
          })
        })
    } catch (err) {
      console.log(err)
      return dispatch(
        setAppCloseableLoadingState({ status: false, closeable: false })
      )
    }
  }
}

export const setSelectedCourse = (payload) => {
  return (dispatch) => {
    return dispatch({
      type: COURSES_ACTIONS_TYPES.SET_SELECTED_COURSE,
      payload,
    })
  }
}

export const setCourses = (payload) => {
  return (dispatch) => {
    return dispatch({
      type: COURSES_ACTIONS_TYPES.SET_COURSES,
      payload,
    })
  }
}

export const publishCourse = (payload) => {
  return async (dispatch) => {
    try {
      const reqBody = {
        course_id: payload,
      }

      dispatch(setAppLoadingState(true))
      const publishedCourses = await http.put(
        COURSES_ENDPOINTS.PUBLISH_COURSE,
        reqBody
      )
      dispatch(
        setAppCloseableLoadingState({
          status: false,
          closeable: false,
          text: '',
        })
      )

      showToast('Course has been published', TOAST_TYPE.success)
      return dispatch(setCourses(publishedCourses.data.courses))
    } catch (err) {
      dispatch(
        setAppCloseableLoadingState({
          status: false,
          closeable: false,
          text: '',
        })
      )
    }
  }
}

export const deleteCourse = (course_id, deletePermanently, restore) => {
  return async (dispatch, getState) => {
    try {
      const reqBody = {
        data: {
          course_id: course_id,
          deletePermanently: deletePermanently,
        },
      }

      dispatch(
        setAppCloseableLoadingState({
          status: true,
          closeable: false,
          text: 'Please wait deleting Course',
        })
      )
      const deletedCourses = await http.delete(
        COURSES_ENDPOINTS.DELETE_COURSE,
        reqBody
      )
      if (deletePermanently === 1) {
        const user = getState().auth.user
        const [course] = getState().course.courses?.filter(
          (course) => course.course_id === course_id
        )
        if (course) {
          //delete the thumbnail
          deleteFileFromCloudinary([JSON.parse(course.thumbnail)])
        }
        //delete the folder
        deleteCompleteDirectoryFromCloudinary(
          `courses/${user.email}/${course_id}`
        )
        showToast('Course has been Permanently deleted', TOAST_TYPE.success)
        dispatch(setCourses(deletedCourses.data.courses))
      } else {
        showToast(
          `Course has been ${restore ? 'restored' : 'deleted'}`,
          TOAST_TYPE.success
        )
        dispatch(setCourses(deletedCourses.data.courses))
      }
    } catch (err) {
      if (err.response) {
        return showToast(err.response.data.message, TOAST_TYPE.error)
      }
    }
  }
}

export const submitCourse = (payload) => {
  return async (dispatch) => {
    try {
      const reqBody = {
        course_id: payload,
      }
      dispatch(
        setAppCloseableLoadingState({
          status: true,
          closeable: false,
          text: 'Please wait creating course',
        })
      )
      const submittedCourse = await http.put(
        COURSES_ENDPOINTS.SUBMIT_COURSE,
        reqBody
      )
      dispatch(setAppLoadingState(false))

      showToast('Course has been submitted', TOAST_TYPE.success)
      return dispatch(setCourses(submittedCourse.data.courses))
    } catch (err) {
      dispatch(setAppLoadingState(false))
    }
  }
}

export const updateCourse = (payload) => {
  return async (dispatch, getState) => {
    try {
      const courses = [...getState().course.courses]

      const tag_ids = []
      for (let i = 0; i < payload.tags?.length; i++) {
        tag_ids.push(payload.tags[i].tag_id)
      }

      const clone = { ...payload }

      clone.tags_id = tag_ids

      dispatch(
        setAppCloseableLoadingState({
          status: true,
          closeable: false,
          text: 'Please wait updating course',
        })
      )
      const updatedCourse = await http.put(
        COURSES_ENDPOINTS.UPDATE_COURSE,
        clone
      )

      dispatch(setAppLoadingState(false))
      const _courses = [...courses]

      const courseIndex = courses.findIndex(
        (course) => parseInt(course.course_id) === parseInt(payload?.course_id)
      )
      if (courseIndex === -1) return

      _courses[courseIndex] = {
        ...courses[courseIndex],
        ...updatedCourse.data,
      }
      // courses[courseIndex] = {
      //   ...courses[courseIndex],
      //   ...updatedCourse.data
      // };

      showToast('Course has been updated', TOAST_TYPE.success)
      dispatch(setCourses(_courses))
    } catch (err) {}
  }
}
