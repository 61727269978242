import { CATEGORY_ENDPOINTS } from 'config/apis'
import http from 'config/http'
import { setAppCloseableLoadingState } from 'reducers/appLoading/appLoading.action'
import { TOAST_TYPE } from 'shared/functions'
import { uploadFileToCloudinaryViaAPI } from 'shared/functions'
import { showToast } from 'shared/functions'
import { errorMessage } from 'shared/functions'

export const CATEGORY_ACTION_TYPES = {
  CREATE_CATEGORY: '[category] CREATE_CATEGORY',
  FETCH_CATEGORIES: '[category] FETCH_CATEGORIES',
  DELETE_CATEGORY: '[category] DELETE_CATEGORY',
  UPDATE_CATEGORY: '[category] UPDATE_CATEGORY',
}

//get all categories
export const getAllCat = () => {
  return async (dispatch) => {
    try {
      dispatch(
        setAppCloseableLoadingState({ status: true, closeable: false , text:''})
      )
      const categories = await http.get(CATEGORY_ENDPOINTS.FETCH_ALL)

      dispatch(
          setAppCloseableLoadingState({ status: false, closeable: false , text:''})
        )
      if (categories.status !== 200 || !categories.data) {
        return showToast('Error to load categories', TOAST_TYPE.error)
      }

      return dispatch({
        type: CATEGORY_ACTION_TYPES.FETCH_CATEGORIES,
        payload: [...categories.data.categories],
      })
    } catch (err) {
      return dispatch(
          setAppCloseableLoadingState({ status: false, closeable: false , text:''})
        )
    }
  }
}

//create new category
export const createCategory = (payload) => {
  return async (dispatch) => {

    try {
      const { title, desc = '', icon = '' } = payload

      if (!title) {
        return errorMessage('Please enter category title')
      }
      dispatch(
        setAppCloseableLoadingState({ status: true, closeable: true , text:'Please wait creating category ...'})
      )

      if (icon != '') {
        const data = {
          file: icon,
          path: 'category',
        }
        const _file = await uploadFileToCloudinaryViaAPI(data,dispatch)

        var _payload = {
          ...payload,
          icon: JSON.stringify({
            public_id: _file.public_id,
            url: _file.url,
          }),
        }
      }

      try {
        const newCat = await http.post(
          CATEGORY_ENDPOINTS.CREATE,
          icon != '' ? _payload : payload
        )
        dispatch(
          setAppCloseableLoadingState({ status: false, closeable: false , text:''})
        )
  
        if (newCat.status !== 200 || !newCat.data) {
          return showToast('Unable to process your request!', TOAST_TYPE.error)
        }

        showToast('Category created!', TOAST_TYPE.success)
        // console.log(newCat.data?.data?.categories)
        const categories = await http.get(CATEGORY_ENDPOINTS.FETCH_ALL)

        return dispatch({
          type: CATEGORY_ACTION_TYPES.CREATE_CATEGORY,
          payload: [...categories?.data?.categories],
        })
      } catch (error) {
        return showToast(error.response.data.message, TOAST_TYPE.error)
      }
    } catch (err) {
      return         dispatch(
        setAppCloseableLoadingState({ status: false, closeable: false , text:''})
      )
    }
  }
}

//update category
export const updateCategory = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(
        setAppCloseableLoadingState({ status: true, closeable: false , text:'Please wait updating category ...'})
      )

      if (typeof payload.icon == 'string' || payload.icon === '' || payload.icon === null) {
        var reqBody = {
          ...payload,
        }
        
      } else {
        const data = {
          file: payload?.icon,
          path: 'category',
        }
        const _file = await uploadFileToCloudinaryViaAPI(data,dispatch)
        //send request
        var reqBody = {
          ...payload,
          icon: JSON.stringify({
            public_id: _file.public_id,
            url: _file.url,
          }),
        }
      }


      const updated = await http.put(
        CATEGORY_ENDPOINTS.UPDATE,
        reqBody
      )

      dispatch(
          setAppCloseableLoadingState({ status: false, closeable: false , text:''})
        )
      if (updated.status !== 200 || !updated.data) {
        return showToast('Unable to process your request!', TOAST_TYPE.error)
      }

      showToast('Category Updated!', TOAST_TYPE.success)
      return dispatch({
        type: CATEGORY_ACTION_TYPES.UPDATE_CATEGORY,
        payload: [...updated.data?.data?.categories],
      })
    } catch (err) {
      return dispatch(
          setAppCloseableLoadingState({ status: false, closeable: false , text:''})
        )
    }
  }
}

//delete category
export const deleteCategory = (payload,restore) => {
  return async (dispatch) => {
    try {
      const { category_id ,deletePermanently} = payload

      if (!category_id) {
        return errorMessage('Please select category')
      }

      const deletedPayload = {
        data: payload,
      }
      dispatch(
        setAppCloseableLoadingState({ status: true, closeable: false , text:'Please wait removing category ...'})
      )

      //send request
      const deleted = await http.delete(
        CATEGORY_ENDPOINTS.DELETE,
        deletedPayload
      )

      dispatch(
          setAppCloseableLoadingState({ status: false, closeable: false , text:''})
        )
      if (deleted.status !== 200 || !deleted.data) {
        return showToast('Unable to process your request!', TOAST_TYPE.error)
      }
      showToast(restore ? deletePermanently === 1 ? 'Category permanently deleted': 'Restored category successfully' : 'Category deleted successully' , TOAST_TYPE.success)
      return dispatch({
        type: CATEGORY_ACTION_TYPES.UPDATE_CATEGORY,
        payload: [...deleted.data?.data?.categories],
      })
      // if(deletePermanently === 1)
      // {

      // }

    } catch (err) {
      return dispatch(
          setAppCloseableLoadingState({ status: false, closeable: false , text:''})
        )
    }
  }
}
