import React, { useEffect } from 'react'
import { Card, Container, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { getQuestions } from 'reducers/askASheikh/askASheikh.actions'
import { ASK_A_SHEIKH_GET_STATE } from 'reducers/askASheikh/getState'
import { AUTH_STATE } from 'reducers/auth/getState'
import { getCourses } from 'reducers/course/courses.action'
import { COURSES_STATE } from 'reducers/course/getState'
import { ILMA_STATE } from 'reducers/ilma/getState'
import { getAllIlmas } from 'reducers/ilma/ilma.actions'
import { KNOWLEDGE_JOURNEY_STATE } from 'reducers/knowledgeJourney/getState'
import { getAssessments } from 'reducers/knowledgeJourney/knowledgeJourney.action'
import { INSTRUCTOR_ROLE } from 'shared/CONSTANTS'
import { SUPER_ADMIN } from 'shared/CONSTANTS'

function Dashboard() {
  const courses = useSelector(COURSES_STATE.courses)
  const ilmas = useSelector(ILMA_STATE.ilmasList)
  const history = useHistory()
  const assessments = useSelector(KNOWLEDGE_JOURNEY_STATE.assessments)
  const user = useSelector(AUTH_STATE.user)
  // const askASheikhQuestions = useSelector(ASK_A_SHEIKH_GET_STATE.questions);
  const dispatch = useDispatch()

  useEffect(() => {
    updateCourses()
    updateIlmas()
    updateAssessments()
    // updateAskASheikhQuestions();
  }, [])

  const updateCourses = () => dispatch(getCourses())
  const updateIlmas = () => dispatch(getAllIlmas())
  const updateAssessments = () => dispatch(getAssessments())
  // const updateAskASheikhQuestions = () => dispatch(getQuestions());

  const onGetFilteredCourses = () => {
    let arr = []
    for (var i = 0; i < courses.length; i++) {
      if (courses[i].deleted === 0) {
        arr.push(courses[i])
      }
    }
    console.log(arr)
    return arr
  }

  const { usageType = {}, role } = user
  let _usageType = {}
  if (typeof usageType === 'string') {
    _usageType = JSON.parse(usageType)
  } else {
    _usageType = { ...usageType }
  }

  const { courseOffer = false, askASheikh = false } = _usageType

  return (
    <Container fluid>
      <Row>
        <Col lg="3" sm="6">
          <Card className="card-stats" style={{ cursor: 'pointer' }}>
            <Card.Body onClick={() => history.push('/admin/account')}>
              <Row>
                <Col xs="5">
                  <div className="icon-big text-center icon-warning">
                    <i className="nc-icon nc-circle-09 text-warning"></i>
                  </div>
                </Col>
                <Col xs="7">
                  <div className="numbers">
                    <p className="card-category">Manage</p>
                    <Card.Title as="h4">Account</Card.Title>
                  </div>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              <hr></hr>
              <div
                className="stats"
                onClick={() => history.push('/admin/account')}>
                <i className="fas fa-redo mr-1"></i>
                Update Now
              </div>
            </Card.Footer>
          </Card>
        </Col>
        {(role === INSTRUCTOR_ROLE && courseOffer && askASheikh || role === SUPER_ADMIN.role ) && (
          <Col lg="3" sm="6">
            <Card className="card-stats" style={{ cursor: 'pointer' }}>
              <Card.Body onClick={() => history.push('/admin/courses')}>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-notes text-warning"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Courses</p>
                      <Card.Title as="h4">
                        {courses && onGetFilteredCourses().length}
                      </Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats" onClick={updateCourses}>
                  <i className="fas fa-redo mr-1"></i>
                  Update Now
                </div>
              </Card.Footer>
            </Card>
          </Col>
        )}
        {role === SUPER_ADMIN.role && (
          <>
            <Col lg="3" sm="6">
              <Card className="card-stats">
                <Card.Body onClick={() => history.push('/admin/ilma')}>
                  <Row>
                    <Col xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-bulb-63 text-success"></i>
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">Ilmas</p>
                        <Card.Title as="h4">{ilmas && ilmas.length}</Card.Title>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <div className="stats" onClick={updateIlmas}>
                    <i className="fas fa-redo mr-1"></i>
                    Update now
                  </div>
                </Card.Footer>
              </Card>
            </Col>
            {/* <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-chat-round text-danger"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Ask A Sheikh</p>
                      <Card.Title as="h4">
                        {askASheikhQuestions && askASheikhQuestions.length}
                      </Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats" onClick={updateAskASheikhQuestions}>
                  <i className="fas fa-redo mr-1"></i>
                  Update now
                </div>
              </Card.Footer>
            </Card>
          </Col> */}
            <Col lg="3" sm="6">
              <Card className="card-stats" style={{ cursor: 'pointer' }}>
                <Card.Body
                  onClick={() => history.push('/admin/knowledge-journey')}>
                  <Row>
                    <Col xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-paper-2 text-primary"></i>
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">Assessments</p>
                        <Card.Title as="h4">
                          {assessments && assessments.length}
                        </Card.Title>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <div className="stats" onClick={updateAssessments}>
                    <i className="fas fa-redo mr-1"></i>
                    Update now
                  </div>
                </Card.Footer>
              </Card>
            </Col>
          </>
        )}
      </Row>
    </Container>
  )
}

export default Dashboard
