import React from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

export const ButtonLink = ({
  tooltip,
  onClick = () => {},
  iconClasses,
  title,
  iconStyle = {},
  disabled,
}) => {
  return (
    <OverlayTrigger
      overlay={<Tooltip id="tooltip-488980961">{tooltip}</Tooltip>}
    >
      <Button
        onClick={onClick}
        className="btn-simple btn-link p-1"
        type="button"
        variant="info"
        disabled={disabled}
      >

        {iconClasses ? (
          <i className={`${iconClasses} ${iconStyle}`} ></i>
        ) : (
          title
        )}
      </Button>
    </OverlayTrigger>
  );
};
