import store from "../store";
import { getLocalStorage, redirectTo } from "./functions";

export const isAuthenticated = () => {
  const token = store.getState().auth.user.token || getLocalStorage("token");

  if (token) {
    return true;
  }

  return false;
};
