import { combineReducers } from "redux";

import AuthReducer from "reducers/auth/auth.reducer";
import AppLoadingReducer from "reducers/appLoading/appLoading.reducer";
import CategoryReducer from "reducers/categories/categories.reducer";
import CoursesReducer from "reducers/course/courses.reducer";
import QuizReducer from "reducers/quiz/quiz.reducer";
import IlmaReducer from "reducers/ilma/ilma.reducer";
import AskASkheikhReducer from "reducers/askASheikh/askASkeikh.reducer";
import tagsReducer from "reducers/tags/tags.reducer";
import knowledgeJourneyReducer from "reducers/knowledgeJourney/knowledgeJourney.reducer";
import lessonReducer from "reducers/videos/lesson.reducer";
import userReducer from "reducers/users/users.reducer";

const AppReducers = combineReducers({
  app_loading: AppLoadingReducer,
  auth: AuthReducer,
  category: CategoryReducer,
  course: CoursesReducer,
  quizes: QuizReducer,
  ilmas: IlmaReducer,
  askASheikh: AskASkheikhReducer,
  tags: tagsReducer,
  myJourney: knowledgeJourneyReducer,
  lessons: lessonReducer,
  users: userReducer
});

const RootReducer = (state, actions) => {
  if (actions.type === "[auth] USER_LOGOUT") {
    return AppReducers(undefined, actions);
  }

  return AppReducers(state, actions);
};

export default RootReducer;
