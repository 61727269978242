import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import { AUTH_STATE } from 'reducers/auth/getState'

import { isAuthenticated } from 'shared/Auth'

export const ProtectedRoute = ({ component: Component, ...restOfProps }) => {
  const user = useSelector(AUTH_STATE.user)
  const [allowedToRoute, setAllowedToRoute] = useState({
    courseOffer: false,
    askASheikh: false,
  })
  useEffect(() => {
    if (!user) return setAllowedToRoute(false)

    setAllowedToRoute(user.usageType)
  }, [user])
  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAuthenticated() ? (
          <Component exact {...props} />
        ) : (
          <Redirect to="/admin/login" />
        )
      }
    />
  )
}
