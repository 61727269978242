import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'

export const OnReplaceItem = ({ onApplySuccessFunction, title, question }) => {
  Swal.fire({
    title: `Assessment`,
    text: `Are you sure you want to create assessment against this topic ${title}?\nTotal questions exist ${question} against category`,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, create it!',
  }).then((result) => {
    if (result.isConfirmed) {
      onApplySuccessFunction()
    } else if (result.dismiss) {
      Swal.fire('Cancelled', 'function cancelled.', 'error')
    }
  })
}
