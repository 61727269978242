import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import Accordion from 'react-bootstrap/Accordion'
import { useSelector, useDispatch } from 'react-redux'

import classes from './askASheikh.module.css'
import { CustomOffCanvas } from 'components/CustomOffCanvas/CustomOffCanvas'
import { SearchInput } from 'components/SearchInput/SearchInput'
import { ASK_A_SHEIKH_GET_STATE } from 'reducers/askASheikh/getState'
import { ConversationCard } from 'components/ConversationCard/ConversationCard'
import { Button, Col, Form, FormGroup, Modal, Row } from 'react-bootstrap'
import { AUTH_STATE } from 'reducers/auth/getState'
import { replyToQuestion } from 'reducers/askASheikh/askASheikh.actions'
import { showToast } from 'shared/functions'
import { TOAST_TYPE } from 'shared/functions'
import { Input } from 'reactstrap'
import { TextEditor } from 'components/TextEditor/TextEditor'
import Select from 'react-select'
import { deleteQuestion } from 'reducers/askASheikh/askASheikh.actions'
import { SUPER_ADMIN } from 'shared/CONSTANTS'

export const Questions = (props) => {
  const currentUser = useSelector(AUTH_STATE.user)
  const questions = useSelector(ASK_A_SHEIKH_GET_STATE.questions)
  const [selectedQuestion, setSelectedQuestion] = useState({})
  const [showQuestionDetail, setQuestionDetailState] = useState(false)
  const [message, setMessage] = useState('')
  const [dropdownList, setdropdownList] = useState({
    value: 'All',
    label: 'All',
  })
  const dispatch = useDispatch()
  const scrollRef = useRef()
  const editorRef = useRef(null)
  useEffect(() => {
    scrollToBottom()
  }, [scrollRef.current])

  const onQuestionSelect = (question) => {
    setSelectedQuestion(question)
    setQuestionDetailState(true)
  }

  const onCloseQuestionDetail = () => {
    setQuestionDetailState(false)
  }

  function stripHTML(myString) {
    let el = document.createElement('p')
    el.innerHTML = myString
    return el.textContent || el.innerText || ''
  }

  //  QUESTION DETAIL VIEW MODAL Q/A
  const QuestionCard = ({ question }) => (
    <div
      className={classes.question_card}
      // style={{display:"flex", alignItems:"center" , justifyContent:"space-between",marginTop:"10px"}}
      onClick={() => onQuestionSelect(question)}>
      <div style={{ fontSize: '10px', width: '70px' }}>
        {question.discussions.length > 0
          ? question.discussions[0].draft === 0
            ? 'Published'
            : 'Save'
          : 'Not Replied'}{' '}
        -{' '}
      </div>
      <div
        style={{
          paddingRight: '5px',
          width: '400px',
          height: '100%',
          wordBreak: 'break-all',
        }}>
        {question.question}
      </div>
      <div
        style={{ whiteSpace: 'nowrap', fontSize: '10px', textAlign: 'left' }}>
        <div>Asked By: </div>
        <div> {question?.user?.fname + ' ' + question?.user?.lname}</div>
      </div>
      {/* <div>{question.description}</div> */}
    </div>
  )

  const onChangeInput = (value) => {
    setMessage(value)
  }

  const onReply = async (e) => {
    e.preventDefault()
    const payload = {
      draft: 0,
      message: message,
      category_id: props?.category?.category_id,
      question_id: selectedQuestion?.question_id,
    }

    await dispatch(replyToQuestion(payload))
    // showToast("Replied successfully", TOAST_TYPE.success);
    setMessage('')
    showToast('You replied successfully.', TOAST_TYPE.success)
    setQuestionDetailState(false)
    props?.onClose()
    scrollToBottom()
  }

  const scrollToBottom = () => {
    if (!scrollRef.current) return

    scrollRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  const onDelete = async () => {
    const payload = {
      // message: answer,
      // description: des,
      category_id: selectedQuestion?.category_id,
      question_id: selectedQuestion?.question_id,
      draft: 1,
    }
    showToast('You deleted qyestion successfully.', TOAST_TYPE.success)
    await dispatch(deleteQuestion(payload))
    onCloseQuestionDetail()
    props?.onClose()
  }

  return (
    <>
      <Modal
        show={showQuestionDetail}
        onHide={onCloseQuestionDetail}
        size="lg"
        animation={false}
        backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title style={{ wordBreak: 'break-all' }}>
            {selectedQuestion?.question}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm="12" md="12" lg="12">
              <Row>
                <Col sm="12" md="12" lg="12">
                  <Form.Label>Description *</Form.Label>
                  <FormGroup>
                    <Input
                      placeholder="Description *"
                      type="textarea"
                      defaultValue={selectedQuestion.description}
                      disabled
                      // onChange={(e) => setdes(e.target.value)}
                      rows="2"
                    />
                  </FormGroup>
                </Col>
              </Row>
              {selectedQuestion && selectedQuestion?.discussions?.length ? (
                <Row style={{ marginTop: '10px' }}>
                  <Col sm="12" md="12" lg="12">
                    <Form.Label>Answers *</Form.Label>
                    <FormGroup>
                      {selectedQuestion?.discussions[0]?.user?.user_id ===
                        currentUser?.user_id && (
                        <Input
                          placeholder="Description *"
                          type="textarea"
                          defaultValue={stripHTML(
                            selectedQuestion?.discussions[0]?.message
                          )}
                          disabled
                          rows="3"
                        />
                      )}
                    </FormGroup>
                  </Col>
                </Row>
              ) : (
                <p style={{ padding: '0 10px' }}></p>
              )}
            </Col>
          </Row>
          <Row>
            <Col sm="12" md="12" lg="12">
              {selectedQuestion &&
                selectedQuestion?.discussions?.length < 1 && (
                  <TextEditor
                    placeholder="Your Answer!"
                    // value={message}
                    onChange={(value) => setMessage(value)}
                    ref={editorRef}
                  />
                )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="button"
            variant="default"
            onClick={onCloseQuestionDetail}>
            Close
          </Button>

          <div className="pull-right">
            {currentUser?.role === SUPER_ADMIN?.role && (
              <Button
                className="btn-fill pull-right"
                type="submit"
                variant="danger"
                onClick={() => onDelete()}>
                Delete
              </Button>
            )}{' '}
            {selectedQuestion && selectedQuestion?.discussions?.length < 1 && (
              <Button
                className="btn-fill ml-2 pull-right"
                type="submit"
                variant="primary"
                onClick={onReply}>
                Reply
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
      <CustomOffCanvas
        show={props?.visible}
        onClose={props?.onClose}
        title={props?.category?.title}>
        <div className={classes.question_wrapper}>
          {/* <SearchInput /> */}
          <Select
            options={[
              { value: 'Published', label: 'Published' },
              { value: 'Saved', label: 'Saved' },
              { value: 'Not Replied', label: 'Not Replied' },
              { value: 'All', label: 'All' },
            ]}
            value={dropdownList}
            onChange={(value) => setdropdownList(value)}
          />

          <Accordion>
            {questions?.map((quest, index) =>
              dropdownList?.value === 'Not Replied' &&
              quest?.discussions?.length <= 0 ? (
                <QuestionCard question={quest} key={index?.toString()} />
              ) : dropdownList?.value === 'Saved' &&
                quest?.discussions[0]?.draft === 1 &&
                quest?.discussions?.length > 0 ? (
                <QuestionCard question={quest} key={index?.toString()} />
              ) : dropdownList?.value === 'Published' &&
                quest?.discussions[0]?.draft === 0 &&
                quest?.discussions?.length > 0 ? (
                <QuestionCard question={quest} key={index?.toString()} />
              ) : (
                dropdownList?.value === 'All' && (
                  <QuestionCard question={quest} key={index?.toString()} />
                )
              )
            )}
          </Accordion>
        </div>
      </CustomOffCanvas>
      <CustomOffCanvas
        show={false}
        onClose={onCloseQuestionDetail}
        title={selectedQuestion?.question}>
        <div className={classes.coversation_wrapper}>
          <>
            {selectedQuestion && selectedQuestion?.discussions?.length ? (
              <>
                {selectedQuestion?.discussions?.map((item, index) => (
                  <ConversationCard
                    highlight={item?.user?.user_id === currentUser?.user_id}
                    item={item}
                    key={index?.toString()}
                    user={item?.user}
                    item={item}
                  />
                ))}
                <div ref={scrollRef} />
              </>
            ) : (
              <p style={{ padding: '0 10px' }}>No Conversation!</p>
            )}
          </>

          {selectedQuestion && selectedQuestion?.discussions?.length < 1 && (
            <div className={classes.conversation_form}>
              <Form onSubmit={onReply}>
                <Form.Group>
                  <Form.Control
                    placeholder="Enter your message here"
                    as="textarea"
                    required
                    onChange={(e) => onChangeInput(e.target.value)}
                  />
                </Form.Group>

                <div className={classes.form_btn}>
                  <Button size="sm" className={`btn-fill`} type="submit">
                    Reply
                  </Button>
                </div>
              </Form>
            </div>
          )}
        </div>
      </CustomOffCanvas>
    </>
  )
}

Questions.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  category: PropTypes.category,
}
