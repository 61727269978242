import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  Modal,
  Row
} from "react-bootstrap";

import classes from "./style.module.css";
import { useDispatch, useSelector } from "react-redux";
import { createAssessment } from "reducers/knowledgeJourney/knowledgeJourney.action";
import { CATEGORY_STATE } from "reducers/categories/getState";
import { getAllUsers } from "reducers/users/users.action";
import { Input } from "reactstrap";
import { askSheikhQuestions } from "reducers/askASheikh/askASheikh.actions";

export const AskQuestion = ({ payload, show = false, onClose = () => {} }) => {
  const [formData, setFormData] = useState({
    question: "",
    description: "",
    category_id: 0,
    sheikh_id: null
  });
  const { users } = useSelector((state) => state.users);
  const topics = useSelector(CATEGORY_STATE.categories);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllUsers());
  }, []);

  const resetForm = () => {
    setFormData({});
  };

  const onChangeInput = (key, value) => {
    const _formData = { ...formData };
    _formData[key] = value;

    setFormData(_formData);
  };

  const onFormClose = () => {
    resetForm();
    onClose();
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const data = {
      question: formData.question,
      description: formData.description,
      category_id: payload.category_id,
      sheikh_id: formData.sheikh_id || null
    };

    dispatch(askSheikhQuestions(data));
    onFormClose();
  };

  return (
    <Modal
      show={show}
      onHide={onFormClose}
      size="lg"
      animation={false}
      backdrop="static"
    >
      <Form onSubmit={onSubmit}>
        <Modal.Header style={{ display: "flex", alignItems: "center" }}>
          <Modal.Title>Ask Sheikh Question</Modal.Title>
        </Modal.Header>
        <Modal.Body className={classes.modal_body}>
          <Row>
            <Col sm="12" md="12" lg="12">
              <FormGroup>
                <Form.Label>Topic *</Form.Label>
                <FormControl
                  type="text"
                  placeholder="Topics *"
                  value={payload.title}
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col className={classes.separate} sm="12" md="12">
              <Form.Label>Select Sheikh *</Form.Label>
              <select
                className={classes.select}
                value={formData.sheikh_id}
                onChange={(e) => onChangeInput("sheikh_id", e.target.value)}
              >
                <option value="">Select Sheikh</option>
                {users.map((item, index) => {
                  if (item.role.toLowerCase() !== "student")
                    return (
                      <option value={item.user_id} key={index.toString()}>
                        {item.fname}&nbsp;{item.lname}
                      </option>
                    );
                })}
              </select>
            </Col>
          </Row>
          <Row>
            <Col sm="12" md="12" lg="12">
              <Form.Label>Question *</Form.Label>
              <FormGroup>
                <Input
                  placeholder="Question *"
                  type="textarea"
                  defaultValue={formData.question}
                  onChange={(e) => onChangeInput("question", e.target.value)}
                  rows="5"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm="12" md="12" lg="12">
              <Form.Label>Detail *</Form.Label>
              <FormGroup>
                <Input
                  placeholder="Detail *"
                  type="textarea"
                  onChange={(e) => onChangeInput("description", e.target.value)}
                  defaultValue={formData.description}
                  rows="5"
                />
              </FormGroup>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button
            size="sm"
            type="button"
            variant="default"
            onClick={onFormClose}
          >
            Cancel
          </Button>
          <Button
            className="btn-fill pull-right"
            type="submit"
            variant="primary"
            size="sm"
          >
            Submit Questions
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
