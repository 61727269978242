import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

import classes from "./style.module.css";
import { QuestionItem } from "./QuestionItem";
import { useDispatch } from "react-redux";
import { createQuestions } from "reducers/knowledgeJourney/knowledgeJourney.action";
import { showToast } from "shared/functions";
import { TOAST_TYPE } from "shared/functions";

const QUESTION = {
  question: "",
  options: [],
  type: "",
  category_id: "",
  level: 1,
  answer: ""
};

export const CreateQuestions = ({ show = false, onClose = () => {} }) => {
  const [questions, setQuestions] = useState([{ ...QUESTION, id: 0 }]);
  const dispatch = useDispatch();
  const [tags, setTags] = React.useState([]);

  const resetForm = () => {
    setQuestions([{ ...QUESTION, id: 0 }]);
    setTags([]);
  };

  const onChangeInput = (key, value, index) => {
    const _questionsList = [...questions];
    const _question = _questionsList[index];
    _question[key] = value;

    _questionsList[index] = _question;
    setQuestions(_questionsList);
  };

  const onAddTag = async (key, value, index) => {
    const _questionsList = [...questions];
    const _question = _questionsList[index];
    _question[key] = value;
    setTags(value);
    _questionsList[index] = _question;
    setQuestions(_questionsList);
  };

  const addQuestion = () => {
    const _questions = [...questions];
    _questions.push({ ...QUESTION, id: _questions.length });
    setQuestions(_questions);
  };

  const onRemove = (question) => {
    const qq = [...questions];
    const _updatedQuestions = qq.filter((q) => q.id !== question.id);
    if (_updatedQuestions?.length < 1) return;

    return setQuestions(() => [..._updatedQuestions]);
  };

  const onUpdateQuestionOptions = (options, index) => {
    const _questions = [...questions];
    const currentQuestion = _questions[index];

    currentQuestion.options = [...options];
    for (let i = 0; i < options?.length; i++) {
      if (options[i].isCorrect) {
        currentQuestion.answer = options[i].label;
      }
    }
    _questions[index] = currentQuestion;

    setQuestions(_questions);
  };

  const onFormClose = () => {
    resetForm();
    onClose();
  };
  // question: "",
  // options: [],
  // type: "",
  // category_id: "",
  // level: 1,
  // answer: ""
  const onSubmit = (e) => {
    e.preventDefault();
    for(var i = 0 ; i < questions.length ; i++)
    {
      if (questions[i]?.question === '') {
        return showToast('Question is required', TOAST_TYPE.error)
      }
      if (questions[i]?.level === '') {
        return showToast('Difficulty Level is required', TOAST_TYPE.error)
      }
      if (questions[i]?.category_id === '') {
        return showToast('Topic is required', TOAST_TYPE.error)
      }
      if (questions[i]?.type === '') {
        return showToast('Question Type is required', TOAST_TYPE.error)
      }
      if (questions[i]?.type === 'radio' && questions[i]?.options.length === 0) {
        return showToast('Multiple question answer is required', TOAST_TYPE.error)
      }
      if (questions[i]?.type === 'checkbox' && !questions[i]?.time) {
        return showToast('Challenge Timing is required', TOAST_TYPE.error)
      }
      if (questions[i]?.type === 'checkbox' && questions[i]?.time && questions[i]?.answer == '') {
        return showToast('Possible Answer is required', TOAST_TYPE.error)
      }
    }

    dispatch(createQuestions(questions));
    onFormClose();
  };

  return (
    <Modal
      show={show}
      onHide={onFormClose}
      size="lg"
      animation={false}
      backdrop="static"
    >
      <Form onSubmit={onSubmit}>
        <Modal.Header style={{ display: "flex", alignItems: "center" }} closeButton>
          <Modal.Title>Create Questions</Modal.Title>
          <Row style={{ marginTop: "10px" }}>
            <Col
              sm="12"
              md="6"
              lg="8"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                size="sm"
                type="button"
                className="btn-fill"
                onClick={addQuestion}
                style={{ width: "180px" }}
              >
                Add Question
              </Button>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body className={classes.modal_body}>
          {questions.map((question, index) => (
            <QuestionItem
              onChange={onChangeInput}
              question={question}
              onRemove={onRemove}
              onAddTag={onAddTag}
              // settags={(tag) => setTags(tag)}
              tags={tags}
              onUpdateQuestionOptions={onUpdateQuestionOptions}
              id={index}
            />
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="sm"
            type="button"
            variant="default"
            onClick={onFormClose}
          >
            Cancel
          </Button>
          <Button
            className="btn-fill pull-right"
            type="submit"
            variant="primary"
            size="sm"
          >
            Submit Questions
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
