import React, { useState } from 'react'
import { Col, Row, Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'

import { CreateIlmaForm as UpdateIlmaForm } from 'components/CreateIlmaForm/CreateIlmaForm'
import { ILMA_STATE } from 'reducers/ilma/getState'
import { setSelectedIlma } from 'reducers/ilma/ilma.actions'
import { deleteIlma } from 'reducers/ilma/ilma.actions'
import { TableRow } from './TableRow'
import { OnDeleteItem } from 'components/DeleteConfirmation'

export const IlmasTable = ({ searchedItem, searchedFile }) => {
  const [showModal, setModalState] = useState(false)
  const [is_Publish, setis_Publish] = useState(false)
  const ilmasList = useSelector(ILMA_STATE.ilmasList)
  const dispatch = useDispatch()

  const onDelete = (ilma) => {
    OnDeleteItem({
      onApplySuccessFunction: () => dispatch(deleteIlma(ilma.ilma_id)),
      title: 'Ilma',
    })
  }

  const onPublish = (ilma) => {
    setis_Publish(!is_Publish)
    const clone = { ...ilma }
    clone.is_Publish = !clone.is_Publish
    return dispatch(setSelectedIlma(clone))
  }

  const onEdit = (ilma) => {
    setModalState(true)
    return dispatch(setSelectedIlma(ilma))
  }

  return (
    <Row>
      <Col sm="12" md="12" lg="12">
        {!ilmasList || !ilmasList?.length ? (
          <p>No Content</p>
        ) : (
          <Table className="table-hover table-striped">
            <thead>
              <tr>
                <th className="border-0">#</th>
                <th className="border-0">Title</th>
                {/* <th className="border-0">View Details</th> */}
                <th className="border-0">Dated</th>
                <th className="border-0">Published</th>
                <th className="border-0">Edit | Delete</th>
              </tr>
            </thead>
            <tbody>
              {ilmasList
                ?.sort(
                  (a, b) => new Date(a.scheduleDate) - new Date(b.scheduleDate)
                )
                ?.map(
                  (ilma, index) =>
                    ilma.type
                      .toLowerCase()
                      .includes(searchedFile.toLowerCase()) ||
                    (ilma.title
                      .toLowerCase()
                      .includes(searchedItem.toLowerCase()) &&
                      ilma?.defaultVideo !== 1 && (
                        <TableRow
                          key={index?.toString()}
                          is_Publish={is_Publish}
                          ilma={ilma}
                          index={index}
                          // onClick={onCourseSelect}
                          onEdit={onEdit}
                          onDelete={onDelete}
                          // onDelete={onDelete}
                          onPublish={onPublish}
                        />
                      ))
                )}
            </tbody>
          </Table>
        )}
      </Col>
      <UpdateIlmaForm
        show={showModal}
        editIlma
        onClose={() => {
          setModalState(false)
          return dispatch(setSelectedIlma({}))
        }}
      />
    </Row>
  )
}
