export const APP_LOADING_ACTIONS_TYPES = {
  APP_LOADING: "[APP] APP_LOADING",
  APP_COURSE_LOADED: "[APP] APP_COURSE_LOADING",
  APP_ROUTES: "[APP] APP_ROUTES"
};

export const setAppLoadingState = (payload) => {
  return (dispatch) => {
    return dispatch({
      type: APP_LOADING_ACTIONS_TYPES.APP_LOADING,
      payload
    });
  };
};

export const setAppCloseableLoadingState = (payload) => {
  return (dispatch) => {
    return dispatch({
      type: APP_LOADING_ACTIONS_TYPES.APP_COURSE_LOADED,
      payload
    });
  };
};

export const setAppRoutes = (payload) => {
  return (dispatch) => {
    return dispatch({
      type: APP_LOADING_ACTIONS_TYPES.APP_ROUTES,
      payload
    });
  };
};
