import React, { useRef } from 'react'
import { Button, Row } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { AUTH_STATE } from 'reducers/auth/getState'
import { AddLessonsForm } from '../AddLessonsForm/AddLessonsForm'
import {
  deleteCourse,
  publishCourse,
  submitCourse,
} from '../../reducers/course/courses.action'

import classes from './courses.module.css'
import { INSTRUCTOR_ROLE } from 'shared/CONSTANTS'
import { SUPER_ADMIN } from 'shared/CONSTANTS'
import { AddQuizForm } from 'components/AddQuizForm/AddQuizForm'
import { getQuiz } from 'reducers/quiz/quiz.actions'
import history from 'config/history'
import { OnDeleteItem } from 'components/DeleteConfirmation'
import { AddQuestionForm } from 'components/AddQuestionForm/AddQuestionForm'
import { useState } from 'react'

export const TableRow = ({
  course,
  index,
  onClick = (course) => {},
  onQuizView = (course) => {},
  onEdit = (course) => {},
  restore,
}) => {
  const lessonsFormRef = useRef(null)
  const currentUser = useSelector(AUTH_STATE.user)
  const dispatch = useDispatch()
  const modalRef = useRef(null)
  const [Quizcourse, setQuizcourse] = useState()

  const onClickAddVideo = () => {
    if (!lessonsFormRef.current) return

    lessonsFormRef.current.showModal()
  }

  const submitOrUnpublish = async () => {
    if (course.submitted) {
      await onPublishCourse() //unpublish the course
    } else {
      //submit the course to admin
      await dispatch(submitCourse(course?.course_id))
    }
  }

  const onPublishCourse = async () => {
    await dispatch(publishCourse(course?.course_id))
  }

  const onDeleteCourse = async () => {
    await dispatch(deleteCourse(course?.course_id, restore ? 1 : 0,restore))
  }

  const onRestoreCourse = async () => {
    await dispatch(deleteCourse(course?.course_id, 0 , restore))
  }

  const getSubmitButtonText = () => {
    if (course.submitted && !course.published) return 'Unsubmit'
    else if (course.published) return 'Unpublish'

    return 'Submit'
  }

  const onClickAdd = (course) => {
    setQuizcourse(course)
    if (!modalRef.current) return

    modalRef.current.showModal()
  }

  // console.log(course?.quizes?.length, 'QUIZ LENGTH',course)

  return (
    <>
      <tr className={classes.row}>
        <td>{index + 1}</td>
        <td>{course.title}</td>
        <td>{course?.user?.fname}</td>
        <td>
          <Button
            size="sm"
            className={`btn-fill`}
            disabled={restore ? true : false}
            variant={restore ? 'secondary' : 'primary'}
            onClick={() => onClick(course)}>
            View
          </Button>
        </td>
        <td>
          {/* {currentUser?.user_id === course?.user?.user_id ? ( */}
          <Button
            size="sm"
            className={`btn-fill ${classes.backBtn}`}
            onClick={onClickAddVideo}
            disabled={
              currentUser?.user_id === course?.user?.user_id && restore !== true
                ? false
                : true
            }
            variant={
              currentUser?.user_id === course?.user?.user_id && restore !== true
                ? 'danger'
                : 'secondary'
            }
            style={{ whiteSpace: 'nowrap' }}>
            Add Lesson
            <i className={`nc-icon nc-simple-add ${classes.create_btn_icon}`} />
          </Button>
          {/* ) : (
            <></>
          )} */}
        </td>
        <td>
          {currentUser?.role === INSTRUCTOR_ROLE ? (
            <Button
              size="sm"
              variant={
                course.published || course.submitted ? 'danger' : 'primary'
              }
              className={`btn-fill ${classes.backBtn}`}
              disabled={!course?.lessons?.length || restore === true}
              onClick={submitOrUnpublish}>
              {getSubmitButtonText()}
              <i
                className={`nc-icon ${
                  course.published || course.submitted
                    ? 'nc-simple-remove'
                    : 'nc-check-2'
                } ${classes.create_btn_icon}`}
              />
            </Button>
          ) : currentUser?.role === SUPER_ADMIN.role ? (
            <Button
              size="sm"
              variant={course.published ? 'danger' : 'primary'}
              className={`btn-fill ${classes.backBtn}`}
              disabled={!course?.lessons?.length || restore === true}
              onClick={onPublishCourse}>
              {course.published ? 'Unpublish' : 'Publish'}
              <i
                className={`nc-icon ${
                  course.published ? 'nc-simple-remove' : 'nc-check-2'
                } ${classes.create_btn_icon}`}
              />
            </Button>
          ) : (
            <></>
          )}
        </td>
        <td>
          {course?.quizes?.length > 0 ? (
            <Button
              size="sm"
              variant="primary"
              className={`btn-fill ${classes.backBtn}`}
              onClick={() => onQuizView(course)}
              disabled={restore}
              style={{ whiteSpace: 'nowrap' }}>
              View Quiz
              <i className={`nc-icon nc-layers-3 ${classes.create_btn_icon}`} />
            </Button>
          ) : (
            <Button
              size="sm"
              variant="primary"
              className={`btn-fill ${classes.backBtn}`}
              onClick={() => onClickAdd(course)}
              disabled={restore}
              style={{ whiteSpace: 'nowrap' }}>
              Add Quiz
              <i className={`nc-icon nc-layers-3 ${classes.create_btn_icon}`} />
            </Button>
          )}
        </td>
        <td>
          {/* {currentUser?.user_id === course?.user?.user_id && ( */}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {!restore && (
              <Button
                size="sm"
                disabled={
                  currentUser?.user_id === course?.user?.user_id &&
                  restore !== true
                    ? false
                    : true
                }
                variant={
                  currentUser?.user_id === course?.user?.user_id
                    ? 'primary'
                    : 'secondary'
                }
                className={`btn-fill ${classes.backBtn}`}
                onClick={() => onEdit(course)}>
                Edit
              </Button>
            )}

            {restore && (
              <Button
                style={{ whiteSpace: 'nowrap' }}
                onClick={onRestoreCourse}
                size="sm"
                className="btn-fill ml-1"
                variant={'primary'}>
                Restore
              </Button>
            )}

            <Button
              size="sm"
              // disabled={currentUser?.user_id === course?.user?.user_id ? false : true}
              variant={'danger'}
              className={`btn-fill ml-1 ${classes.backBtn}`}
              onClick={() =>
                OnDeleteItem({
                  onApplySuccessFunction: onDeleteCourse,
                  title: 'Course',
                  restore: false,
                })
              }>
              Delete
              <i
                className={`nc-icon nc-simple-rßemove ${classes.create_btn_icon}`}
              />
            </Button>
          </div>
          {/* )} */}
        </td>
      </tr>
      <AddQuizForm
        ref={modalRef}
        Quizcourse={Quizcourse}
        function={onQuizView}
      />
      <AddLessonsForm course={course} ref={lessonsFormRef} />
    </>
  )
}
