import { KNOWLEDGE_JOURNEY_ENDPOINTS } from 'config/apis'
import http from 'config/http'
import { setAppLoadingState } from 'reducers/appLoading/appLoading.action'
import { TOAST_TYPE } from 'shared/functions'
import { showToast } from 'shared/functions'

export const KNOWLEDGE_ACTION_TYPES = {
  SET_QUESTIONS: '[KNOWLEDGE] SET_QUESTIONS',
  SET_ASSESSMENTS: '[KNOWLEDGE] SET_ASSESSMENTS',
  UPDATE_ASSESSMENTS: '[KNOWLEDGE] UPDATE_ASSESSMENTS',
}

export const setQuestions = (payload) => (dispatch) =>
  dispatch({
    type: KNOWLEDGE_ACTION_TYPES.SET_QUESTIONS,
    payload,
  })

export const setAssessments = (payload) => (dispatch) =>
  dispatch({
    type: KNOWLEDGE_ACTION_TYPES.SET_ASSESSMENTS,
    payload,
  })

export const updateMultiAssessments = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(setAppLoadingState(true))
      const assessments = await http.put(
        KNOWLEDGE_JOURNEY_ENDPOINTS.UPDATE_MULTI_ASSESSMENTS + '/' + payload.id,
        payload
      )
      dispatch({
        type: KNOWLEDGE_ACTION_TYPES.UPDATE_ASSESSMENTS,
        payload,
      })
    } catch (err) {}
  }
}

export const getQuestions = () => {
  return async (dispatch) => {
    try {
      dispatch(setAppLoadingState(true))
      const questions = await http.get(
        KNOWLEDGE_JOURNEY_ENDPOINTS.GET_QUESTIONS
      )

      return dispatch(setQuestions([...questions.data.questionnaire]))
    } catch (err) {}
  }
}

export const getAssessments = () => {
  return async (dispatch) => {
    try {
      dispatch(setAppLoadingState(true))
      const assessments = await http.get(
        KNOWLEDGE_JOURNEY_ENDPOINTS.GET_ASSESSMENTS
      )

      dispatch(setAssessments(assessments.data.assessments))
    } catch (err) {}
  }
}

export const deleteQuestion = (questionID) => {
  return async (dispatch, getState) => {
    try {
      //TODO: add delete api to backend
      // return;
      dispatch(setAppLoadingState(true))
      const deleteQuestion = await http.delete(
        `${KNOWLEDGE_JOURNEY_ENDPOINTS.DELETE_QUESTION}/${questionID}`
      )

      const questionsList = [...getState().myJourney.questions]

      const updatedList = questionsList.filter((q) => q.id !== questionID)
      showToast('Question successfully deleted', TOAST_TYPE.success)
      // setQuestions([...updatedList]);
      dispatch(setQuestions(updatedList))
    } catch (err) {}
  }
}

export const deleteAssessment = (assessmentID) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setAppLoadingState(true))
      const deleteAssessment = await http.delete(
        `${KNOWLEDGE_JOURNEY_ENDPOINTS.DELETE_ASSESSMENT}/${assessmentID}`
      )

      const oldAssessments = [...getState().myJourney.assessments]
      const updatedAssessments = oldAssessments.filter(
        (assessment) => assessment.id !== assessmentID
      )
      showToast('Assessment successfully deleted', TOAST_TYPE.success)
      dispatch(setAssessments(updatedAssessments))
    } catch (err) {}
  }
}

export const createQuestions = (questions) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setAppLoadingState(true))

      const newQuestions = await http.post(
        KNOWLEDGE_JOURNEY_ENDPOINTS.CREATE_QUESTIONS,
        {
          questions: questions,
        }
      )

      const questionsList = [...getState().myJourney.questions]
      const updatedList = [...questionsList, ...newQuestions.data?.data]
      showToast('Question successfully created', TOAST_TYPE.success)
      return dispatch(setQuestions(updatedList))
    } catch (err) {}
  }
}

export const updateQuestions = (questions) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setAppLoadingState(true))

      const newQuestions = await http.put(
        KNOWLEDGE_JOURNEY_ENDPOINTS.UPDATE_QUESTIONS,
        {
          ...questions,
          options: JSON.stringify(questions.options),
        }
      )

      const questionsList = [...getState().myJourney.questions]

      questionsList.filter((item, index) => {
        if (item.id === questions.id) {
          questionsList[index] = {
            ...questions,
            options: JSON.stringify(questions.options),
          }
        }
      })
      showToast('Question successfully updated', TOAST_TYPE.success)
      return dispatch(setQuestions(questionsList))
    } catch (err) {}
  }
}

export const createAssessment = (payload) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setAppLoadingState(true))
      const newAssessment = await http.post(
        KNOWLEDGE_JOURNEY_ENDPOINTS.CREATE_ASSESSMENTS,
        payload
      )

      const assessments = [...getState().myJourney.assessments]
      assessments.push(newAssessment.data.data)
      showToast('Assessment successfully created', TOAST_TYPE.success)
      return dispatch(setAssessments(assessments))
    } catch (err) {}
  }
}

export const createMultiAssessment = (payload) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setAppLoadingState(true))
      const newAssessment = await http.post(
        KNOWLEDGE_JOURNEY_ENDPOINTS.CREATE_MULTI_ASSESSMENTS,
        payload
      )

      const assessments = [...getState().myJourney.assessments]
      const data = {
        category_id: newAssessment.data.data.category_id,
        createdAt: Date.now(),
        created_by: newAssessment.data.data.created_by,
        id: newAssessment.data.data.assessmentId,
        is_deleted: 0,
        questions: '[]',
        scoref: newAssessment.data.data.scoref,
        scoret: newAssessment.data.data.scoret,
        title: newAssessment.data.data.title,
        topic_wise_count: newAssessment.data.data.topic_wise_count,
        updatedAt: Date.now(),
      }
      assessments.push(data)

      showToast('Assessment successfully created', TOAST_TYPE.success)
      return dispatch(setAssessments(assessments))
    } catch (err) {}
  }
}
