import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap'

import { CoursesHeader } from 'components/CoursesHeader/CoursesHeader'
import { useDispatch, useSelector } from 'react-redux'
import { getAllCat } from 'reducers/categories/categories.action'
import { getCourses } from 'reducers/course/courses.action'
import { CoursesTable } from 'components/CoursesTable/CoursesTable'
import { withRouter } from 'react-router'
import { SUPER_ADMIN } from 'shared/CONSTANTS'
import { INSTRUCTOR_ROLE } from 'shared/CONSTANTS'
import { AUTH_STATE } from 'reducers/auth/getState'
import classes from './CoursesHeader.module.css'
import { CreateCourseForm } from 'components/CreateCourseForm/CreateCourseForm'
  
const Courses = (props) => {
  const dispatch = useDispatch()
  const currentUser = useSelector(AUTH_STATE.user)
  const [showCreateCourseModal, setCreateCourseModal] = useState(false)
  const [togglePages, settogglePages] = useState(false)
  const [searchedItem, setsearchedItem] = useState('')

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    //fetch data
    await dispatch(getAllCat())
    await dispatch(getCourses())
  }

  console.log(currentUser.role === SUPER_ADMIN.role)
  return (
    <Container fluid>
      <Row>
        <Col sm="12" lg="12" md="12">
          <Card>
            <Card.Header>
              <Row>
                <Col sm="12" lg="9" md="9">
                  <Card.Title>
                    {togglePages ? 'Restore Courses' : 'Courses'}
                  </Card.Title>
                </Col>
                <Col sm="12" lg="3" md="3" style={{display:"flex", justifyContent:"end"}}>
                    <div
                      sm="12"
                      md="6"
                      lg="6"
                      style={{ display: 'flex', whiteSpace: 'nowrap' }}>
                      {!togglePages && (currentUser.role === INSTRUCTOR_ROLE ||
                        currentUser.role === SUPER_ADMIN.role) && (
                        <Button
                          className={`btn-fill ${classes.create_btn}`}
                          variant="primary"
                          onClick={() => setCreateCourseModal(true)}
                          size="sm">
                          Create Course
                          <i
                            className={`nc-icon nc-simple-add ${classes.create_btn_icon}`}
                          />
                        </Button>
                      )}
                      &nbsp;
                      {(currentUser.role === SUPER_ADMIN.role || currentUser.role === INSTRUCTOR_ROLE) && (
                        <Button
                          onClick={() => settogglePages(!togglePages)}
                          size="sm"
                          className="btn-fill"
                          variant={'primary'}>
                          {togglePages ? 'Back' : 'Restore Course'}
                        </Button>
                      )}
                    </div>
                  <CreateCourseForm
                    show={showCreateCourseModal}
                    onClose={() => setCreateCourseModal(false)}
                  />
                </Col>
              </Row>
              <Row className='mt-2 d-flex justify-content-end'>
                <Col sm="12" lg="3" md="2">
                  <Form.Control
                    name="name"
                    placeholder="Enter Title to Search Course"
                    type="text"
                    onChange={(e) => setsearchedItem(e.target.value)}
                    // value={formData.fname}
                    required></Form.Control>
                  </Col>
                </Row>
            </Card.Header>
            <Card.Body>
              <CoursesTable {...props} restorePage={togglePages}               
              searchedItem={searchedItem}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default withRouter(Courses)
